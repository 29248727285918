/**
 * File: src/components/ProlifiField/Schedules/DirectorCustomModal.jsx
 *
 * Instead of listing individual stores, we list the director’s areas if "Store Visit(s)" is chosen.
 * Additionally, if one area is "Kansas & Nebraska", we split it into two separate items.
 * 
 * Now we remove the time-of-day pickers; 
 * user only selects "start date" and "end date". 
 * We automatically set 7 AM on start date, and 11:59 PM on end date.
 */

import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  // HStack,  <-- We can remove if we don't need it
  Select
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";

import {
  MultiSelectComponent,
  CheckBoxSelection,
  Inject
} from "@syncfusion/ej2-react-dropdowns";

// Import custom CSS for z-index fix (Syncfusion dropdown layering)
import "./DirectorCustomModal.css";

// 1) Helper => transform the raw areas array => splitting "Kansas & Nebraska"
function transformAreas(rawAreas) {
  const result = [];
  for (const a of rawAreas) {
    const trimmed = (a || "").trim();
    if (trimmed.toLowerCase() === "kansas & nebraska") {
      result.push("Kansas");
      result.push("Nebraska");
    } else {
      result.push(trimmed);
    }
  }
  return result;
}

export default function DirectorCustomModal({
  isOpen,
  onClose,
  onSave,
  staticItems = ["Office", "Vacation", "Meetings"],
  // This array is the director’s areas from the server, e.g. ["Kansas & Nebraska","Champaign",...]
  availableStores = [],
  initialEvent = null
}) {
  // We'll rename "Store Visit(s)" => eventType => "store-visits"
  const [eventType, setEventType] = useState("");
  const [areaList, setAreaList]   = useState([]); // user-chosen areas from the multi-select
  const [startTime, setStartTime] = useState(""); // now just a date (string)
  const [endTime,   setEndTime]   = useState(""); // now just a date (string)
  const [notes,     setNotes]     = useState([]);

  // We'll keep a separate local array => directorAreas, after splitting "Kansas & Nebraska"
  const [directorAreas, setDirectorAreas] = useState([]);

  // If user picks "store-visits" => show multi-select
  const isStoreVisit = (eventType === "store-visits");

  // 2) Transform availableStores => directorAreas
  useEffect(() => {
    setDirectorAreas(transformAreas(availableStores));
  }, [availableStores]);

  // 3) If `initialEvent` changes => fill local states
  useEffect(() => {
    if (!initialEvent) {
      setEventType("");
      setAreaList([]);
      setStartTime("");
      setEndTime("");
      setNotes([]);
      return;
    }

    // e.g. "store-visits" or "static-Office"
    if (initialEvent.storeOrEvent === "store-visits") {
      setEventType("store-visits");
      setAreaList(initialEvent.storeIds || []);
    } else if (initialEvent.storeOrEvent?.startsWith("static-")) {
      setEventType(initialEvent.storeOrEvent);
      setAreaList([]);
    } else {
      setEventType("");
      setAreaList([]);
    }

    // The original StartTime / EndTime might be "YYYY-MM-DD HH:mm:ss" or similar
    // We only care about the date portion now => so let's parse out the date if possible
    // If there's no valid date => remain blank
    const stDate = parseLocalDate(initialEvent.StartTime);
    const etDate = parseLocalDate(initialEvent.EndTime);
    setStartTime(stDate || "");
    setEndTime(etDate   || "");

    setNotes(Array.isArray(initialEvent.notes) ? initialEvent.notes : []);
  }, [initialEvent, isOpen]);

  // 4) parseLocalDate => given a string like "2025-01-16 09:00:00" => returns "2025-01-16"
  function parseLocalDate(val) {
    if (!val) return "";
    const dt = new Date(val);
    if (isNaN(dt.getTime())) return "";
    // Extract just the "YYYY-MM-DD"
    const yyyy = dt.getFullYear();
    const mm   = String(dt.getMonth()+1).padStart(2,"0");
    const dd   = String(dt.getDate()).padStart(2,"0");
    return `${yyyy}-${mm}-${dd}`;
  }

  // 5) On "Save"
  function handleSave() {
    if (!eventType) {
      alert("Please pick either Store Visit(s) or a static event type.");
      return;
    }
    if (!startTime || !endTime) {
      alert("Please pick Start & End dates.");
      return;
    }

    let finalType   = eventType;
    let finalAreas  = [];
    if (isStoreVisit) {
      finalType = "store-visits";
      if (!areaList || areaList.length === 0) {
        alert("Please pick at least one area if you choose Store Visit(s).");
        return;
      }
      finalAreas = areaList;
    }

    // We build actual JS Date for the start => 7:00 AM
    // Then for the end => 11:59 PM
    const startDateObj = new Date(`${startTime}T07:00:00`);
    const endDateObj   = new Date(`${endTime}T23:59:00`); 
    // If you want 23:59:59 => do "23:59:59" above

    // final payload => keep keys => startTime / endTime
    const payload = {
      storeOrEvent: finalType,
      storeIds:     finalAreas,
      startTime: startDateObj.toISOString(),
      endTime:   endDateObj.toISOString(),
      notes
    };

    // If editing => add the existing id
    if (initialEvent?.Id) {
      payload.id = initialEvent.Id;
    }

    console.log('[DirectorCustomModal] handleSave => Final payload:', payload);
    onSave(payload);
  }

  function handleAddNote() {
    setNotes(prev => [...prev,""]);
  }
  function handleNoteChange(idx, val) {
    setNotes(prev => {
      const copy = [...prev];
      copy[idx] = val;
      return copy;
    });
  }

  // The event type dropdown => "store-visits" or "static-..."
  const eventTypeOptions = [
    { value:"store-visits", label:"Store Visit(s)" },
    ...staticItems.map(s => ({ value:`static-${s}`, label:s }))
  ];

  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      trapFocus={false}
      closeOnOverlayClick={false}
      blockScrollOnMount={false}
      size="xl"
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        border="1px solid gold"
        boxShadow="0 0 10px rgba(218,165,32,0.5)"
      >
        <ModalHeader fontWeight="bold">
          {initialEvent ? "Edit Director Shift" : "Create Director Shift"}
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          {/* 1) The top-level event type */}
          <FormControl mb={4}>
            <FormLabel>Event Type</FormLabel>
            <Select
              placeholder="Pick an event type..."
              bg="white"
              value={eventType}
              onChange={(e) => {
                setEventType(e.target.value);
                if (e.target.value !== "store-visits") {
                  setAreaList([]);
                }
              }}
            >
              {eventTypeOptions.map((opt) => (
                <option key={opt.value} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </Select>
          </FormControl>

          {/* 2) If "store-visits" => pick area(s) multi-select */}
          {isStoreVisit && (
            <FormControl mb={4}>
              <FormLabel>Pick Area(s)</FormLabel>
              <MultiSelectComponent
                id="multiAreaSelect"
                dataSource={directorAreas}
                fields={{ text: null, value: null }} 
                placeholder="Select one or more areas..."
                mode="CheckBox"
                showSelectAll={true}
                showDropDownIcon={true}
                allowFiltering={true}
                filterBarPlaceholder="Search areas..."
                popupWidth="auto"
                width="100%"
                value={areaList}
                change={(e) => setAreaList(e.value || [])}
                cssClass="myMultiSelect"
              >
                <Inject services={[CheckBoxSelection]} />
              </MultiSelectComponent>
            </FormControl>
          )}

          {/* 3) Start/End => only date (no time) => We'll set times to 7 AM & 11:59 PM internally */}
          <FormControl mb={4}>
            <FormLabel>Start Date</FormLabel>
            <Input
              type="date"
              bg="white"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
            />
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>End Date</FormLabel>
            <Input
              type="date"
              bg="white"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
            />
          </FormControl>

          {/* 4) Multiple notes */}
          <FormControl mb={2}>
            <FormLabel>Notes (multiple)</FormLabel>
          </FormControl>
          <VStack align="stretch" spacing={2}>
            {notes.map((n, idx) => (
              <Input
                key={idx}
                bg="white"
                placeholder={`Note #${idx + 1}`}
                value={n}
                onChange={(e) => handleNoteChange(idx, e.target.value)}
              />
            ))}
          </VStack>

          <Button
            leftIcon={<AddIcon />}
            size="sm"
            mt={2}
            variant="outline"
            onClick={handleAddNote}
          >
            Add Another Note
          </Button>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSave}>
            Save
          </Button>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
