// src/components/BbUpload.js

import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// We'll assume you have a custom loading indicator
import LoadingIndicator from "../LoadingIndicator"; // Adjust import path if necessary

import {
  Flex,
  Box,
  Heading,
  Button,
  Text,
  useColorModeValue,
  Input,
} from "@chakra-ui/react";

import SalesDataChecker from "./SalesDataChecker";

function BbUpload() {
  const [file, setFile] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const MySwal = withReactContent(Swal);

  // Toggle the visibility of the upload form
  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
  };

  // When the user selects a file
  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };

  // Determine base URL for dev/prod
  const getBaseUrl = () => {
    return process.env.NODE_ENV === "development"
      ? "http://72.167.34.236:5000"
      : "https://prolifi.app";
  };

  // Upload file to the server
  const handleUpload = async () => {
    if (!file) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select a file first!",
      });
      return;
    }

    setIsLoading(true);

    const formData = new FormData();
    formData.append("file", file);

    try {
      const baseUrl = getBaseUrl();
      const response = await axios.post(`${baseUrl}/api/bbUpload/upload`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log("Upload successful", response.data);

      MySwal.fire({
        icon: "success",
        title: "Success!",
        text: "Data successfully inserted into database.",
      });
    } catch (error) {
      console.error("Upload failed", error);
      MySwal.fire({
        icon: "error",
        title: "Failed!",
        text: "Failed to upload data. Please try again.",
      });
    } finally {
      setIsLoading(false);
      setFile(null);
    }
  };

  // For styling
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const boxBgColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const dropBgHover = useColorModeValue("blue.50", "gray.600");
  const dropBorderColor = useColorModeValue("blue.300", "blue.600");

  return (
    <Flex direction="column" align="center" bg={bgColor} minH="100vh" p={6}>
      {/* 
        Full-Screen Overlay for LoadingIndicator 
        (only if isLoading = true)
      */}
      {isLoading && (
        <Flex
          position="fixed"
          top={0}
          left={0}
          w="100vw"
          h="100vh"
          bg="rgba(0,0,0,0.5)"
          align="center"
          justify="center"
          zIndex="9999"
        >
          <LoadingIndicator />
        </Flex>
      )}

      <Box
        width="100%"
        borderWidth="1px"
        borderColor={borderColor}
        borderRadius="md"
        bg={boxBgColor}
        mb={6}
        shadow="md"
      >
        {/* Thin toggle bar at the top (attached to container) */}
        <Box
          as="button"
          onClick={toggleFormVisibility}
          display="block"
          w="100%"
          borderBottomWidth={isFormVisible ? "1px" : "0"}
          borderColor={useColorModeValue("gray.200", "gray.600")}
          textAlign="center"
          py={1}
          fontSize="sm"
          bgGradient="linear(to-r, blue.400, blue.600)"
          color="white"
          _hover={{ bgGradient: "linear(to-r, blue.500, blue.700)" }}
          _focus={{ outline: "none" }}
        >
          {isFormVisible ? "▲ Hide Form" : "▼ Show Form"}
        </Box>

        {/* The form content, only if visible */}
        {isFormVisible && (
          <Box p={4}>
            {/* Modern, professional file upload zone (drag + click) */}
            <Box
              as="label"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              p={6}
              border="2px dashed"
              borderColor={dropBorderColor}
              borderRadius="md"
              cursor="pointer"
              transition="background 0.2s"
              _hover={{ bg: dropBgHover }}
              mb={4}
            >
              {!file ? (
                <Text fontSize="sm" color="gray.500">
                  Click or drag your BlueBook file here.
                </Text>
              ) : (
                <Text fontSize="sm" color="blue.500" fontWeight="medium">
                  Selected file: {file.name}
                </Text>
              )}
              <Input
                type="file"
                accept=".csv"
                hidden
                onChange={handleFileChange}
              />
            </Box>

            {/* Align the Upload button to the right */}
            <Flex justify="flex-end">
              <Button
                onClick={handleUpload}
                disabled={isLoading}
                bgGradient="linear(to-r, blue.400, blue.600)"
                _hover={{ bgGradient: "linear(to-r, blue.500, blue.700)" }}
                _active={{ bgGradient: "linear(to-r, blue.600, blue.800)" }}
                color="white"
                fontWeight="bold"
                borderRadius="md"
                boxShadow="md"
                transition="all 0.3s"
              >
                Upload
              </Button>
            </Flex>
          </Box>
        )}
      </Box>

      <Box
        width="100%"
        borderWidth="1px"
        borderColor={borderColor}
        borderRadius="md"
        bg={boxBgColor}
        p={4}
        shadow="md"
      >
        <SalesDataChecker />
      </Box>
    </Flex>
  );
}

export default BbUpload;
