// File: src/components/ProlifiField/Schedules/WeekRangeSelector.jsx

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getBaseUrl } from '../../../../utils/getBaseUrl'; // <-- your existing function
import './WeekRangeSelector.css';

export default function WeekRangeSelector({
  startDate,
  endDate,
  onWeekSelected,
  weeklyOverallHours = 0, // aggregator for Monday–Sunday
}) {
  const [mondayDate, setMondayDate] = useState(null);
  const [sundayDate, setSundayDate] = useState(null);

  // Track whether the selected week is already submitted
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    if (!startDate) return;
    const d = new Date(startDate);
    if (!isNaN(d)) {
      const mon = d;
      setMondayDate(mon);

      const sun = new Date(mon);
      sun.setDate(sun.getDate() + 6);
      sun.setHours(23, 59, 59, 999);
      setSundayDate(sun);
    }
  }, [startDate]);

  // Check each time [mondayDate] or [sundayDate] changes
  useEffect(() => {
    if (!mondayDate || !sundayDate) return;
    checkIfWeekComplete(mondayDate, sundayDate);
  }, [mondayDate, sundayDate]);

  if (!mondayDate || !sundayDate) {
    return (
      <div className="wrs-container">
        <h4 className="wrs-title">Week Range Selector</h4>
        <p>Loading…</p>
      </div>
    );
  }

  const labelStr = formatMD(mondayDate) + ' - ' + formatMD(sundayDate);

  // Move forward/back by 7 days
  function shiftWeek(days) {
    const newMonday = new Date(mondayDate);
    newMonday.setDate(newMonday.getDate() + days);

    const newSunday = new Date(newMonday);
    newSunday.setDate(newSunday.getDate() + 6);
    newSunday.setHours(23, 59, 59, 999);

    setMondayDate(newMonday);
    setSundayDate(newSunday);
    onWeekSelected?.(newMonday, newSunday);
  }

  // If <45 hours, highlight aggregator in red
  const aggregatorClass = weeklyOverallHours < 45
    ? 'wrs-aggregator wrs-aggregator-alert'
    : 'wrs-aggregator';

  /**
   * 1) Check if this user’s week is marked complete in DB
   */
  async function checkIfWeekComplete(mon, sun) {
    try {
      const baseUrl = getBaseUrl();
      const isoMon = toYyyyMmDd(mon);
      const isoSun = toYyyyMmDd(sun);

      const url = `${baseUrl}/pfield_events/isWeekComplete?weekStart=${isoMon}&weekEnd=${isoSun}`;
      const res = await axios.get(url, { withCredentials: true });

      setIsComplete(!!res.data.isComplete);
    } catch (err) {
      console.error('Error checking isWeekComplete =>', err);
      setIsComplete(false);
    }
  }

  /**
   * 2) Mark the week complete if not already
   */
  async function handleMarkComplete() {
    try {
      if (!mondayDate || !sundayDate) return;
      const isoMon = toYyyyMmDd(mondayDate);
      const isoSun = toYyyyMmDd(sundayDate);

      const baseUrl = getBaseUrl();
      const url = `${baseUrl}/pfield_events/markWeekComplete`;
      const payload = { weekStart: isoMon, weekEnd: isoSun };
      const res = await axios.post(url, payload, { withCredentials: true });

      console.log('markWeekComplete =>', res.data);
      // Mark local state as complete
      setIsComplete(true);
    } catch (err) {
      console.error('Error marking week complete =>', err);
      alert('Could not mark week complete => ' + err.message);
    }
  }

  // If the week is complete, we show a green “Schedule Submitted” style
  const buttonLabel = isComplete ? 'Schedule Submitted' : 'Mark Week Complete';
  const buttonClass = isComplete ? 'wrs-mark-complete wrs-complete-disabled' : 'wrs-mark-complete';

  return (
    <div className="wrs-container">
      <h4 className="wrs-title">Week Range Selector</h4>

      <button
        className={buttonClass}
        onClick={handleMarkComplete}
        disabled={isComplete}
      >
        {buttonLabel}
      </button>

      <div className="wrs-row">
        <button className="wrs-arrow-btn" onClick={() => shiftWeek(-7)}>
          &lt;
        </button>
        <span className="wrs-label">{labelStr}</span>
        <button className="wrs-arrow-btn" onClick={() => shiftWeek(7)}>
          &gt;
        </button>
      </div>

      <div className="wrs-overall-row">
        <span className={aggregatorClass}>
          This Week’s Hours: <strong>{weeklyOverallHours}</strong>
        </span>
      </div>
    </div>
  );
}

// Show e.g. "3/12"
function formatMD(d) {
  const mm = d.getMonth() + 1;
  const dd = d.getDate();
  return mm + '/' + dd;
}

// Return "YYYY-MM-DD" from a JS Date
function toYyyyMmDd(dateObj) {
  const yyyy = dateObj.getFullYear();
  const mm = String(dateObj.getMonth() + 1).padStart(2, '0');
  const dd = String(dateObj.getDate()).padStart(2, '0');
  return `${yyyy}-${mm}-${dd}`;
}
