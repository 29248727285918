// src/components/FraudPrevention.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import * as XLSX from "xlsx";

// Chakra UI
import {
  Box,
  Container,
  Flex,
  Heading,
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";

// react-icons
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

// Custom Loading Indicator
import LoadingIndicator from "../LoadingIndicator";

function FraudPrevention() {
  // -----------------------------
  // State
  // -----------------------------
  const [files, setFiles] = useState({
    voidsFile: null,
    discountsFile: null,
    oaVoidsFile: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [fileStatus, setFileStatus] = useState({
    voidsFile: "pending",
    discountsFile: "pending",
    oaVoidsFile: "pending",
  });

  // -----------------------------
  // ENV / Hooks (unconditional)
  // -----------------------------
  /**
   * 1) We define all usage of useColorModeValue BEFORE any early returns or conditionals
   */
  const pageBg = useColorModeValue("gray.50", "gray.800");
  const containerBg = useColorModeValue("white", "gray.700");
  const successColor = "green.500";
  const errorColor = "red.500";
  const gradientBg = useColorModeValue(
    "linear(to-r, gray.100, blue.50)",
    "linear(to-r, gray.800, gray.900)"
  );

  /**
   * 2) Now we can safely do any conditionals. If we do early returns,
   *    the hook calls above are still invoked in the same order every time.
   */

  // -----------------------------
  // For environment-based base URL
  // -----------------------------
  const getBaseUrl = () => {
    if (process.env.NODE_ENV === "development") {
      return "http://72.167.34.236:5000";
    }
    return "https://prolifi.app";
  };

  // Debug: Log whenever `files` changes
  useEffect(() => {
    console.log("Current files:", files);
  }, [files]);

  // -----------------------------
  // File Handling
  // -----------------------------
  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    const readers = [];

    Array.from(selectedFiles).forEach((file) => {
      readers.push(readFile(file));
    });

    Promise.all(readers).then((results) => {
      const processedFiles = categorizeFiles(results);
      setFiles(processedFiles);
    });
  };

  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        resolve({ file, workbook });
      };
      reader.onerror = reject;
      reader.readAsBinaryString(file);
    });
  };

  const categorizeFiles = (filesData) => {
    const fileTypes = {
      voidsFile: null,
      discountsFile: null,
      oaVoidsFile: null,
    };
    let tempFileStatus = {
      voidsFile: "error",
      discountsFile: "error",
      oaVoidsFile: "error",
    };

    filesData.forEach(({ file, workbook }) => {
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];

      // Check specific cells
      const cellValueA3 = worksheet["A3"] ? worksheet["A3"].v : null;
      const cellValueA1 = worksheet["A1"] ? worksheet["A1"].v : null;

      if (cellValueA3 === "Net Sales and Voids") {
        fileTypes.voidsFile = file;
        tempFileStatus.voidsFile = "success";
      } else if (cellValueA3 === "Manual Discounts") {
        fileTypes.discountsFile = file;
        tempFileStatus.discountsFile = "success";
      } else if (cellValueA1 === "Employee Control Report") {
        fileTypes.oaVoidsFile = file;
        tempFileStatus.oaVoidsFile = "success";
      }
    });

    setFileStatus(tempFileStatus);
    return fileTypes;
  };

  // -----------------------------
  // Submit => POST => file download
  // -----------------------------
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append("voidsFile", files.voidsFile);
      formData.append("discountsFile", files.discountsFile);
      formData.append("oaVoidsFile", files.oaVoidsFile);

      const baseUrl = getBaseUrl();
      const response = await axios.post(`${baseUrl}/api/fraud-prevention`, formData, {
        responseType: "blob",
        withCredentials: true,
      });

      // Build filename from date
      const date = new Date();
      const formattedDate = `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
      const filename = `FPR_${formattedDate}.xlsx`;

      // Create object URL => download
      const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();

      // Reset after success
      setFiles({ voidsFile: null, discountsFile: null, oaVoidsFile: null });
      setFileStatus({
        voidsFile: "pending",
        discountsFile: "pending",
        oaVoidsFile: "pending",
      });
    } catch (error) {
      if (error.response) {
        console.error("Error data:", error.response.data);
        console.error("Error status:", error.response.status);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // -----------------------------
  // Render
  // -----------------------------
  if (isLoading) {
    return (
      <Box bg={pageBg} minH="100vh" p={6}>
        <LoadingIndicator />
      </Box>
    );
  }

  return (
    <Box bgGradient={gradientBg} minH="100vh" py={10} px={4}>
      <Container
        as="form"
        onSubmit={handleSubmit}
        maxW="lg"
        p={6}
        borderWidth="1px"
        borderRadius="md"
        boxShadow="lg"
        bg={containerBg}
      >
        <Heading as="h2" size="lg" mb={2} textAlign="center">
          Fraud Prevention
        </Heading>
        <Text fontSize="sm" color="gray.500" mb={6} textAlign="center">
          Upload your monthly net-sales/voids, manual discounts, and OA voids files.
          Once validated, click Process to download your combined Fraud Prevention report.
        </Text>

        <FormControl mb={4}>
          <FormLabel fontWeight="semibold">Select Files</FormLabel>
          <Input type="file" onChange={handleFileChange} accept=".xlsx, .xls" multiple />
        </FormControl>

        <Flex align="center" justify="space-between" mb={6}>
          <Flex align="center" gap={4}>
            {/* Voids */}
            <Icon
              as={fileStatus.voidsFile === "success" ? FaCheckCircle : FaTimesCircle}
              color={fileStatus.voidsFile === "success" ? successColor : errorColor}
              boxSize={6}
              title="Voids File"
            />
            {/* Discounts */}
            <Icon
              as={fileStatus.discountsFile === "success" ? FaCheckCircle : FaTimesCircle}
              color={fileStatus.discountsFile === "success" ? successColor : errorColor}
              boxSize={6}
              title="Discounts File"
            />
            {/* OA Voids */}
            <Icon
              as={fileStatus.oaVoidsFile === "success" ? FaCheckCircle : FaTimesCircle}
              color={fileStatus.oaVoidsFile === "success" ? successColor : errorColor}
              boxSize={6}
              title="OA Voids File"
            />
          </Flex>

          <Button
            type="submit"
            colorScheme="teal"
            isDisabled={
              fileStatus.voidsFile !== "success" ||
              fileStatus.discountsFile !== "success" ||
              fileStatus.oaVoidsFile !== "success"
            }
          >
            Process
          </Button>
        </Flex>
      </Container>
    </Box>
  );
}

export default FraudPrevention;
