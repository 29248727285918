// src/components/ConfettiBurst.js

import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import { keyframes } from "@emotion/react";

const confettiKeyframes = keyframes`
  0%   { transform: translate(0,0) }
  100% { transform: translate(var(--dx), var(--dy)) }
`;

const COLORS = ["#FFC107", "#E91E63", "#00BCD4", "#4CAF50", "#FF5722"];
function randomColor() {
  return COLORS[Math.floor(Math.random() * COLORS.length)];
}

export default function ConfettiBurst({ x, y, onComplete }) {
  const [pieces] = useState(() => {
    const arr = [];
    for (let i = 0; i < 8; i++) {
      const angle = Math.random() * 360;
      const distance = 40 + Math.random() * 30;
      const dx = Math.cos((angle * Math.PI) / 180) * distance;
      const dy = Math.sin((angle * Math.PI) / 180) * distance;
      arr.push({
        id: i,
        dx,
        dy,
        color: randomColor(),
        rotation: Math.random() * 180,
      });
    }
    return arr;
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      if (onComplete) onComplete();
    }, 400);
    return () => clearTimeout(timer);
  }, [onComplete]);

  return (
    <Box
      position="absolute"
      left={x}
      top={y}
      pointerEvents="none"
      overflow="visible"
      transform="translate(-50%, -50%)"
    >
      {pieces.map((piece) => (
        <Box
          key={piece.id}
          position="absolute"
          width="8px"
          height="8px"
          bg={piece.color}
          style={{
            "--dx": `${piece.dx}px`,
            "--dy": `${piece.dy}px`,
          }}
          borderRadius="2px"
          transform={`rotate(${piece.rotation}deg)`}
          animation={`${confettiKeyframes} 0.6s ease-out forwards`}
        />
      ))}
    </Box>
  );
}
