import React, {
  useRef, useEffect, forwardRef, useImperativeHandle, useState
} from 'react';
import {
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  TimelineViews,
  Month,
  DragAndDrop,
  Resize,
  ResourcesDirective,
  ResourceDirective,
  Inject
} from '@syncfusion/ej2-react-schedule';
import axios from 'axios';

// The two modals
import LegacyDirectorModal from './director/LegacyDirectorModal';
import DirectorCustomModal from './director/DirectorCustomModal';

import ShiftTooltip from './super/ShiftTooltip';
import { validateShift } from '../../../utils/shiftValidation';
import { getBaseUrl } from '../../../utils/getBaseUrl';
import SupervisorHoursOverlay from './SupervisorHoursOverlay';

const DIRECTOR_RESOURCE_ID = -999;

/**
 * Format a JS date to local string "YYYY-MM-DD HH:mm:ss"
 */
function formatLocalDateTime(date) {
  const pad = (n) => String(n).padStart(2, '0');
  const year = date.getFullYear();
  const mon  = pad(date.getMonth() + 1);
  const day  = pad(date.getDate());
  const hr   = pad(date.getHours());
  const min  = pad(date.getMinutes());
  const sec  = pad(date.getSeconds());
  return `${year}-${mon}-${day} ${hr}:${min}:${sec}`;
}

const DirectorScheduleSection = forwardRef(function DirectorScheduleSection(props, ref) {
  const {
    events,
    setEvents,
    supervisorResource = [],
    onDateRangeChange,
    expandCollapseAndSwitchView,
    startDate,
    endDate,
    onEventsChanged,     // We'll await this so we can re-fetch from server
    directorStores,
    directorSupId,
    directorAreas
  } = props;

  const scheduleRef = useRef(null);
  const overlayRef  = useRef(null);
  const originalTimesRef = useRef({});

  // Two modals:
  const [isLegacyDirectorModalOpen, setIsLegacyDirectorModalOpen] = useState(false);
  const [directorRowEvent, setDirectorRowEvent] = useState(null);

  const [isSupModalOpen, setIsSupModalOpen] = useState(false);
  const [supervisorRowEvent, setSupervisorRowEvent] = useState(null);

  // For hours overlay
  const [supervisorHoursMap, setSupervisorHoursMap] = useState({});
  const [nameToIdMap, setNameToIdMap] = useState({});

  // Track supervisor IDs that have a “completed” week
  const [completedSupIds, setCompletedSupIds] = useState(new Set());

  // Expose some methods to the parent
  useImperativeHandle(ref, () => ({
    switchView,
    refresh() {
      scheduleRef.current?.refreshEvents();
      scheduleRef.current?.dataBind();
    }
  }));

  // --------------------------------------------------------------------------
  // (A) Function to fetch the "completed weeks" from pfield_completed_weeks table
  // --------------------------------------------------------------------------
  async function fetchCompletedWeeks(startLocal, endLocal) {
    try {
      const baseUrl = getBaseUrl();
      const url = `${baseUrl}/pfield_events/pfield_director/completedWeeks`;
      const payload = {
        startDate: startLocal,
        endDate:   endLocal
      };
      const response = await axios.post(url, payload, { withCredentials: true });
      console.log('[fetchCompletedWeeks] =>', response.data);

      const newSet = new Set();
      if (response.data && response.data.completedWeeks) {
        response.data.completedWeeks.forEach((w) => {
          if (w.status === 'complete' && w.user_id) {
            newSet.add(w.user_id);
          }
        });
      }
      setCompletedSupIds(newSet);

    } catch (err) {
      console.error('Error fetching completed weeks =>', err);
    }
  }

async function deleteDirectorShift(id) {
  try {
    const baseUrl = getBaseUrl();
    const delUrl = `${baseUrl}/pfield_events/pfield_director/directorEvent/${id}`;
    await axios.delete(delUrl, { withCredentials: true });
    console.log('Deleted director event =>', id);

    // Also remove it from local state if needed
    setEvents(prev => prev.filter(e => e.Id !== id));

  } catch (err) {
    console.error('Error deleting director event =>', err);
    alert('Error deleting director event. Check console for details.');
  }
}

  // Recompute name→id map + total hours each time events or resources change
  useEffect(() => {
    const newNameMap = {};
    supervisorResource.forEach(r => {
      if (r.id !== DIRECTOR_RESOURCE_ID) {
        newNameMap[r.text] = r.id;
      }
    });
    setNameToIdMap(newNameMap);

    const newHoursMap = {};
    events.forEach(ev => {
      if (ev.SupervisorId && ev.SupervisorId !== DIRECTOR_RESOURCE_ID) {
        const s = new Date(ev.startTime);
        const e = new Date(ev.endTime);
        if (!isNaN(s) && !isNaN(e)) {
          const hrs = (e - s) / (1000 * 60 * 60);
          newHoursMap[ev.SupervisorId] = (newHoursMap[ev.SupervisorId] || 0) + hrs;
        }
      }
    });
    setSupervisorHoursMap(newHoursMap);
  }, [events, supervisorResource]);

  // (B) On first mount => if the current view is TimelineWeek, fetch completed weeks
  useEffect(() => {
    if (scheduleRef.current) {
      if (scheduleRef.current.currentView === 'TimelineWeek') {
        const vDates = scheduleRef.current.getCurrentViewDates();
        if (vDates?.length > 0) {
          const earliest = new Date(vDates[0]);
          earliest.setHours(0, 0, 0, 0);
          const latest = new Date(vDates[vDates.length - 1]);
          latest.setHours(23, 59, 59, 999);

          const earliestStr = formatLocalDateTime(earliest);
          const latestStr   = formatLocalDateTime(latest);
          fetchCompletedWeeks(earliestStr, latestStr);
        }
      }
    }
  }, []);

  // (C) If parent doesn't have start/end, set them once from the schedule’s visible dates in local strings
  useEffect(() => {
    if (scheduleRef.current && (!startDate || !endDate)) {
      const vDates = scheduleRef.current.getCurrentViewDates();
      if (vDates?.length > 0) {
        const earliest = new Date(vDates[0]);
        earliest.setHours(0, 0, 0, 0);
        const latest = new Date(vDates[vDates.length - 1]);
        latest.setHours(23, 59, 59, 999);

        onDateRangeChange?.(formatLocalDateTime(earliest), formatLocalDateTime(latest));
      }
    }
  }, [onDateRangeChange, startDate, endDate]);

  function switchView(viewName) {
    if (scheduleRef.current) {
      scheduleRef.current.currentView = viewName;
      scheduleRef.current.dataBind();
    }
  }

  function resourceHeaderTemplate(props) {
    const isTimelineWeek = (scheduleRef.current?.currentView === 'TimelineWeek');
    const isComplete = completedSupIds.has(props.resourceData.id);

    // We'll always wrap in my-resource-label:
    return (
      <div className="my-resource-label" style={{ display: 'flex', alignItems: 'center' }}>
        <span>{props.resourceData.text}</span>
        {isTimelineWeek && isComplete && (
          <span style={{ marginLeft: 8, color: 'green', fontWeight: 'bold' }}>✔</span>
        )}
      </div>
    );
  }

  // -------------------------------------------------------
  // DOUBLE-CLICK HANDLERS => open the correct modal
  // -------------------------------------------------------
  function handleCellDoubleClick(args) {
    args.cancel = true;

    const groupIndex = args.groupIndex;
    if (groupIndex == null) {
      alert('Invalid resource row => read-only');
      return;
    }
    const cellResource = scheduleRef.current.getResourcesByIndex(groupIndex);
    if (!cellResource || !cellResource.resourceData) {
      alert('Unknown resource => read-only');
      return;
    }

    const supId = cellResource.resourceData.id;

    // By default, let's pick 9am–5pm for new shifts
    const day = new Date(args.startTime);
    day.setHours(9, 0, 0, 0);
    const dayEnd = new Date(day);
    dayEnd.setHours(17, 0, 0, 0);

    const newEv = {
      Id: null,
      SupervisorId: supId,
      Subject: '',
      startTime: day,
      endTime: dayEnd,
      Description:'',
      storeOrEvent:'store-visits',
      storeIds:[],
      notes:[]
    };

    if (supId === DIRECTOR_RESOURCE_ID) {
      setDirectorRowEvent(newEv);
      setIsLegacyDirectorModalOpen(true);
    } else if (directorSupId && supId === directorSupId) {
      setSupervisorRowEvent(newEv);
      setIsSupModalOpen(true);
    } else {
      alert('This supervisor is read-only. No new shift allowed here.');
    }
  }

  function handleEventDoubleClick(args) {
    args.cancel = true;
    const ev = args.event || {};
    const supId = ev.SupervisorId;
    if (!supId) return;

    if (supId === DIRECTOR_RESOURCE_ID) {
      setDirectorRowEvent(ev);
      setIsLegacyDirectorModalOpen(true);
    } else if (directorSupId && supId === directorSupId) {
      setSupervisorRowEvent(ev);
      setIsSupModalOpen(true);
    } else {
      alert('Supervisor shift => read-only');
    }
  }

  // -------------------------------------------------------
  // DIRECTOR MODAL -> SAVE/DELETE
  // -------------------------------------------------------
  async function handleDirectorModalSave(modEv) {
    try {
      const baseUrl = getBaseUrl();
      const saveUrl = `${baseUrl}/pfield_events/pfield_director/saveDirectorEvent`;

      function localString(dateObj) {
        if (!(dateObj instanceof Date)) return '';
        return formatLocalDateTime(dateObj);
      }
      const payload = {
        ...modEv,
        startTime: localString(new Date(modEv.startTime)),
        endTime:   localString(new Date(modEv.endTime))
      };

      const { data } = await axios.post(saveUrl, payload, { withCredentials: true });
      console.log('Saved director event =>', data);

      let finalId = modEv.Id;
      if (data.insertedId) {
        finalId = data.insertedId;
      } else if (data.eventId) {
        finalId = data.eventId;
      }

      if (finalId) {
        const newEvent = {
          Id: finalId,
          SupervisorId: DIRECTOR_RESOURCE_ID,
          Subject:
            modEv.storeOrEvent === 'store-visits'
              ? 'Store Visit(s)'
              : modEv.storeOrEvent?.startsWith('static-')
                ? modEv.storeOrEvent.substring(7)
                : 'Misc',
          startTime: modEv.startTime,
          endTime:   modEv.endTime,
          Description: '',
          storeOrEvent: modEv.storeOrEvent,
          storeIds:     modEv.storeIds || [],
          notes:        modEv.notes || []
        };

        if (modEv.Id) {
          setEvents(prev => prev.map(e => e.Id === modEv.Id ? newEvent : e));
        } else {
          setEvents(prev => [...prev, newEvent]);
        }
      }

      setIsLegacyDirectorModalOpen(false);

      // RE-FETCH from server first, then refresh schedule
      if (onEventsChanged) {
        await onEventsChanged();
      }
      scheduleRef.current?.refreshEvents();
      scheduleRef.current?.dataBind();

    } catch (err) {
      console.error('Error saving director event =>', err);
      alert('Error saving director event. Check console for details.');
    }
  }

  async function handleDirectorModalDelete(id) {
    try {
      const baseUrl = getBaseUrl();
      const delUrl = `${baseUrl}/pfield_events/pfield_director/directorEvent/${id}`;
      await axios.delete(delUrl, { withCredentials: true });
      console.log('Deleted director event =>', id);

      setEvents(prev => prev.filter(e => e.Id !== id));
      setIsLegacyDirectorModalOpen(false);

      // RE-FETCH from server first, then refresh schedule
      if (onEventsChanged) {
        await onEventsChanged();
      }
      scheduleRef.current?.refreshEvents();
      scheduleRef.current?.dataBind();

    } catch (err) {
      console.error('Error deleting director event =>', err);
      alert('Error deleting director event. Check console for details.');
    }
  }

  // -------------------------------------------------------
  // DIRECTOR’s SUPERVISOR MODAL -> SAVE/DELETE
  // -------------------------------------------------------
  async function handleSupervisorModalSave(modEv) {
    // The updated shift is already saved in DirectorCustomModal => DB
    // We now update local state so it appears.

    if (modEv.Id) {
      setEvents(prev => prev.map(e => (e.Id === modEv.Id ? { ...e, ...modEv } : e)));
    } else {
      // Newly inserted => if no DB ID, generate a random local ID
      if (!modEv.Id) {
        modEv.Id = Math.floor(Math.random() * 900000) + 100;
      }
      setEvents(prev => [...prev, modEv]);
    }
    setIsSupModalOpen(false);

    // RE-FETCH from server, then refresh
    if (onEventsChanged) {
      await onEventsChanged();
    }
    scheduleRef.current?.refreshEvents();
    scheduleRef.current?.dataBind();
  }

  async function handleSupervisorModalDelete(id) {
    setEvents(prev => prev.filter(e => e.Id !== id));
    setIsSupModalOpen(false);

    // RE-FETCH from server, then refresh
    if (onEventsChanged) {
      await onEventsChanged();
    }
    scheduleRef.current?.refreshEvents();
    scheduleRef.current?.dataBind();
  }

  // -------------------------------------------------------
  // DRAG/RESIZE + onActionBegin => handle drag/resize/delete
  // -------------------------------------------------------
  function handleDragStart(args) {
    if (scheduleRef.current?.currentView === 'TimelineWeek') {
      const ev = Array.isArray(args.data) ? args.data[0] : args.data;
      if (ev?.Id) {
        if (ev.SupervisorId === DIRECTOR_RESOURCE_ID ||
            (directorSupId && ev.SupervisorId === directorSupId)) {
          originalTimesRef.current[ev.Id] = {
            start: new Date(ev.startTime),
            end:   new Date(ev.endTime)
          };
        }
      }
    }
  }

  function handleResizeStart(args) {
    handleDragStart(args);
  }

  async function deleteSupervisorShiftInDb(shiftId) {
    try {
      const baseUrl = getBaseUrl();
      const delUrl = `${baseUrl}/pfield_events/pfield_director/supervisorEvent/${shiftId}`;
      await axios.delete(delUrl, { withCredentials: true });
      console.log('Supervisor shift deleted =>', shiftId);

      setEvents(prev => prev.filter(e => e.Id !== shiftId));
    } catch (err) {
      console.error('Error deleting supervisor shift =>', err);
      alert('Error deleting supervisor shift. Check console for details.');
    }
  }

  async function saveDirectorSupShiftViaDrag(ev) {
    try {
      const baseUrl = getBaseUrl();
      const url = `${baseUrl}/pfield_events/pfield_director/supervisorEvent`;

      const payload = {
        Id: ev.Id,
        startTime: formatLocalDateTime(new Date(ev.startTime)),
        endTime:   formatLocalDateTime(new Date(ev.endTime)),
        Subject:   ev.Subject || '',
        storeOrEvent: ev.storeOrEvent || '',
        storeIds:  ev.storeIds || [],
        notes:     ev.notes || []
      };

      const { data } = await axios.post(url, payload, { withCredentials: true });
      console.log('Supervisor shift updated =>', data);

      setEvents(prev => prev.map(e => {
        if (e.Id === ev.Id) {
          return { ...e, startTime: ev.startTime, endTime: ev.endTime };
        }
        return e;
      }));
    } catch (err) {
      console.error('Error updating supervisor shift =>', err);
      alert('Error updating supervisor shift. Reverting...');
    }
  }

  async function saveDirectorShiftViaDrag(ev) {
    try {
      const baseUrl = getBaseUrl();
      const url = `${baseUrl}/pfield_events/pfield_director/saveDirectorEvent`;

      const storeOrEvent = ev.storeOrEvent || 'static-Office';
      const payload = {
        Id: ev.Id,
        startTime: formatLocalDateTime(new Date(ev.startTime)),
        endTime:   formatLocalDateTime(new Date(ev.endTime)),
        storeOrEvent,
        storeIds: ev.storeIds || [],
        notes:    ev.notes || []
      };

      const { data } = await axios.post(url, payload, { withCredentials: true });
      console.log('Director shift updated after drag =>', data);

      setEvents(prev => prev.map(e => {
        if (e.Id === ev.Id) {
          return { ...e, startTime: ev.startTime, endTime: ev.endTime };
        }
        return e;
      }));
    } catch (err) {
      console.error('Error updating director shift =>', err);
      alert('Error updating director shift. Reverting...');
    }
  }

  async function onActionBegin(args) {
    const rt = args.requestType;

    if (['eventCreate','eventChange','eventRemove'].includes(rt)) {
      const changedList = Array.isArray(args.data) ? args.data : [args.data];
      for (const ev of changedList) {
        // If shift belongs to a resource we do NOT allow editing => cancel
        if (ev.SupervisorId !== DIRECTOR_RESOURCE_ID &&
            ev.SupervisorId !== directorSupId) {
          args.cancel = true;
          return;
        }
      }

      if (rt === 'eventCreate' || rt === 'eventChange') {
        const { cancel, message } = validateShift(args, events, scheduleRef);
        if (cancel) {
          args.cancel = true;
          if (message) alert(message);
          return;
        }
      }
    }

    if (rt === 'eventChange') {
      args.cancel = true;
      const changedList = Array.isArray(args.data) ? args.data : [args.data];

      for (let ev of changedList) {
        if (scheduleRef.current?.currentView === 'TimelineWeek') {
          const orig = originalTimesRef.current[ev.Id];
          if (orig) {
            const newStart = new Date(ev.startTime);
            const newEnd   = new Date(ev.endTime);

            let startHr   = newStart.getHours();
            let startMin  = newStart.getMinutes();
            let endHr     = newEnd.getHours();
            let endMin    = newEnd.getMinutes();

            // If user drags across multiple days, revert the day portion
            // to the original date (only time changes).
            if (
              newStart.getFullYear() !== orig.start.getFullYear() ||
              newStart.getMonth()     !== orig.start.getMonth()    ||
              newStart.getDate()      !== orig.start.getDate()
            ) {
              startHr  = orig.start.getHours();
              startMin = orig.start.getMinutes();
            }
            if (
              newEnd.getFullYear() !== orig.end.getFullYear() ||
              newEnd.getMonth()     !== orig.end.getMonth()    ||
              newEnd.getDate()      !== orig.end.getDate()
            ) {
              endHr  = orig.end.getHours();
              endMin = orig.end.getMinutes();
            }

            const newStartDay = new Date(
              newStart.getFullYear(),
              newStart.getMonth(),
              newStart.getDate(),
              startHr,
              startMin,
              0
            );
            const newEndDay = new Date(
              newEnd.getFullYear(),
              newEnd.getMonth(),
              newEnd.getDate(),
              endHr,
              endMin,
              0
            );

            const dayDiff = (newEndDay - newStartDay) / (1000*60*60*24);
            if (dayDiff > 1) {
              newEndDay.setHours(23, 59, 59, 999);
            }

            ev.startTime = newStartDay;
            ev.endTime   = newEndDay;
          }
        }

        if (ev.SupervisorId === DIRECTOR_RESOURCE_ID) {
          await saveDirectorShiftViaDrag(ev);
        } else if (directorSupId && ev.SupervisorId === directorSupId) {
          await saveDirectorSupShiftViaDrag(ev);
        }
      }

      // Now fetch from server again, then refresh
      if (onEventsChanged) {
        await onEventsChanged();
      }
      scheduleRef.current?.refreshEvents();
      scheduleRef.current?.dataBind();
    }

    if (rt === 'eventRemove') {
      args.cancel = true;
      const removedList = Array.isArray(args.data) ? args.data : [args.data];
      for (let ev of removedList) {
        if (ev.SupervisorId === DIRECTOR_RESOURCE_ID) {
          await deleteDirectorShift(ev.Id);
        } else if (directorSupId && ev.SupervisorId === directorSupId) {
          await deleteSupervisorShiftInDb(ev.Id);
        }
      }

      // Re-fetch from server, then refresh
      if (onEventsChanged) {
        await onEventsChanged();
      }
      scheduleRef.current?.refreshEvents();
      scheduleRef.current?.dataBind();
    }
  }

  function onActionComplete(args) {
    if (args.requestType === 'viewNavigate' || args.requestType === 'dateNavigate') {
      setTimeout(() => {
        if (scheduleRef.current) {
          const vDates = scheduleRef.current.getCurrentViewDates();
          if (vDates?.length > 0) {
            const earliest = new Date(vDates[0]);
            earliest.setHours(0, 0, 0, 0);
            const latest = new Date(vDates[vDates.length - 1]);
            latest.setHours(23, 59, 59, 999);

            onDateRangeChange?.(
              formatLocalDateTime(earliest),
              formatLocalDateTime(latest)
            );

            if (scheduleRef.current.currentView === 'TimelineWeek') {
              const earliestStr = formatLocalDateTime(earliest);
              const latestStr   = formatLocalDateTime(latest);
              fetchCompletedWeeks(earliestStr, latestStr);
            }
          }
        }
      }, 0);
    }
  }

  function onDataBound() {
    // If we have a SupervisorHoursOverlay, recalc positions:
    if (overlayRef.current?.recalcPositions) {
      overlayRef.current.recalcPositions();
    }
  }

  function onPopupOpen(args) {
    // We always use our custom modals, so cancel default popups.
    if (args.type === 'Editor' || args.type === 'QuickInfo') {
      args.cancel = true;
    }
  }

function onEventRendered(args) {
  if (args.data.SupervisorId && args.data.SupervisorId !== DIRECTOR_RESOURCE_ID) {
    // Normal supervisors
    const found = supervisorResource.find(r => r.id === args.data.SupervisorId);
    if (found) {
      args.element.style.backgroundColor = found.color;
    }
    args.element.classList.remove('director-event');
  } else if (args.data.SupervisorId === DIRECTOR_RESOURCE_ID) {
    // Director row => add fancy class
    args.element.classList.add('director-event');
  }
}

  function shiftDurationTemplate(props) {
    const sDate = new Date(props.startTime);
    const eDate = new Date(props.endTime);
    if (isNaN(sDate) || isNaN(eDate)) {
      return (
        <div style={{ padding:'2px' }}>
          <div style={{ fontWeight:'bold' }}>{props.Subject}</div>
          <div>(Invalid date)</div>
        </div>
      );
    }
    const startStr = sDate.toLocaleTimeString([], { hour:'2-digit', minute:'2-digit' });
    const endStr   = eDate.toLocaleTimeString([], { hour:'2-digit', minute:'2-digit' });
    return (
      <div style={{ padding:'2px' }}>
        <div style={{ fontWeight:'bold' }}>{props.Subject}</div>
        <div>{startStr} - {endStr}</div>
      </div>
    );
  }

  return (
    <div style={{ position:'relative', display:'block', maxHeight:'none' }}>
      <LegacyDirectorModal
        isOpen={isLegacyDirectorModalOpen}
        initialEvent={directorRowEvent}
        onClose={() => setIsLegacyDirectorModalOpen(false)}
        onSave={handleDirectorModalSave}
        onDelete={handleDirectorModalDelete}
        availableStores={directorAreas}
      />

      <DirectorCustomModal
        isOpen={isSupModalOpen}
        eventData={supervisorRowEvent}
        onClose={() => setIsSupModalOpen(false)}
        onSave={handleSupervisorModalSave}
        onDelete={handleSupervisorModalDelete}
        directorStores={directorStores}
      />

      <ScheduleComponent
        ref={scheduleRef}
        rowAutoHeight={true}
        showQuickInfo={true}
        dragStart={handleDragStart}
        resizeStart={handleResizeStart}
        cellDoubleClick={handleCellDoubleClick}
        eventDoubleClick={handleEventDoubleClick}
        actionBegin={onActionBegin}
        actionComplete={onActionComplete}
        popupOpen={onPopupOpen}
        eventRendered={onEventRendered}
        dataBound={onDataBound}
        selectedDate={new Date()}
        firstDayOfWeek={1}
        currentView="Month"
        height="auto"
        width="100%"
        resourceHeaderTemplate={resourceHeaderTemplate}
        eventSettings={{
          dataSource: events,
          template: shiftDurationTemplate,
          fields: {
            id: 'Id',
            subject: { name:'Subject' },
            groupID: { name:'SupervisorId' },
            startTime: { name:'startTime' },
            endTime:   { name:'endTime' },
            description:{ name:'Description' }
          }
        }}
      >
        <ResourcesDirective>
          <ResourceDirective
            field="SupervisorId"
            title="Supervisor / Director"
            name="Supervisor"
            dataSource={supervisorResource}
            textField="text"
            idField="id"
            colorField="color"
            allowMultiple={false}
            expanded={true}
          />
        </ResourcesDirective>

        <ViewsDirective>
          <ViewDirective
            option="TimelineWeek"
            startHour="00:00"
            endHour="24:00"
            timeScale={{ enable:false }}
            group={{ resources:['Supervisor'] }}
            eventSettings={{
              enableTooltip:false,
              tooltipTemplate:ShiftTooltip
            }}
          />
          <ViewDirective
            option="Month"
            rowAutoHeight={true}
            group={{ resources:[] }}
            eventSettings={{
              enableTooltip:true,
              tooltipTemplate:ShiftTooltip
            }}
          />
        </ViewsDirective>
        <Inject services={[TimelineViews, Month, DragAndDrop, Resize]} />
      </ScheduleComponent>

      <SupervisorHoursOverlay
        ref={overlayRef}
        supervisorHoursMap={supervisorHoursMap}
        nameToIdMap={nameToIdMap}
      />
    </div>
  );
});

export default DirectorScheduleSection;
