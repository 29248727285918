// src/components/PrFileUpload.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

// Import your custom loading indicator
import LoadingIndicator from "../LoadingIndicator"; // Adjust the path as needed

// Chakra UI v2 imports
import {
  Box,
  Flex,
  Button,
  Text,
  Select,
  FormControl,
  FormLabel,
  Divider,
  useColorModeValue,
  Heading,
} from "@chakra-ui/react";
import { ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";

function PrFileUpload() {
  const [file, setFile] = useState(null);
  const [pdfUrl, setPdfUrl] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Toggle state for showing/hiding the form
  const [isFormVisible, setIsFormVisible] = useState(true);

  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState("");
  const [userRole, setUserRole] = useState("");
  const [warnings, setWarnings] = useState([]);

  // For color mode dynamic styling
  const errorBgColor = useColorModeValue("#ffe5e5", "#573232");
  const formBgColor = useColorModeValue("white", "gray.700");
  const gradientBg = useColorModeValue(
    "linear-gradient(to right, #f0f4f9, #d9e2f3)",
    "linear-gradient(to right, #2d3748, #1a202c)"
  );

  // Hover background for the drop-area
  const dropBgHover = useColorModeValue("blue.50", "gray.600");

  // On mount, fetch the user group and possible store list
  useEffect(() => {
    const fetchStores = async () => {
      const baseUrl = getBaseUrl();
      try {
        const {
          data: { userGroup },
        } = await axios.get(`${baseUrl}/api/user-group`, { withCredentials: true });
        setUserRole(userGroup);

        // Only fetch stores if user is a supervisor or director
        if (userGroup === "supervisor" || userGroup === "director") {
          const storesUrl =
            userGroup === "supervisor"
              ? `${baseUrl}/api/store-selection/stores-for-supervisor`
              : `${baseUrl}/api/store-selection/stores-for-director`;
          const { data: storesData } = await axios.get(storesUrl, { withCredentials: true });
          setStores(storesData);
        } else {
          setStores([]);
        }
      } catch (error) {
        console.error("Error fetching stores:", error);
        Swal.fire("Error", "Failed to fetch stores.", "error");
      }
    };

    fetchStores();
  }, []);

  // Adjust baseUrl based on environment/hostname
  const getBaseUrl = () => {
    const hostname = window.location.hostname;
    if (hostname === "72.167.34.236") {
      return "http://72.167.34.236:5000";
    } else if (/^(www\.)?prolifi\.app$/.test(hostname)) {
      return "https://prolifi.app";
    } else {
      return "";
    }
  };

  // Toggle the form visibility
  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
    setErrorMessage("");
  };

  // File selected
  const handleFileChange = (e) => {
    setFile(e.target.files[0] || null);
    setErrorMessage("");
    setPdfUrl("");
    setWarnings([]);
  };

  // Main upload + conversion
  const handleUpload = async (e) => {
    e.preventDefault();

    if (!file) {
      Swal.fire("Oops...", "Please select a file first", "error");
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    // Prepare form data
    const formData = new FormData();
    formData.append("file", file);

    // Helper to send an email report
    const sendEmailReport = async (storeID, pdfPath) => {
      try {
        setIsLoading(true); // Turn on loading while email is being sent
        const baseUrl = getBaseUrl();
        const forceSendResponse = await axios.post(
          `${baseUrl}/api/forceSendEmail`,
          { storeID, pdfPath },
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );
        Swal.fire("Sent!", forceSendResponse.data.message, "success");
      } catch (error) {
        Swal.fire("Error", "Failed to send the report.", "error");
      } finally {
        setIsLoading(false);
      }
    };

    try {
      const baseUrl = getBaseUrl();
      const response = await axios.post(`${baseUrl}/api/prFileUpload`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        withCredentials: true,
      });

      // If there's an Excel path, open it
      if (response.data.excelPath) {
        const excelFileName = response.data.excelPath.split("/").pop();
        const downloadUrl = `${baseUrl}/api/download/${excelFileName}`;
        window.open(downloadUrl, "_blank");
      }

      // If there's a PDF path, set up the iframe viewer
      if (response.data.pdfPath) {
        const pdfFileName = response.data.pdfPath.split("/").pop();
        const viewUrl = `${baseUrl}/api/view-pdf/${pdfFileName}`;
        setPdfUrl(viewUrl);
      }

      // If not a typical payroll day => user must confirm
      if (response.data.status === "not_payroll_day") {
        setIsLoading(false); // Turn off loading so we can interact with SweetAlert

        Swal.fire({
          title: "Are you sure?",
          text: "Today is not a typical payroll processing day. Do you still want to email the report to your supervisor?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, send it!",
          cancelButtonText: "No!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            const { storeID, pdfPath } = response.data;
            await sendEmailReport(storeID, pdfPath);
          } else {
            setIsLoading(false);
          }
        });

        // Show any warnings
        if (response.data.warnings && response.data.warnings.length > 0) {
          setWarnings(response.data.warnings);
        } else {
          setWarnings([]);
        }
      } else {
        // Typical payroll day => auto-send
        const { storeID, pdfPath } = response.data;
        await sendEmailReport(storeID, pdfPath);
      }
    } catch (error) {
      console.error("Upload error:", error);
      const errMessage =
        error.response && error.response.data
          ? error.response.data
          : "An unexpected error occurred";
      setErrorMessage(errMessage);
      setIsLoading(false);
    }
  };

  // Handling store selection
  const handleStoreChange = async (e) => {
    const storeId = e.target.value;
    setSelectedStore(storeId);

    try {
      const baseUrl = getBaseUrl();
      await axios.post(
        `${baseUrl}/api/update-selected-store`,
        { storeId },
        { withCredentials: true }
      );
    } catch (error) {
      console.error("Error updating session with selected store:", error);
    }
  };

  return (
    <Flex
      p={6}
      w="100%"
      flexDir="column"
      align="center"
      bg={gradientBg}
      minH="100vh"
    >
      {/* Full-screen overlay for the LoadingIndicator, only if actively loading */}
      {isLoading && (
        <Flex
          position="fixed"
          top="0"
          left="0"
          w="100vw"
          h="100vh"
          bg="rgba(0,0,0,0.5)"
          justify="center"
          align="center"
          zIndex="9999"
        >
          <LoadingIndicator />
        </Flex>
      )}

      <Box w="95%" maxW="1200px">

        {/* Container for the toggle and the form */}
        <Box
          borderWidth="1px"
          borderRadius="md"
          shadow="md"
          mb={1}
          bg={formBgColor}
          overflow="hidden"
        >
          {/* Thin toggle bar at the top */}
          <Box
            as="button"
            onClick={toggleFormVisibility}
            display="block"
            w="100%"
            borderBottomWidth={isFormVisible ? "1px" : "0"}
            borderColor={useColorModeValue("gray.200", "gray.600")}
            textAlign="center"
            py={0.5}
            fontSize="sm"
            bgGradient="linear(to-r, blue.500, blue.300)"
            color="white"
            _hover={{
              bgGradient: "linear(to-r, teal.500, blue.500)",
            }}
            _focus={{ outline: "none" }}
          >
            {isFormVisible ? (
              <>
                <ChevronUpIcon mr={2} />
                Hide Form
              </>
            ) : (
              <>
                <ChevronDownIcon mr={2} />
                Show Form
              </>
            )}
          </Box>

          {/* Render the form only if visible */}
          {isFormVisible && (
            <Box as="form" onSubmit={handleUpload} p={6}>
              <Text fontSize="2xl" fontWeight="bold" mb={0.5}>
                Upload & Convert
              </Text>
              <Text fontSize="sm" color="gray.500" mb={4}>
                Please select your payroll file. The converter will create the
                upload file for your payroll processor.
              </Text>
              <Divider mb={2} />

              {(userRole === "supervisor" || userRole === "director") && (
                <FormControl mb={2}>
                  <FormLabel fontWeight="semibold">Select Store</FormLabel>
                  <Select
                    placeholder="None"
                    value={selectedStore}
                    onChange={handleStoreChange}
                    focusBorderColor="blue.500"
                  >
                    {stores.map((storeID) => (
                      <option key={storeID.toString()} value={storeID}>
                        {storeID}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}

              {/* Modern, professional file upload area */}
              <FormControl mb={4}>
                <FormLabel fontWeight="semibold"></FormLabel>
                <Flex
                  as="label"
                  direction="column"
                  align="center"
                  justify="center"
                  p={4}
                  borderRadius="md"
                  border="2px dashed"
                  borderColor="blue.300"
                  cursor="pointer"
                  transition="background 0.2s"
                  _hover={{ bg: dropBgHover }}
                >
                  {file ? (
                    <>
                      <Text color="blue.500" fontWeight="bold">
                        {file.name}
                      </Text>
                      <Text fontSize="sm" color="gray.500">
                        Click to select a different TimeCard file.
                      </Text>
                    </>
                  ) : (
                    <Text fontSize="sm" color="gray.500">
                      Click or drag a file here to upload your TimeCard file.
                    </Text>
                  )}
                  <input
                    type="file"
                    hidden
                    onChange={handleFileChange}
                  />
                </Flex>
              </FormControl>

              {/* Align the Process button to the right */}
              <Flex justify="flex-end" mt={4}>
                <Button
                  type="submit"
                  bgGradient="linear(to-r, teal.400, blue.400)"
                  color="white"
                  fontWeight="bold"
                  borderRadius="md"
                  boxShadow="md"
                  transition="all 0.3s"
                  _hover={{
                    bgGradient: "linear(to-r, teal.500, blue.500)",
                    boxShadow: "lg",
                  }}
                  _active={{
                    bgGradient: "linear(to-r, teal.600, blue.600)",
                    boxShadow: "inner",
                  }}
                  h="48px"
                  px={6}
                  disabled={isLoading} // Button is disabled during loading
                >
                  Process
                </Button>
              </Flex>

              {warnings.length > 0 && (
                <Box
                  mt={4}
                  p={3}
                  borderWidth="1px"
                  borderRadius="md"
                  borderColor="orange.300"
                >
                  <Text fontSize="md" color="orange.500" fontWeight="semibold">
                    Warnings:
                  </Text>
                  {warnings.map((warning, index) => (
                    <Text key={index} color="orange.500" fontSize="sm">
                      - {warning}
                    </Text>
                  ))}
                </Box>
              )}
            </Box>
          )}
        </Box>

        {errorMessage && (
          <Box
            borderWidth="1px"
            borderRadius="md"
            shadow="sm"
            p={4}
            mb={6}
            maxW="600px"
            bg={errorBgColor}
            color="red.600"
            dangerouslySetInnerHTML={{ __html: errorMessage }}
          />
        )}

        {pdfUrl && (
          <Box
            mt={4}
            w="100%"
            h="80vh"
            borderWidth="1px"
            borderColor="gray.300"
            borderRadius="md"
            overflow="hidden"
            shadow="sm"
          >
            <iframe
              src={pdfUrl}
              title="PDF Preview"
              width="100%"
              height="100%"
              style={{ border: "none" }}
            />
          </Box>
        )}
      </Box>
    </Flex>
  );
}

export default PrFileUpload;
