/**
 * File: src/components/CustomModal.jsx
 *
 * We ensure that every shift has a valid Subject:
 *    "<StoreName> - reason1, reason2, reason3"
 * 
 * If the user selects "Other", we replace that with whatever
 * they typed in `ReasonOther`.
 */

import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';

/** Convert a Date => "YYYY-MM-DDTHH:mm" for datetime-local input */
function toDateTimeLocalString(date) {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  const y = date.getFullYear();
  const m = String(date.getMonth() + 1).padStart(2, '0');
  const d = String(date.getDate()).padStart(2, '0');
  const hh = String(date.getHours()).padStart(2, '0');
  const mm = String(date.getMinutes()).padStart(2, '0');
  return `${y}-${m}-${d}T${hh}:${mm}`;
}

/** Return array of reason strings for "store" vs "Office/Meetings" etc. */
function getReasonOptionsForCombinedId(combinedId) {
  if (!combinedId || combinedId === '-- Select an option --') {
    return [];
  }
  if (combinedId === 'Vacation') return [];
  if (combinedId === 'Office') {
    return [
	'End of Week',
	'Schedule Review',	
	'Inventory Review',
	'End of Month',
	'Other'
   ];
  }
  if (combinedId === 'Meetings') {
    return ['GM/Area', 'Supervisor', 'Class: AMLC', 'Class: EGMC', 'Other'];
  }
  // Otherwise => assume it's a "store" => multi reason
  return [
    'General Visit',
    'Struggling Sales, Profit, or KPIs',
    'Audit/Quarterly',
    'Working w/ MIT',
    'GM not Present',
    'Other',
  ];
}

export default function CustomModal({
  isOpen,
  eventData,
  onClose,
  onSave,
  onDelete,
  combinedOptions = [],
  allEvents = []
}) {
  const [formData, setFormData] = useState({
    Id: null,
    Subject: '',
    StartTime: new Date(),
    EndTime: new Date(),
    Description: '',
    CombinedId: '-- Select an option --',
    Reasons: [],
    ReasonOther: '',
  });

  // On mount or when eventData changes => parse subject => store + reasons
  useEffect(() => {
    if (!eventData) {
      setFormData({
        Id: null,
        Subject: '',
        StartTime: new Date(),
        EndTime: new Date(),
        Description: '',
        CombinedId: '-- Select an option --',
        Reasons: [],
        ReasonOther: '',
      });
      return;
    }

    const oldSubject = eventData.Subject || '';
    let parsedReasons = [];
    // If the subject was e.g. "48TH STREET - General Visit, HR"
    if (oldSubject.includes(' - ')) {
      const idx = oldSubject.indexOf(' - ');
      const afterDash = oldSubject.substring(idx + 3).trim();
      // e.g. "General Visit, HR"
      const splitted = afterDash.split(',');
      parsedReasons = splitted.map(r => r.trim()).filter(Boolean);
    }

    setFormData({
      Id: eventData.Id ?? null,
      Subject: oldSubject,
      StartTime: eventData.StartTime ? new Date(eventData.StartTime) : new Date(),
      EndTime: eventData.EndTime ? new Date(eventData.EndTime) : new Date(),
      Description: eventData.Description ?? '',
      CombinedId: eventData.CombinedId ?? '-- Select an option --',
      Reasons: parsedReasons,
      ReasonOther: '',
    });
  }, [eventData]);

  // Re-check the array of possible reason strings
  const reasonStrings = getReasonOptionsForCombinedId(formData.CombinedId);
  const reasonOptions = reasonStrings.map(r => ({ value: r, label: r }));
  const isOtherSelected = formData.Reasons.includes('Other');

  function handleReasonsChange(selected) {
    // selected => array of { value, label }
    if (!selected) {
      setFormData(prev => ({ ...prev, Reasons: [] }));
      return;
    }
    const selectedReasons = selected.map(item => item.value);
    setFormData(prev => ({ ...prev, Reasons: selectedReasons }));
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  }

  function handleDateTimeChange(e, fieldName) {
    setFormData(prev => ({
      ...prev,
      [fieldName]: new Date(e.target.value)
    }));
  }

  function handleSave() {
    // Ensure user selected a store/event
    if (formData.CombinedId === '-- Select an option --') {
      alert('Please select a store/event.');
      return;
    }
    // If reason array is required => if reasonStrings is non-empty, must pick at least 1
    if (reasonStrings.length > 0 && formData.Reasons.length === 0) {
      alert('Please select one or more reasons.');
      return;
    }

    // 1) Get store name
    const storeOption = combinedOptions.find(o => o.id === formData.CombinedId);
    const storeName = storeOption ? storeOption.text : formData.CombinedId;

    // 2) If "Other" is selected, replace it with "ReasonOther"
    let finalReasons = [...formData.Reasons];
    if (isOtherSelected && formData.ReasonOther.trim()) {
      const idx = finalReasons.indexOf('Other');
      if (idx !== -1) {
        finalReasons[idx] = formData.ReasonOther.trim();
      }
    }

    // 3) Join them with comma
    const reasonsStr = finalReasons.join(', ');

    // 4) Build new subject => "StoreName - reason1, reason2"
    let newSubject = storeName;
    if (reasonsStr) {
      newSubject += ' - ' + reasonsStr;
    }

    // 5) Call onSave => pass the updated Subject
    onSave({
      ...formData,
      Subject: newSubject,
    });
  }

  function handleDeleteClick() {
    if (formData.Id != null) {
      onDelete(formData.Id);
    }
  }

  const customStyles = {
    content: {
      maxWidth: '700px',
      margin: 'auto',
      borderRadius: '10px',
      padding: '20px',
      border: '1px solid #ddd',
      boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)',
      height: 'auto',
      maxHeight: '45vh',
      overflow: 'auto'
    },
    overlay: {
      zIndex: 999999,
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    }
  };

  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Custom Editor Modal"
      ariaHideApp={false}
      style={customStyles}
    >
      <h2 style={{ marginTop: 0, marginBottom: '1rem' }}>
        {formData.Id ? 'Edit Event' : 'Create Event'}
      </h2>

      {/* Store/Event dropdown */}
      <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
        <div style={{ flex: 1 }}>
          <label style={{ fontWeight: 'bold', display: 'block' }}>Store/Event</label>
          <select
            name="CombinedId"
            value={formData.CombinedId}
            onChange={handleChange}
            style={{
              width: '100%',
              padding: '8px',
              borderRadius: '4px',
              border: '1px solid #ccc'
            }}
          >
            <option value="-- Select an option --">-- Select an option --</option>
            {combinedOptions.map((opt) => (
              <option key={opt.id} value={opt.id}>
                {opt.text}
              </option>
            ))}
          </select>
        </div>

        {/* Multi-select for reasons => react-select */}
        {reasonStrings.length > 0 && (
          <div style={{ flex: 1 }}>
            <label style={{ fontWeight: 'bold', display: 'block' }}>Reasons</label>
            <Select
              isMulti
              options={reasonOptions}
              value={reasonOptions.filter(opt => formData.Reasons.includes(opt.value))}
              onChange={handleReasonsChange}
              placeholder="Select reason(s)..."
            />
          </div>
        )}
      </div>

      {/* If "Other" => show input */}
      {isOtherSelected && (
        <div style={{ marginBottom: '1rem' }}>
          <label style={{ fontWeight: 'bold', display: 'block' }}>Other Reason</label>
          <input
            type="text"
            name="ReasonOther"
            value={formData.ReasonOther}
            onChange={handleChange}
            placeholder="Type your custom reason..."
            style={{
              width: '100%',
              padding: '8px',
              borderRadius: '4px',
              border: '1px solid #ccc'
            }}
          />
        </div>
      )}

      {/* Start/End times */}
      <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
        <div style={{ flex: 1 }}>
          <label style={{ fontWeight: 'bold', display: 'block' }}>Start Time</label>
          <input
            type="datetime-local"
            name="StartTime"
            value={toDateTimeLocalString(formData.StartTime)}
            onChange={(e) => handleDateTimeChange(e, 'StartTime')}
            style={{
              width: '100%',
              padding: '8px',
              borderRadius: '4px',
              border: '1px solid #ccc'
            }}
          />
        </div>
        <div style={{ flex: 1 }}>
          <label style={{ fontWeight: 'bold', display: 'block' }}>End Time</label>
          <input
            type="datetime-local"
            name="EndTime"
            value={toDateTimeLocalString(formData.EndTime)}
            onChange={(e) => handleDateTimeChange(e, 'EndTime')}
            style={{
              width: '100%',
              padding: '8px',
              borderRadius: '4px',
              border: '1px solid #ccc'
            }}
          />
        </div>
      </div>

      {/* Description */}
      <div style={{ marginBottom: '1rem' }}>
        <label style={{ fontWeight: 'bold', display: 'block' }}>Description</label>
        <textarea
          name="Description"
          rows={3}
          style={{
            width: '100%',
            padding: '8px',
            borderRadius: '4px',
            border: '1px solid #ccc'
          }}
          placeholder="Notes or details..."
          value={formData.Description}
          onChange={handleChange}
        />
      </div>

      {/* Bottom buttons */}
      <div style={{ marginTop: '1.5rem', display: 'flex', gap: '1rem' }}>
        {formData.Id && (
          <button
            onClick={handleDeleteClick}
            style={{
              background: '#f44336',
              color: '#fff',
              padding: '8px 16px',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
          >
            Delete
          </button>
        )}
        <button
          onClick={handleSave}
          style={{
            background: '#2196f3',
            color: '#fff',
            padding: '8px 16px',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
        >
          Save
        </button>
        <button
          onClick={onClose}
          style={{
            background: '#bbb',
            color: '#333',
            padding: '8px 16px',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
}
