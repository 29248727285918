import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import axios from 'axios';
import { getBaseUrl } from '../../../../utils/getBaseUrl';

/**
 * Convert a JS Date => "YYYY-MM-DD HH:mm:ss"
 * suitable for your pfieldEvents DB which expects this format.
 */
function toMySqlDateTime(date) {
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return '';
  }
  const yyyy = date.getFullYear();
  const mm   = String(date.getMonth() + 1).padStart(2, '0');
  const dd   = String(date.getDate()).padStart(2, '0');
  const HH   = String(date.getHours()).padStart(2, '0');
  const MM   = String(date.getMinutes()).padStart(2, '0');
  const SS   = String(date.getSeconds()).padStart(2, '0');
  return `${yyyy}-${mm}-${dd} ${HH}:${MM}:${SS}`;
}

/**
 * For the <input type="datetime-local" /> we need "YYYY-MM-DDTHH:mm".
 */
function toDateTimeLocalString(date) {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  const y  = date.getFullYear();
  const m  = String(date.getMonth() + 1).padStart(2, '0');
  const d  = String(date.getDate()).padStart(2, '0');
  const hh = String(date.getHours()).padStart(2, '0');
  const mm = String(date.getMinutes()).padStart(2, '0');
  return `${y}-${m}-${d}T${hh}:${mm}`;
}

/**
 * Returns a list of "reason" strings depending on the store/event chosen.
 */
function getReasonOptionsForCombinedId(combinedId) {
  if (!combinedId || combinedId === '-- Select an option --') {
    return [];
  }
  if (combinedId === 'Vacation') {
    return [];
  }
  if (combinedId === 'Office') {
    return ['End of Week', 'Schedule Review', 'Inventory Review', 'End of Month', 'Other'];
  }
  if (combinedId === 'Meetings') {
    return ['GM/Area', 'Supervisor', 'Class: AMLC', 'Class: EGMC', 'Other'];
  }
  // Otherwise => assume it's a store => multiple possible reasons
  return [
    'General Visit',
    'Struggling Sales, Profit, or KPIs',
    'Audit/Quarterly',
    'Working w/ MIT',
    'GM not Present',
    'Other',
  ];
}

/**
 * DirectorCustomModal
 * - Merges user’s store list with static items: "Office", "Meetings", "Vacation"
 * - Lets user pick "store/event", reasons, date/time
 * - On Save => calls your /pfield_events route to create/update the shift in DB
 *   then calls onSave() so the parent can refresh (making sure the new event
 *   has a SupervisorId so it appears right away in local state).
 */
export default function DirectorCustomModal({
  isOpen,
  eventData,
  onClose,
  onSave,
  onDelete,
  directorStores = []
}) {
  // 1) The user’s supervised store list from /my_sup_stores
  const [myStoreList, setMyStoreList] = useState([]);

  // 2) Some static items
  const staticItems = [
    { id: 'Office',   text: 'Office' },
    { id: 'Meetings', text: 'Meetings' },
    { id: 'Vacation', text: 'Vacation' }
  ];
  const [combinedOptions, setCombinedOptions] = useState([]);

  // 3) Our local form data (added SupervisorId to track the row)
  const [formData, setFormData] = useState({
    Id: null,
    SupervisorId: null,
    Subject: '',
    StartTime: new Date(),
    EndTime: new Date(),
    Description: '',
    CombinedId: '-- Select an option --',
    Reasons: [],
    ReasonOther: ''
  });

  // 4) If modal is opening => fetch user’s store list
  useEffect(() => {
    if (isOpen) {
      fetchMySupervisorStores();
    }
  }, [isOpen]);

  async function fetchMySupervisorStores() {
    try {
      const baseUrl = getBaseUrl();
      const url = `${baseUrl}/pfield_events/pfield_director/my_sup_stores`;
      const res = await axios.get(url, { withCredentials: true });
      if (res.data.success && Array.isArray(res.data.stores)) {
        const storeOpts = res.data.stores.map(s => ({
          id: String(s.store_id),
          text: s.store_name
        }));
        setMyStoreList(storeOpts);
      } else {
        setMyStoreList([]);
      }
    } catch (err) {
      console.error('Error fetching my sup stores =>', err);
      setMyStoreList([]);
    }
  }

  // 5) Merge store list + static items
  useEffect(() => {
    setCombinedOptions([...myStoreList, ...staticItems]);
  }, [myStoreList]);

  // 6) If eventData changes => parse it or set defaults for new shift
  useEffect(() => {
    if (!eventData) {
      // NEW SHIFT => default times 9–5
      const start9 = new Date();
      start9.setHours(9, 0, 0, 0);
      const end5 = new Date(start9);
      end5.setHours(17, 0, 0, 0);

      setFormData({
        Id: null,
        SupervisorId: null,
        Subject: '',
        StartTime: start9,
        EndTime: end5,
        Description: '',
        CombinedId: '-- Select an option --',
        Reasons: [],
        ReasonOther: ''
      });
      return;
    }

    // EDIT SHIFT => parse the subject for storeName / reasons
    const oldSubject = eventData.Subject || '';
    let parsedReasons = [];
    let combinedId = '-- Select an option --';

    // If the subject was "Some Store - reason, reason, ..."
    if (oldSubject.includes(' - ')) {
      const idx = oldSubject.indexOf(' - ');
      const afterDash = oldSubject.substring(idx + 3).trim();
      parsedReasons = afterDash.split(',').map(r => r.trim()).filter(Boolean);

      const storeName = oldSubject.substring(0, idx).trim();
      const foundOpt = combinedOptions.find(o => o.text === storeName);
      if (foundOpt) {
        combinedId = foundOpt.id;
      }
    } else {
      // maybe it's just "Vacation" or "Office", no dash
      const foundOpt = combinedOptions.find(o => o.text === oldSubject);
      if (foundOpt) {
        combinedId = foundOpt.id;
      }
    }

    setFormData({
      Id: eventData.Id ?? null,
      SupervisorId: eventData.SupervisorId ?? null, // preserve row
      Subject: oldSubject,
      StartTime: eventData.startTime ? new Date(eventData.startTime) : new Date(),
      EndTime: eventData.endTime ? new Date(eventData.endTime) : new Date(),
      Description: eventData.Description ?? '',
      CombinedId: combinedId,
      Reasons: parsedReasons,
      ReasonOther: ''
    });
  // Re-run if "combinedOptions" changes (so we can re-match the store name properly)
  }, [eventData, combinedOptions]);

  // 7) Reason logic
  const foundOpt = combinedOptions.find(o => o.id === formData.CombinedId);
  const reasonStrings = getReasonOptionsForCombinedId(foundOpt?.text || '');
  const reasonOptions = reasonStrings.map(r => ({ value: r, label: r }));
  const isOtherSelected = formData.Reasons.includes('Other');

  // 8) handle user changes to fields
  function handleChange(e) {
    const { name, value } = e.target;

    setFormData(prev => {
      const updated = { ...prev, [name]: value };

      // If the user just changed "CombinedId" to a store (not an event),
      // ensure "General Visit" is in the reasons
      if (
        name === 'CombinedId' &&
        value !== 'Office' &&
        value !== 'Meetings' &&
        value !== 'Vacation' &&
        value !== '-- Select an option --'
      ) {
        if (!updated.Reasons.includes('General Visit')) {
          updated.Reasons = [...updated.Reasons, 'General Visit'];
        }
      }

      return updated;
    });
  }

  function handleReasonsChange(selected) {
    if (!selected) {
      setFormData(prev => ({ ...prev, Reasons: [] }));
      return;
    }
    const selectedReasons = selected.map(x => x.value);
    setFormData(prev => ({ ...prev, Reasons: selectedReasons }));
  }

  function handleDateTimeChange(e, fieldName) {
    const dt = new Date(e.target.value);
    setFormData(prev => ({ ...prev, [fieldName]: dt }));
  }

  // 9) handle Save => build final subject => do DB call => then onSave()
  async function handleSave() {
    // Make sure the user picked a store/event
    if (formData.CombinedId === '-- Select an option --') {
      alert('Please pick a store/event first.');
      return;
    }
    // If this store/event has "reasons," ensure at least one reason is chosen
    if (reasonStrings.length > 0 && formData.Reasons.length === 0) {
      alert('Please pick at least one reason.');
      return;
    }

    // If "Other" is chosen, replace it with the user’s typed text
    let finalReasons = [...formData.Reasons];
    if (isOtherSelected && formData.ReasonOther.trim()) {
      const idx = finalReasons.indexOf('Other');
      if (idx !== -1) {
        finalReasons[idx] = formData.ReasonOther.trim();
      }
    }

    // Build the final Subject => "StoreName - reason, reason"
    const reasonsStr = finalReasons.join(', ');
    const storeName = foundOpt ? foundOpt.text : 'Unknown';
    let newSubject = storeName;
    if (reasonsStr) {
      newSubject += ` - ${reasonsStr}`;
    }

    // Convert start/end to DB timestamps
    const startStr = toMySqlDateTime(formData.StartTime);
    const endStr = toMySqlDateTime(formData.EndTime);

    // For demo => always CategoryId=1
    const categoryId = 1;

    const payload = {
      Subject: newSubject,
      StartTime: startStr,
      EndTime: endStr,
      Description: formData.Description || '',
      CombinedId: formData.CombinedId,
      CategoryId: categoryId
      // We do NOT send SupervisorId because the server route handles that logic
    };

    try {
      const baseUrl = getBaseUrl();
      if (formData.Id) {
        // Updating existing
        await axios.put(`${baseUrl}/pfield_events/${formData.Id}`, payload, { withCredentials: true });
        console.log('[DirectorCustomModal] => updated shift =>', formData.Id);
      } else {
        // Creating new
        const res = await axios.post(`${baseUrl}/pfield_events`, payload, { withCredentials: true });
        console.log('[DirectorCustomModal] => new shift inserted =>', res.data);
        if (res.data.insertedId) {
          formData.Id = res.data.insertedId;
        }
      }

      // Send updated shift to parent
      onSave({
        ...formData,
        Subject: newSubject,
        // Include SupervisorId so the parent can show it right away
        SupervisorId: formData.SupervisorId
      });
    } catch (err) {
      console.error('[DirectorCustomModal] => handleSave => error =>', err);
      alert('Error saving shift to the database. Check console.');
    }
  }

  function handleDeleteClick() {
    if (formData.Id != null) {
      onDelete(formData.Id);
    }
  }

  // 10) Modal styles
  const customStyles = {
    content: {
      maxWidth: '700px',
      margin: 'auto',
      borderRadius: '10px',
      padding: '20px',
      border: '1px solid #ddd',
      boxShadow: '0 4px 16px rgba(0,0,0,0.2)',
      height: 'auto',
      maxHeight: '75vh',
      overflow: 'auto'
    },
    overlay: {
      zIndex: 999999,
      backgroundColor: 'rgba(0,0,0,0.5)'
    }
  };

  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Director Editor Modal"
      ariaHideApp={false}
      style={customStyles}
    >
      <h2 style={{ marginTop: 0, marginBottom: '1rem' }}>
        {formData.Id ? 'Edit Director Event' : 'Create Director Event'}
      </h2>

      {/* Store/Event dropdown */}
      <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
        <div style={{ flex: 1 }}>
          <label style={{ fontWeight: 'bold', display: 'block' }}>Store/Event</label>
          <select
            name="CombinedId"
            value={formData.CombinedId}
            onChange={handleChange}
            style={{
              width: '100%',
              padding: '8px',
              borderRadius: '4px',
              border: '1px solid #ccc'
            }}
          >
            <option value="-- Select an option --">-- Select an option --</option>
            {combinedOptions.map(opt => (
              <option key={opt.id} value={opt.id}>
                {opt.text}
              </option>
            ))}
          </select>
        </div>

        {/* If reasonStrings => show a multi-select for reasons */}
        {reasonStrings.length > 0 && (
          <div style={{ flex: 1 }}>
            <label style={{ fontWeight: 'bold', display: 'block' }}>Reasons</label>
            <Select
              isMulti
              options={reasonOptions}
              value={reasonOptions.filter(opt => formData.Reasons.includes(opt.value))}
              onChange={handleReasonsChange}
              placeholder="Select reason(s)..."
            />
          </div>
        )}
      </div>

      {/* If "Other" => show the ReasonOther input */}
      {isOtherSelected && (
        <div style={{ marginBottom: '1rem' }}>
          <label style={{ fontWeight: 'bold', display: 'block' }}>Other Reason</label>
          <input
            type="text"
            name="ReasonOther"
            value={formData.ReasonOther}
            onChange={handleChange}
            placeholder="Type your custom reason..."
            style={{
              width: '100%',
              padding: '8px',
              borderRadius: '4px',
              border: '1px solid #ccc'
            }}
          />
        </div>
      )}

      {/* Start/End => datetime-local */}
      <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
        <div style={{ flex: 1 }}>
          <label style={{ fontWeight: 'bold', display: 'block' }}>Start Time</label>
          <input
            type="datetime-local"
            name="StartTime"
            value={toDateTimeLocalString(formData.StartTime)}
            onChange={e => handleDateTimeChange(e, 'StartTime')}
            style={{
              width: '100%',
              padding: '8px',
              borderRadius: '4px',
              border: '1px solid #ccc'
            }}
          />
        </div>
        <div style={{ flex: 1 }}>
          <label style={{ fontWeight: 'bold', display: 'block' }}>End Time</label>
          <input
            type="datetime-local"
            name="EndTime"
            value={toDateTimeLocalString(formData.EndTime)}
            onChange={e => handleDateTimeChange(e, 'EndTime')}
            style={{
              width: '100%',
              padding: '8px',
              borderRadius: '4px',
              border: '1px solid #ccc'
            }}
          />
        </div>
      </div>

      {/* Description (optional) */}
      <div style={{ marginBottom: '1rem' }}>
        <label style={{ fontWeight: 'bold', display: 'block' }}>Description</label>
        <textarea
          name="Description"
          rows={3}
          style={{
            width: '100%',
            padding: '8px',
            borderRadius: '4px',
            border: '1px solid #ccc'
          }}
          placeholder="Notes or details..."
          value={formData.Description}
          onChange={handleChange}
        />
      </div>

      {/* Buttons */}
      <div style={{ marginTop: '1.5rem', display: 'flex', gap: '1rem' }}>
        {formData.Id && (
          <button
            onClick={handleDeleteClick}
            style={{
              background: '#f44336',
              color: '#fff',
              padding: '8px 16px',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
          >
            Delete
          </button>
        )}
        <button
          onClick={handleSave}
          style={{
            background: '#2196f3',
            color: '#fff',
            padding: '8px 16px',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
        >
          Save
        </button>
        <button
          onClick={onClose}
          style={{
            background: '#bbb',
            color: '#333',
            padding: '8px 16px',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
}
