// src/components/GradingStandardsEditor.js
import React, { useEffect, useState } from "react";
import axios from "axios";

// Chakra UI
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  Switch as ChakraSwitch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

// KPI Groups, labelMappings, etc.

const kpiGroups = {
  Profitability: [
    "SalesChange",
    "ContExpenses",
    "CrewProduction",
    "LaborPercentage",
    "FoodVarianceMonth",
    "FormattedCash",
  ],
  Service: [
    "ComplaintsPer10k",
    "OnTimesPercentage",
    "FormattedAveTime",
    "FormattedReplyTime",
  ],
};

const labelMappings = {
  SalesChange: {
    yellowThreshold: "Red Threshold",
  },
};

function GradingStandardsEditor() {
  // ----------------- STATE -----------------
  const [initialStandards, setInitialStandards] = useState({});
  const [gradingStandards, setGradingStandards] = useState({});
  const [currentGroup, setCurrentGroup] = useState("Profitability");
  const [userData, setUserData] = useState({});
  const [selectedArea, setSelectedArea] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [changesToConfirm, setChangesToConfirm] = useState([]);

  // ------------- UNCONDITIONAL HOOKS -------------
  // Call all useColorModeValue hooks at the top
  const pageBg = useColorModeValue("gray.50", "gray.800");
  const containerBg = useColorModeValue("white", "gray.700");
  const confirmBg = useColorModeValue("gray.100", "gray.600");

  // ------------- HELPER FUNCTIONS -------------
  const getBaseUrl = () => {
    const { hostname } = window.location;
    return hostname.includes("prolifi.app")
      ? "https://prolifi.app"
      : "http://72.167.34.236:5000";
  };

  // Input change => update local state
  const handleInputChange = (kpiName, thresholdType, value) => {
    setGradingStandards((prev) => ({
      ...prev,
      [kpiName]: {
        ...prev[kpiName],
        [thresholdType]: value,
      },
    }));
  };

  // On mount => fetch user data
  useEffect(() => {
    const baseUrl = getBaseUrl();
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/egrading-standards/user-session-data`,
          { withCredentials: true }
        );
        if (response.data.success) {
          setUserData(response.data.data);
          setSelectedArea(response.data.data.areas[0] || "");
        } else {
          setError("Failed to fetch user data");
        }
      } catch (err) {
        setError("Error fetching user data");
      }
      setLoading(false);
    };
    fetchUserData();
    // eslint-disable-next-line
  }, []);

  // If selectedArea changes => fetch that area’s grading standards
  useEffect(() => {
    if (selectedArea) {
      fetchGradingStandards(selectedArea);
    }
    // eslint-disable-next-line
  }, [selectedArea]);

  // Re-fetch logic
  const fetchGradingStandards = async (area) => {
    const baseUrl = getBaseUrl();
    try {
      const response = await axios.get(
        `${baseUrl}/api/egrading-standards/grading-standards/${area}`,
        { withCredentials: true }
      );
      if (response.data.success) {
        const standards = {};
        response.data.data.forEach((item) => {
          standards[item.kpi_name] = {
            greenThreshold: item.green_threshold,
            yellowThreshold: item.yellow_threshold,
            redThreshold: item.red_threshold,
          };
        });
        setGradingStandards(standards);
        setInitialStandards(standards);
      } else {
        setError("No grading standards found for the selected area.");
        setGradingStandards({});
        setInitialStandards({});
      }
    } catch (err) {
      setError("Failed to fetch grading standards");
      console.error(err);
    }
  };

  // Resets local state => discards unsaved changes
  const resetToInitialStandards = () => {
    setGradingStandards({ ...initialStandards });
    setShowConfirmation(false);
  };

  // Prepares changes => triggers the "Confirm" dialog
  const prepareSubmission = () => {
    const changes = [];
    const updatedStandards = {};

    Object.keys(gradingStandards).forEach((kpiName) => {
      const current = gradingStandards[kpiName];
      const init = initialStandards[kpiName] || {};
      let hasChanges = false;
      const kpiChanges = {};

      ["greenThreshold", "yellowThreshold", "redThreshold"].forEach(
        (threshold) => {
          if (current[threshold] !== init[threshold]) {
            kpiChanges[threshold] = current[threshold];
            changes.push(
              `${kpiName} - ${threshold}: from ${
                init[threshold] || "none"
              } to ${current[threshold]}`
            );
            hasChanges = true;
          }
        }
      );

      if (hasChanges) {
        updatedStandards[kpiName] = kpiChanges;
      }
    });

    if (Object.keys(updatedStandards).length) {
      setChangesToConfirm(changes);
      setShowConfirmation(true);
      setGradingStandards((prev) => ({
        ...prev,
        ...updatedStandards,
      }));
    } else {
      alert("No changes to submit.");
    }
  };

  // Final submission => saves changes
  const handleSubmit = async () => {
    if (!showConfirmation) {
      prepareSubmission();
      return;
    }

    const baseUrl = getBaseUrl();
    try {
      const response = await axios.post(
        `${baseUrl}/api/egrading-standards/update-grading-standards`,
        {
          area: selectedArea,
          gradingStandards,
        }
      );
      console.log(response.data);
      alert("Grading standards updated successfully!");
      setShowConfirmation(false);
      fetchGradingStandards(selectedArea); // refresh
    } catch (err) {
      console.error("Failed to update grading standards:", err);
      alert("Failed to update grading standards.");
    }
  };

  // If data is still loading => fallback
  if (loading) return <Text>Loading...</Text>;

  return (
    <Box minH="100vh" bg={pageBg} p={6}>
      <Box maxW="900px" mx="auto" p={6} bg={containerBg} boxShadow="md" borderRadius="md">
        <Heading as="h1" size="lg" mb={4}>
          Edit Grading Standards
        </Heading>
        {error && (
          <Text color="red.400" mb={4}>
            {error}
          </Text>
        )}

        {/* Area Selection */}
        <FormControl mb={4} w="280px">
          <FormLabel>Select Area:</FormLabel>
          <Select
            value={selectedArea}
            onChange={(e) => setSelectedArea(e.target.value)}
            isDisabled={userData.areas?.length <= 1}
          >
            {userData.areas?.map((area) => (
              <option key={area} value={area}>
                {area}
              </option>
            ))}
          </Select>
        </FormControl>

        {/* Toggle between "Profitability" vs "Service" */}
        <Flex alignItems="center" mb={4}>
          <Text fontWeight="semibold" mr={3}>
            KPI Group:
          </Text>
          <ChakraSwitch
            id="kpi-group-switch"
            colorScheme="teal"
            isChecked={currentGroup === "Profitability"}
            onChange={(e) =>
              setCurrentGroup(e.target.checked ? "Profitability" : "Service")
            }
            mr={3}
          />
          <Text fontWeight="bold">{currentGroup}</Text>
        </Flex>

        {/* List of KPIs for the current group */}
        <Box>
          {kpiGroups[currentGroup].map((kpiName) => {
            const thresholds = gradingStandards[kpiName] || {
              greenThreshold: "",
              yellowThreshold: "",
              redThreshold: "",
            };
            const customLabels = labelMappings[kpiName] || {};

            return (
              <Box key={kpiName} borderWidth="1px" borderRadius="md" p={4} mb={4}>
                <Heading as="h2" size="md" mb={2}>
                  {kpiName}
                </Heading>

                {/* Green */}
                <FormControl mb={2} maxW="280px">
                  <FormLabel>Green Threshold:</FormLabel>
                  <Input
                    type="number"
                    value={thresholds.greenThreshold}
                    onChange={(e) =>
                      handleInputChange(kpiName, "greenThreshold", e.target.value)
                    }
                  />
                </FormControl>

                {/* Yellow or "Red" label if SalesChange */}
                {kpiName === "SalesChange" ? (
                  <FormControl mb={2} maxW="280px">
                    <FormLabel>
                      {customLabels.yellowThreshold || "Yellow Threshold"}:
                    </FormLabel>
                    <Input
                      type="number"
                      value={thresholds.yellowThreshold}
                      onChange={(e) =>
                        handleInputChange(kpiName, "yellowThreshold", e.target.value)
                      }
                    />
                  </FormControl>
                ) : thresholds.yellowThreshold !== null && (
                  <FormControl mb={2} maxW="280px">
                    <FormLabel>Yellow Threshold:</FormLabel>
                    <Input
                      type="number"
                      value={thresholds.yellowThreshold}
                      onChange={(e) =>
                        handleInputChange(kpiName, "yellowThreshold", e.target.value)
                      }
                    />
                  </FormControl>
                )}

                {/* Red threshold for all except SalesChange */}
                {kpiName !== "SalesChange" && thresholds.redThreshold !== null && (
                  <FormControl mb={2} maxW="280px">
                    <FormLabel>Red Threshold:</FormLabel>
                    <Input
                      type="number"
                      value={thresholds.redThreshold}
                      onChange={(e) =>
                        handleInputChange(kpiName, "redThreshold", e.target.value)
                      }
                    />
                  </FormControl>
                )}
              </Box>
            );
          })}
        </Box>

        {/* Save Changes Button */}
        <Button colorScheme="teal" onClick={handleSubmit} mb={showConfirmation ? 2 : 0}>
          Save Changes
        </Button>

        {/* Confirmation Dialog */}
        {showConfirmation && (
          <Box
            mt={4}
            p={4}
            borderWidth="1px"
            borderRadius="md"
            bg={confirmBg} // useColorModeValue for dialog background
          >
            <Heading as="h3" size="sm" mb={2}>
              You are submitting the following changes to {selectedArea}:
            </Heading>
            <Box mb={3}>
              {changesToConfirm.map((change) => (
                <Text key={change} fontSize="sm">
                  {change}
                </Text>
              ))}
            </Box>
            <Flex gap={2}>
              <Button colorScheme="teal" onClick={handleSubmit}>
                Confirm
              </Button>
              <Button variant="outline" onClick={resetToInitialStandards}>
                Cancel
              </Button>
            </Flex>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default GradingStandardsEditor;
