import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Still using Syncfusion DatePicker for month selector
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';

import styles from './TZFileUploadV2.module.css';
import LoadingIndicator from '../LoadingIndicator';

// Import the LFLPromptModal
import LFLPromptModal from './LFLPromptModal';

function TZFileUploadV2() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);

  // Show or hide the Syncfusion date picker
  const [showMonthSelector, setShowMonthSelector] = useState(false);
  // The selected date for submission month
  const [selectedDate, setSelectedDate] = useState(null);

  // Checkbox state for disabling emails
  const [disableEmails, setDisableEmails] = useState(false);

  // Optional loading state
  const [isLoading, setIsLoading] = useState(false);

  // State to control the LFL prompt modal
  const [showLFLPrompt, setShowLFLPrompt] = useState(false);

  /**
   * Helper to figure out backend base URL
   */
  const getBaseUrl = () => {
    const hostname = window.location.hostname;
    if (hostname === '72.167.34.236') {
      return 'http://72.167.34.236:5000';
    } else if (/^(www\.)?prolifi\.app$/.test(hostname)) {
      return 'https://prolifi.app';
    } else {
      // Default or fallback
      return '';
    }
  };

  /**
   * If it's on or before the 5th of the month, show the month selector
   */
  useEffect(() => {
    const today = new Date();
    if (today.getDate() <= 5) {
      setShowMonthSelector(true);
      setSelectedDate(today);
    }
  }, []);

  /**
   * Helper to format a JS Date as "YYYY-MM"
   */
  const formatToYYYYMM = (dateObj) => {
    if (!dateObj || !(dateObj instanceof Date) || isNaN(dateObj)) {
      return '';
    }
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // zero-based => +1
    return `${year}-${month}`;
  };

  /**
   * Fired when user selects a file via <input> or drag-drop
   */
  const handleFileChange = (e) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setError(null);
      setUploadStatus(null);
    } else {
      setSelectedFile(null);
    }
  };

  /**
   * Called when user clicks "Process File"
   * We first open the LFL prompt modal
   */
  const handleProcessClick = () => {
    if (!selectedFile) {
      setError('Please select an Excel file before proceeding.');
      return;
    }
    // Show the LFL modal prompt
    setShowLFLPrompt(true);
  };

  /**
   * If user says "No" to LFL, skip it and do main upload
   */
  const handleSkipLFL = () => {
    setShowLFLPrompt(false);
    handleMainUpload();
  };

  /**
   * If user says "Yes" and picks an LFL file
   */
  const handleConfirmLFL = async (lflFile) => {
    setShowLFLPrompt(false);
    setIsLoading(true);
    setError(null);
    setUploadStatus(null);

    try {
      // 1) Upload LFL file first
      const baseUrl = getBaseUrl();
      const lflForm = new FormData();
      lflForm.append('lflFile', lflFile);

      await axios.post(`${baseUrl}/api/tzv2/upload-lfl`, lflForm, {
        headers: { 'Content-Type': 'multipart/form-data' },
        withCredentials: true
      });

      // 2) After LFL success, proceed with main file
      await handleMainUpload();
    } catch (err) {
      console.error('Error uploading LFL file:', err);
      setError('An error occurred while uploading the LFL file. Please try again.');
      setIsLoading(false);
    }
  };

  /**
   * Actually do the main TZ file upload logic
   */
  const handleMainUpload = async () => {
    setIsLoading(true);
    setError(null);
    setUploadStatus(null);

    try {
      const baseUrl = getBaseUrl();
      const formData = new FormData();
      formData.append('excelFile', selectedFile);

      // If we have a submission month selected, add it
      if (showMonthSelector && selectedDate) {
        const submissionMonth = formatToYYYYMM(selectedDate);
        if (submissionMonth) {
          formData.append('submissionMonth', submissionMonth);
        }
      }

      // If "Disable Email triggers" is checked, add that as well
      formData.append('disableEmails', disableEmails ? 'true' : 'false');

      // Send to your node backend
      await axios.post(`${baseUrl}/api/tzv2/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        withCredentials: true
      });

      // If successful
      setUploadStatus('File processed successfully');
    } catch (err) {
      console.error('Error uploading main file:', err);
      setError('An error occurred while processing the file. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>TotZone Upload v2</h2>
      <p className={styles.instructions}>
        New TotZone processing file uploaded here. Click
        <strong> Process File </strong> after selecting your file.
      </p>

      {/* Row for month picker and "Disable Emails" checkbox side by side */}
      <div className={styles.controlsRow}>
        {/* Month Selector */}
        {showMonthSelector && (
          <div className={styles.monthSelector}>
            <label>Submission Month</label>
            <DatePickerComponent
              placeholder="Pick the month"
              format="MMMM yyyy"
              start="Year"
              depth="Year"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.value)}
            />
          </div>
        )}

        {/* Disable Emails checkbox */}
        <div className={styles.checkboxContainer}>
          <input
            type="checkbox"
            id="disableEmails"
            checked={disableEmails}
            onChange={(e) => setDisableEmails(e.target.checked)}
          />
          <label htmlFor="disableEmails">Disable Email triggers</label>
        </div>
      </div>

      {/* Custom File Upload Section */}
      <div className={styles.uploadSection}>
        <label className={styles.dragArea}>
          Drag & drop or click to upload
          <input
            type="file"
            accept=".xlsx,.xls"
            className={styles.fileInput}
            onChange={handleFileChange}
          />
        </label>
        {error && <div className={styles.errorMessage}>{error}</div>}
      </div>

      {/* File Preview */}
      {selectedFile && (
        <div className={styles.filePreview}>
          <h3>File Selected:</h3>
          <p>{selectedFile.name}</p>
          <p><strong>Size:</strong> {(selectedFile.size / 1024).toFixed(2)} KB</p>
        </div>
      )}

      {/* Process File Button */}
      <div className={styles.buttonContainer}>
        <button className={styles.uploadButton} onClick={handleProcessClick}>
          Process File
        </button>
      </div>

      {/* Loading Indicator if needed */}
      {isLoading && (
        <div className={styles.loadingSection}>
          <LoadingIndicator />
        </div>
      )}

      {/* Result Section */}
      {uploadStatus && (
        <div className={styles.resultSection}>
          <h3>TZv2 Results:</h3>
          <p>{uploadStatus}</p>
        </div>
      )}

      {/* Our LFL Prompt Modal (conditionally rendered) */}
      <LFLPromptModal
        visible={showLFLPrompt}
        onClose={() => setShowLFLPrompt(false)}
        onSkipLFL={handleSkipLFL}
        onConfirmLFL={handleConfirmLFL}
      />
    </div>
  );
}

export default TZFileUploadV2;
