// src/components/ConverterInstructions.js

import React, { useState } from "react";
import {
  Box,
  Heading,
  Text,
  Link as ChakraLink,
  OrderedList,
  ListItem,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

const ConverterInstructions = () => {
  const [openSection, setOpenSection] = useState(null);

  // For a bit of subtle dynamic theming:
  const sectionBg = useColorModeValue("gray.50", "gray.700");
  const borderCol = useColorModeValue("gray.200", "gray.600");
  const linkColor = useColorModeValue("blue.600", "blue.300");

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  return (
    <Box
      p={4}
      borderRadius="md"
      borderWidth="1px"
      borderColor={borderCol}
      bg={useColorModeValue("white", "gray.800")}
      shadow="md"
    >
      <Heading as="h3" size="md" mb={3} color={useColorModeValue("blue.600", "blue.300")}>
        Converter Instructions
      </Heading>

      <OrderedList spacing={4} ml={4}>
        {/* SECTION 1 */}
        <ListItem>
          <Text
            as="button"
            display="inline-flex"
            alignItems="center"
            justifyContent="space-between"
            w="100%"
            textAlign="left"
            fontWeight="semibold"
            mb={1}
            fontSize="sm"
            onClick={() => toggleSection(1)}
            _hover={{ color: linkColor }}
          >
            <Box as="span">
              Section 1: Inventory Xpress Setup
            </Box>
            <Icon as={openSection === 1 ? ChevronUpIcon : ChevronDownIcon} ml={2} />
          </Text>

          {openSection === 1 && (
            <Box
              pl={6}
              py={3}
              mt={1}
              bg={sectionBg}
              borderRadius="md"
              borderLeft="4px solid"
              borderColor={borderCol}
              fontSize="sm"
            >
              <OrderedList spacing={2}>
                <ListItem>
                  Navigate to{" "}
                  <ChakraLink
                    href="https://online.inventoryxpress.net/"
                    target="_blank"
                    rel="noopener noreferrer"
                    color={linkColor}
                    textDecoration="underline"
                  >
                    Inventory Xpress
                  </ChakraLink>. (This will open in a new window/tab).
                </ListItem>
                <ListItem>Create your Inventory Xpress Order.</ListItem>
                <ListItem>
                  Once the order loads, you can click "Custom Settings" to adjust your
                  "On hand buffer" or "Days to be Closed" options if needed.
                </ListItem>
                <ListItem>
                  Make adjustments to your order by selecting different sections.
                  To order more or less of an item, change the number of cases in
                  the "Quantity To Order" column.
                </ListItem>
                <ListItem>
                  When you’re finished, click the "Export" button to export the order
                  in the correct format.
                </ListItem>
                <Box as="p" mt={2}>
                  The file will download and be named something like:{" "}
                  <em>'Reinhart_2410-48thSt_Order_2024-09-07'</em>.
                </Box>
              </OrderedList>
            </Box>
          )}
        </ListItem>

        {/* SECTION 2 */}
        <ListItem>
          <Text
            as="button"
            display="inline-flex"
            alignItems="center"
            justifyContent="space-between"
            w="100%"
            textAlign="left"
            fontWeight="semibold"
            mb={1}
            fontSize="sm"
            onClick={() => toggleSection(2)}
            _hover={{ color: linkColor }}
          >
            <Box as="span">
              Section 2: Prolifi Truck Converter
            </Box>
            <Icon as={openSection === 2 ? ChevronUpIcon : ChevronDownIcon} ml={2} />
          </Text>

          {openSection === 2 && (
            <Box
              pl={6}
              py={3}
              mt={1}
              bg={sectionBg}
              borderRadius="md"
              borderLeft="4px solid"
              borderColor={borderCol}
              fontSize="sm"
            >
              <OrderedList spacing={2}>
                <ListItem>Return to the prolifi.app Truck Converter.</ListItem>
                <ListItem>
                  Click "Browse" to open the file dialog, find the IX file you
                  just created, select it, and click "Open".
                </ListItem>
                <ListItem>
                  The "Convert" button should now be activated. Click it to convert
                  the file. The converted file will automatically download and
                  be named something like <em>'ProlifiTruck_2024-09-07_090600.csv'</em>.
                </ListItem>
              </OrderedList>
            </Box>
          )}
        </ListItem>

        {/* SECTION 3 */}
        <ListItem>
          <Text
            as="button"
            display="inline-flex"
            alignItems="center"
            justifyContent="space-between"
            w="100%"
            textAlign="left"
            fontWeight="semibold"
            mb={1}
            fontSize="sm"
            onClick={() => toggleSection(3)}
            _hover={{ color: linkColor }}
          >
            <Box as="span">
              Section 3: Upload to CustomerFirst
            </Box>
            <Icon as={openSection === 3 ? ChevronUpIcon : ChevronDownIcon} ml={2} />
          </Text>

          {openSection === 3 && (
            <Box
              pl={6}
              py={3}
              mt={1}
              bg={sectionBg}
              borderRadius="md"
              borderLeft="4px solid"
              borderColor={borderCol}
              fontSize="sm"
            >
              <OrderedList spacing={2}>
                <ListItem>
                  Navigate to{" "}
                  <ChakraLink
                    href="https://www.customerfirstsolutions.com/?bu=performance"
                    target="_blank"
                    rel="noopener noreferrer"
                    color={linkColor}
                    textDecoration="underline"
                  >
                    PFG Website
                  </ChakraLink>. (This will open in a new window/tab).
                </ListItem>
                <ListItem>
                  Log in and click "Orders" at the top of the screen. From the dropdown,
                  select "Import Order".
                </ListItem>
                <ListItem>
                  On the right side, you'll see "Upload file". Click the "Choose file"
                  button below that.
                </ListItem>
                <ListItem>
                  This opens the file upload dialog. Find the ProlifiTech converted file,
                  select it, and click "Open".
                </ListItem>
                <ListItem>
                  If there are any issues with adding items to your order during the
                  upload process, PFG will show an "Important failures" warning.
                  <br />
                  <strong>Note:</strong> If you encounter any issues, note the error
                  and inform your supervisor so the item can be updated.
                </ListItem>
                <ListItem>
                  An "Order Details" dialog will open. Here, set your delivery date
                  and give the order a name (e.g., "ProlifiTech Rocks!").
                </ListItem>
                <ListItem>Click "Create Order".</ListItem>
                <ListItem>
                  The order will process, and all your items should be added to your
                  "Order Review" page.
                </ListItem>
                <ListItem>
                  On the "Order Review" page, review the items and cases added. The "Did
                  you forget?" tool will show items not in your order but might be
                  needed.
                </ListItem>
                <ListItem>
                  Once you're satisfied, click "Submit Order" on the right side to
                  complete your truck order.
                </ListItem>
              </OrderedList>
            </Box>
          )}
        </ListItem>
      </OrderedList>
    </Box>
  );
};

export default ConverterInstructions;
