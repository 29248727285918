// src/components/VisionDental.js
import React, { useState } from 'react';
import axios from 'axios';
import MultiStoreModal from './MultiStoreModal';
import { saveAs } from 'file-saver';
import generateExcelFile from './excelVDGenerator';

// --- CHAKRA IMPORTS ---
import {
  Box,
  Button,
  Heading,
  Input,
  Spinner,
  Text,
  useDisclosure,
  Flex,
  FormControl,
  FormLabel,
  useColorModeValue,
} from '@chakra-ui/react';

function VisionDental() {
  const [file, setFile] = useState(null);
  const [processedData, setProcessedData] = useState(null);
  const [storeTotals, setStoreTotals] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [needsAssignment, setNeedsAssignment] = useState(null);
  const [allStores, setAllStores] = useState([]);
  const [assignments, setAssignments] = useState({});
  const [storeInfo, setStoreInfo] = useState({});
  const [secondaryData, setSecondaryData] = useState([]); 
  const [assignedStoreData, setAssignedStoreData] = useState([]);
  const [loading, setLoading] = useState(false);

  const containerBg = useColorModeValue('white', 'gray.800');
  const containerShadow = useColorModeValue('md', 'dark-lg');

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      console.error('No file selected');
      return;
    }

    setLoading(true);

    try {
      const formData = new FormData();
      formData.append('file', file);

      const baseUrl =
        window.location.hostname === '72.167.34.236'
          ? 'http://72.167.34.236:5000'
          : 'https://prolifi.app';

      const response = await axios.post(
        `${baseUrl}/api/vision-dental/upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        }
      );

      console.log('Response:', response.data);

      const { sortedData, storeInfo, needsAssignment, secondaryData } = response.data;

      // Utility function to remove duplicates in the retroactivity array
      const removeDuplicateRetroactivity = (retroactivityArray) => {
        const uniqueRetroactivity = [];
        const seen = new Set();

        retroactivityArray.forEach((retro) => {
          const uniqueKey = `${retro.identifier}-${retro.changeCode}-${retro.adjEff}-${retro.product}-${retro.contractID}-${retro.adjAmnt}`;
          if (!seen.has(uniqueKey)) {
            uniqueRetroactivity.push(retro);
            seen.add(uniqueKey);
          }
        });

        return uniqueRetroactivity;
      };

      // Function to process retroactivity for both sortedData and needsAssignment
      const processRetroactivity = (data) => {
        for (const entry of data) {
          if (entry.retroactivity && Array.isArray(entry.retroactivity)) {
            entry.retroactivity = removeDuplicateRetroactivity(entry.retroactivity);
          }
        }
      };

      // Function to process secondaryData and create or update entries in needsAssignment
      const processSecondaryData = (secondaryData, sortedData, needsAssignment) => {
        secondaryData.forEach((entry) => {
          // Check if entry is valid
          if (!entry.FN || !entry.LN || entry.adjAmnt === null) {
            return;
          }

          // Check if the entry already exists in sortedData
          let entryExistsInSortedData = false;
          for (const category in sortedData) {
            if (
              sortedData[category].some((item) => item.identifier === entry.identifier)
            ) {
              entryExistsInSortedData = true;
              break;
            }
          }

          if (entryExistsInSortedData) {
            return;
          }

          // Check if the entry already exists in needsAssignment
          let existingEntry = needsAssignment.find(
            (item) => item.identifier === entry.identifier
          );

          // If entry exists in needsAssignment, update it
          if (existingEntry) {
            // Check if the product (VISION/DENTAL) is already added, if not, add it
            const productExists = existingEntry.products.some(
              (product) => product.product === entry.product
            );
            if (!productExists) {
              existingEntry.products.push({
                product: entry.product || 'VISION',
                contractType: entry.prevCT || 'EMPLOYEE ONLY',
                totDue: 0.0,
                homeStore: 'NA',
              });
            }

            // Check if retroactivity already exists
            const retroactivityExists = existingEntry.retroactivity.some(
              (retro) =>
                retro.changeCode === entry.changeCode &&
                retro.contractID === entry.contractID &&
                retro.adjAmnt === entry.adjAmnt
            );
            if (!retroactivityExists) {
              existingEntry.retroactivity.push({
                changeCode: entry.changeCode || 'ADD NEW CONTRACT',
                contractID: entry.contractID || null,
                identifier: entry.identifier,
                FN: entry.FN,
                LN: entry.LN,
                product: entry.product || 'VISION',
                prevCT: entry.prevCT || 'EMPLOYEE ONLY',
                adjEff: entry.adjEff || null,
                adjEnd: entry.adjEnd || null,
                adjAmnt: entry.adjAmnt || 0,
              });
            }
          } else {
            // If entry doesn't exist in needsAssignment, create a new entry
            needsAssignment.push({
              identifier: entry.identifier,
              FN: entry.FN,
              LN: entry.LN,
              MI: entry.MI || null,
              Suff: entry.Suff || null,
              stores: entry.stores || [],
              products: [
                {
                  product: entry.product || 'VISION',
                  contractType: entry.prevCT || 'EMPLOYEE ONLY',
                  totDue: 0.0,
                  homeStore: 'NA',
                },
              ],
              retroactivity: [
                {
                  changeCode: entry.changeCode || 'ADD NEW CONTRACT',
                  contractID: entry.contractID || null,
                  identifier: entry.identifier,
                  FN: entry.FN,
                  LN: entry.LN,
                  product: entry.product || 'VISION',
                  prevCT: entry.prevCT || 'EMPLOYEE ONLY',
                  adjEff: entry.adjEff || null,
                  adjEnd: entry.adjEnd || null,
                  adjAmnt: entry.adjAmnt || 0,
                },
              ],
            });
          }
        });
      };

      // Process retroactivity for sortedData
      for (const store in sortedData) {
        sortedData[store].forEach((entry) => {
          processRetroactivity([entry]);
        });
      }

      // Process retroactivity for needsAssignment
      processRetroactivity(needsAssignment);

      // Process secondaryData
      processSecondaryData(secondaryData, sortedData, needsAssignment);

      // Set updated states
      setProcessedData(sortedData);
      setNeedsAssignment(needsAssignment);
      setStoreInfo(storeInfo);
      setSecondaryData(secondaryData);

      // Calculate all unique stores
      const stores = new Set();
      for (const category in sortedData) {
        for (const store in sortedData[category]) {
          stores.add(store);
        }
      }
      setAllStores(Array.from(stores));

      // If there are needs assignment entries, show the modal
      if (needsAssignment.length > 0) {
        setShowModal(true);
      } else {
        // If no assignment needed, generate the file right away
        handleDownload(sortedData, storeInfo, secondaryData, []);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setLoading(false);
    }
  };

  const calculateStoreTotals = (data, assignments, needsAssignment) => {
    const totals = {};

    // Helper function to find all matching entries in needsAssignment by identifier
    const findAssignmentEntries = (identifier) => {
      return needsAssignment.filter((entry) => entry.identifier === identifier);
    };

    // Process store data (main data)
    for (const store in data) {
      totals[store] = data[store].reduce((acc, entry) => {
        if (entry.products && Array.isArray(entry.products)) {
          entry.products.forEach((product) => {
            if (!acc[product.product]) {
              acc[product.product] = 0;
            }
            acc[product.product] += product.totDue;

            // Process retroactivity adjustments for each product
            if (entry.retroactivity && Array.isArray(entry.retroactivity)) {
              entry.retroactivity.forEach((retro) => {
                if (retro.product === product.product) {
                  acc[retro.product] += retro.adjAmnt;
                }
              });
            }
          });
        }
        return acc;
      }, {});
    }

    // Process retroactivity from needsAssignment
    for (const store in assignments) {
      if (!totals[store]) {
        totals[store] = {};
      }

      assignments[store].forEach((assignedEntry) => {
        const matchingEntries = findAssignmentEntries(assignedEntry.identifier);

        matchingEntries.forEach((needsAssignmentEntry) => {
          if (
            needsAssignmentEntry.retroactivity &&
            Array.isArray(needsAssignmentEntry.retroactivity)
          ) {
            needsAssignmentEntry.retroactivity.forEach((retro) => {
              if (!totals[store][retro.product]) {
                totals[store][retro.product] = 0;
              }
              totals[store][retro.product] += retro.adjAmnt;
            });
          }
        });
      });
    }

    return totals;
  };

  const mapStoreNumbersToNames = (data, storeInfo) => {
    const updatedData = {};
    for (const store in data) {
      const storeName = storeInfo[store] || 'Unknown Store';
      updatedData[storeName] = data[store];
    }
    return updatedData;
  };

  const handleAssign = (
    updatedSingleStoreData,
    updatedMultiStoreData,
    assignmentData,
    assignedStoreData
  ) => {
    // Calculate store totals with manual assignments
    const totals = calculateStoreTotals(
      updatedSingleStoreData,
      assignmentData,
      needsAssignment
    );

    setStoreTotals(totals);
    setProcessedData(updatedSingleStoreData);
    setShowModal(false);
    setAssignedStoreData(assignedStoreData);

    const updatedDataWithStoreNames = mapStoreNumbersToNames(
      updatedSingleStoreData,
      storeInfo
    );
    handleDownload(updatedDataWithStoreNames, storeInfo, secondaryData, assignedStoreData);
  };

  const handleDownload = async (
    updatedSingleStoreData,
    storeInfo,
    secondaryData,
    assignedStoreData
  ) => {
    if (!updatedSingleStoreData) {
      console.error('No store data available for download');
      return;
    }

    try {
      const buffer = await generateExcelFile(
        updatedSingleStoreData,
        storeInfo,
        secondaryData,
        assignedStoreData
      );

      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, 'store_totals.xlsx');
    } catch (error) {
      console.error('Error generating Excel file:', error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setAssignments({});
  };

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      w="100%"
      p={{ base: 4, md: 8 }}
      bg={useColorModeValue('gray.50', 'gray.900')}
      minH="100vh"
    >
      <Box
        w={{ base: 'full', md: 'md' }}
        bg={containerBg}
        p={{ base: 6, md: 8 }}
        borderRadius="lg"
        boxShadow={containerShadow}
      >

        <FormControl mb={4}>
          <FormLabel fontWeight="medium">Select a File</FormLabel>
          <Input
            type="file"
            onChange={handleFileChange}
            accept=".xlsx,.xls,.csv"
            isDisabled={loading}
          />
        </FormControl>

        <Button
          w="full"
          colorScheme="blue"
          onClick={handleUpload}
          isDisabled={loading}
          mb={2}
        >
          {loading ? <Spinner size="sm" mr={2} /> : 'Upload File'}
        </Button>

        {showModal && (
          <MultiStoreModal
            needsAssignment={needsAssignment}
            singleStoreData={processedData}
            onAssign={handleAssign}
            assignments={assignments}
            setAssignments={setAssignments}
            onClose={handleCloseModal}
          />
        )}
      </Box>
    </Flex>
  );
}

export default VisionDental;
