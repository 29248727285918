// tzPDFHelpers.js

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import QRCode from 'qrcode';

/**
 * Generate a PDF for incomplete employees, leveraging jsPDF, QRCode, and jsPDF-Autotable,
 * attempting to fit everything on a single (landscape) page.
 *
 * @param {Array} groupedData - The array of employee objects with their incomplete objectives
 * @param {string} [pdfFilename='Incomplete_Employees.pdf'] - Optional filename for the PDF
 */
export async function generateIncompletesPDF(groupedData, pdfFilename = 'Incomplete_Employees.pdf') {
  try {
    // 1) Generate a QR code
    const qrDataUrl = await QRCode.toDataURL(
      'https://inspirebrands.csod.com/client/inspirebrands/sonic.aspx'
    );

    // 2) Create a jsPDF doc in landscape format
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'pt',
      format: 'letter',
    });

    let y = 5; // minimal top space

    // Calculate page and image dimensions for centering the QR code
    const pageWidth = doc.internal.pageSize.getWidth();
    const imageWidth = 80;
    const qrX = (pageWidth - imageWidth) / 2; // center horizontally
    const qrHeight = 80; // same as width if square

    // 3) Add the QR code
    doc.addImage(qrDataUrl, 'PNG', qrX, y, imageWidth, qrHeight);

    // Stylized text on both sides of the QR code
    doc.setFont('helvetica', 'bolditalic');
    doc.setFontSize(14);
    doc.setTextColor(220, 20, 60); // bright color

    const textMid = y + 30;
    const leftTextX = qrX - 75;
    const rightTextX = qrX + imageWidth + 20;

    doc.text('Scan me', leftTextX, textMid);
    doc.text('to go to', leftTextX + 9, textMid + 15);
    doc.text('Totzone!', leftTextX, textMid + 30);

    doc.text('Scan me', rightTextX, textMid);
    doc.text('to go to', rightTextX, textMid + 15);
    doc.text('Totzone!', rightTextX, textMid + 30);

    // Move down below the QR code
    y += qrHeight + 10;

    // 4) Disclaimers in italic, smaller font
    doc.setFont('helvetica', 'italic');
    doc.setFontSize(8);
    doc.setTextColor(100); // muted gray
    const textLeftMargin = 20; // reduce left margin to help fit table

    doc.text(
      '-These employees still need to complete their monthly objectives on Totzone!',
      textLeftMargin,
      y
    );
    y += 10;

    doc.text(
      '-This is a requirement that we complete these every month, but if you do, you also get the chance to win Gift Cards for places like Amazon, Nike, etc.',
      textLeftMargin,
      y
    );
    y += 10;

    // Revert to normal style for the table
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(10);
    doc.setTextColor(0);

    // 5) Build your table data
    let maxObjectives = 0;
    groupedData.forEach((emp) => {
      if (emp.objectives.length > maxObjectives) {
        maxObjectives = emp.objectives.length;
      }
    });
    const headerRow = ['Employee Name', 'Employee Username'];
    for (let i = 1; i <= maxObjectives; i++) {
      headerRow.push(`Objective ${i}`);
    }
    const bodyRows = groupedData.map((emp) => {
      const row = [emp.name, emp.username || ''];
      emp.objectives.forEach((obj) => row.push(obj));
      const diff = maxObjectives - emp.objectives.length;
      for (let i = 0; i < diff; i++) {
        row.push(''); // pad empty cells
      }
      return row;
    });

    // 6) Use autoTable with minimal margins, no page breaks, wrapped content, etc.
    autoTable(doc, {
      startY: y,
      margin: { top: 0, right: 20, bottom: 0, left: 20 },
      pageBreak: 'avoid',        // avoid splitting the table
      tableWidth: 'wrap',        // shrink columns to their content if possible
      styles: {
        fontSize: 9,             // smaller text to help fit
        cellPadding: 3,
        overflow: 'linebreak',   // wrap text in a cell
      },
      headStyles: {
        fillColor: [41, 128, 185],
        textColor: 255,
        halign: 'center',
      },
      head: [headerRow],
      body: bodyRows,
    });

    // 7) Save file
    doc.save(pdfFilename);
  } catch (error) {
    console.error('Error generating PDF:', error);
    throw error; // Let caller handle it
  }
}
