// MonthlyManual.js
import React, { useState, useEffect } from "react";
import axios from "axios";

// Chakra UI
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  Text,
  Collapse,
  useColorModeValue,
  UnorderedList,
  ListItem,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";

import { format, startOfISOWeek, endOfISOWeek, getISOWeek, subDays, getMonth, getYear } from "date-fns";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStore, faDownload, faPaperPlane } from "@fortawesome/free-solid-svg-icons";

import ConfirmationModal from "./ConfirmationModal";
import DownloadExcel from "./DownloadExcel";

/** A helper for retrieving the "MM/YYYY" based on your logic. */
function getCurrentMonthYear() {
  const today = new Date();
  let month = today.getMonth(); // 0-based
  let year = today.getFullYear();

  // Get the last day of the current month
  const lastDayOfMonth = new Date(year, month + 1, 0).getDate();

  // Check if today is within the last 4 days of the current month
  if (lastDayOfMonth - today.getDate() >= 4) {
    // If not within the last 4 days, return the previous month
    month = month === 0 ? 11 : month - 1;
    year = month === 11 ? year - 1 : year;
  }

  return `${month + 1}/${year}`;
}

function MonthlyManual() {
  // STATE
  const [formData, setFormData] = useState({
    storeSelection: "-1",
    combinedFoodAndPaperCost: "",
    idealFoodAndPaper: "",
    totalComplaints: "",
    productComplaints: "",
    foodSafety: "",
    hoursEndingMonth: "",
  });

  const [supervisedStores, setSupervisedStores] = useState([]);
  const [selectedMonthYear, setSelectedMonthYear] = useState(getCurrentMonthYear());
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [onModalConfirm, setOnModalConfirm] = useState(() => () => {});

  const [hoursEndingPlaceholder, setHoursEndingPlaceholder] = useState("");
  const [mtdPlaceholder, setMtdPlaceholder] = useState("");
  const [formattedMonthYear, setFormattedMonthYear] = useState("");
  const [downloadData, setDownloadData] = useState(null);
  const [triggerDownload, setTriggerDownload] = useState(false);

  const [kpiGoals, setKPIGoals] = useState([]);
  const [isDownloadEnabled, setIsDownloadEnabled] = useState(false);
  const [missingStores, setMissingStores] = useState([]);

  // THEME
  const boxBg = useColorModeValue("white", "gray.700");
  const borderCol = useColorModeValue("gray.200", "gray.600");

  /** Base URL depending on environment. */
  const getBaseUrl = () => {
    const hostname = window.location.hostname;
    if (hostname === "72.167.34.236") {
      return "http://72.167.34.236:5000";
    } else if (/^(www\.)?prolifi\.app$/.test(hostname)) {
      return "https://prolifi.app";
    } else {
      return "http://localhost:5000";
    }
  };

  // LIFECYCLE
  useEffect(() => {
    fetchSupervisedStores();
    calculateDates();
    determineHoursEndingPlaceholder();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const baseUrl = getBaseUrl();
    fetchKPIGoals(baseUrl).then(setKPIGoals);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (formData.storeSelection !== "-1") {
      // reset form partial
      setFormData((prev) => ({
        ...prev,
        combinedFoodAndPaperCost: "",
        idealFoodAndPaper: "",
        totalComplaints: "",
        productComplaints: "",
        foodSafety: "",
        hoursEndingMonth: "",
      }));
      fetchStoreData(formData.storeSelection, selectedMonthYear);
    }
    // eslint-disable-next-line
  }, [formData.storeSelection, selectedMonthYear]);

  useEffect(() => {
    let timer;
    if (downloadData) {
      timer = setTimeout(() => {
        setDownloadData(null);
      }, 500);
    }
    return () => clearTimeout(timer);
  }, [downloadData]);

  useEffect(() => {
    if (supervisedStores.length > 0) {
      checkStoreEntries();
    }
    // eslint-disable-next-line
  }, [supervisedStores, formData, selectedMonthYear]);

  // HELPER: Fetch KPI Goals
  async function fetchKPIGoals(baseUrl) {
    try {
      const response = await axios.get(`${baseUrl}/api/monthly/kpi-grading-standards`, {
        withCredentials: true,
      });
      console.log("KPI Goals:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching KPI goals:", error);
      return [];
    }
  }

  // HELPER: fetch supervised stores
  const fetchSupervisedStores = async () => {
    const baseUrl = getBaseUrl();
    try {
      const response = await axios.get(`${baseUrl}/api/supervised-stores`, { withCredentials: true });
      setSupervisedStores(response.data);
    } catch (error) {
      console.error("Error fetching supervised stores:", error);
    }
  };

  // HELPER: Check if all store entries exist
  const checkStoreEntries = async () => {
    const baseUrl = getBaseUrl();
    const missingEntries = [];

    try {
      const [month, year] = selectedMonthYear.split("/");
      for (const storeId of supervisedStores) {
        const response = await axios.get(`${baseUrl}/api/monthly/store-data/${storeId}/${month}/${year}`, {
          withCredentials: true,
        });
        if (!response.data || response.data.length === 0) {
          missingEntries.push(storeId);
        }
      }
      if (missingEntries.length === 0) {
        setIsDownloadEnabled(true);
      } else {
        setIsDownloadEnabled(false);
      }
      setMissingStores(missingEntries);
    } catch (error) {
      console.error("Error checking store entries:", error);
      setIsDownloadEnabled(false);
      setMissingStores(supervisedStores);
    }
  };

  // HELPER: fetch store data for a single store
  const fetchStoreData = async (storeId, monthYear) => {
    const baseUrl = getBaseUrl();
    try {
      const [month, year] = monthYear.split("/");
      const response = await axios.get(`${baseUrl}/api/monthly/store-data/${storeId}/${month}/${year}`, {
        withCredentials: true,
      });
      if (response.data && response.data.length > 0) {
        const submissionData = response.data[0];
        const isSplitWeek = submissionData.hours_ending_month != null && submissionData.hours_beginning_month != null;
        setFormData((prev) => ({
          ...prev,
          combinedFoodAndPaperCost: submissionData.actualFnP || "",
          idealFoodAndPaper: submissionData.idealFoodAndPaper || "",
          totalComplaints: submissionData.totalComplaints || "",
          productComplaints: submissionData.productComplaints || "",
          foodSafety: submissionData.foodSafety || "",
          hoursEndingMonth: submissionData.hoursMonthEnd || "",
        }));
      } else {
        // Clear form if no data
        setFormData((prev) => ({
          ...prev,
          combinedFoodAndPaperCost: "",
          idealFoodAndPaper: "",
          totalComplaints: "",
          productComplaints: "",
          foodSafety: "",
          hoursEndingMonth: "",
        }));
      }
    } catch (error) {
      console.error("Error fetching store data:", error);
    }
  };

  // HELPER: fetch all data for Excel
  const fetchAllStoresData = async () => {
    const baseUrl = getBaseUrl();
    const storeDataList = [];
    let kpiGradingStandards = [];
    try {
      const [month, year] = selectedMonthYear.split("/");

      // Fetch KPI Grading
      const kpiResponse = await axios.get(`${baseUrl}/api/monthly/kpi-grading-standards`, {
        withCredentials: true,
      });
      if (kpiResponse.data) {
        kpiGradingStandards = kpiResponse.data;
      }

      // Iterate over all supervised stores
      for (const storeId of supervisedStores) {
        const response = await axios.get(`${baseUrl}/api/monthly/store-data/${storeId}/${month}/${year}`, {
          withCredentials: true,
        });
        if (response.data && response.data.length > 0) {
          response.data.forEach((item) => {
            item.storeSelection = parseInt(item.storeSelection, 10);
            item.actualFnP = parseFloat(item.actualFnP);
            item.idealFoodAndPaper = parseFloat(item.idealFoodAndPaper);
            item.totalComplaints = parseInt(item.totalComplaints, 10);
            item.productComplaints = parseInt(item.productComplaints, 10);
            item.mysteryShop = item.mysteryShop != null ? parseFloat(item.mysteryShop) : null;
            item.foodSafety = parseFloat(item.foodSafety);
            item.hoursMonthEnd = parseFloat(item.hoursMonthEnd);
            item.submissionMonth = parseInt(item.submissionMonth, 10);
            item.submissionYear = parseInt(item.submissionYear, 10);
            item.totalTickets = parseInt(item.totalTickets, 10);
            item.totalSales = parseFloat(item.totalSales);
            item.weightedReplyTime = parseFloat(item.weightedReplyTime);
            item.weightedAveTime = parseFloat(item.weightedAveTime);
            item.onTimePercentage = parseFloat(item.onTimePercentage);
            item.totalHours = parseFloat(item.totalHours);
            storeDataList.push(item);
          });
        }
      }
      return { storeDataList, kpiGradingStandards };
    } catch (error) {
      console.error("Error fetching data for all stores:", error);
      return { storeDataList: [], kpiGradingStandards: [] };
    }
  };

  // DATES & PLACEHOLDERS
  const determineHoursEndingPlaceholder = () => {
    const today = new Date();
    const dayOfMonth = today.getDate();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    const isEndOfMonth = lastDayOfMonth - dayOfMonth < 4;
    let relevantMonth = currentMonth;
    let relevantYear = currentYear;

    if (!isEndOfMonth) {
      relevantMonth = currentMonth === 0 ? 11 : currentMonth - 1;
      relevantYear = currentMonth === 0 ? currentYear - 1 : currentYear;
    }

    const lastDayOfRelevantMonth = new Date(relevantYear, relevantMonth + 1, 0);
    let lastMonday = new Date(lastDayOfRelevantMonth);
    while (lastMonday.getDay() !== 1) {
      lastMonday.setDate(lastMonday.getDate() - 1);
    }
    const options = { month: "short", day: "numeric", year: "numeric" };
    const start = lastMonday.toLocaleDateString(undefined, options);
    const end = lastDayOfRelevantMonth.toLocaleDateString(undefined, options);
    setHoursEndingPlaceholder(`${start} - ${end}`);
  };

  const calculateDates = () => {
    const today = new Date();
    const dayOfMonth = today.getDate();
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
    if (lastDayOfMonth - dayOfMonth < 4) {
      const currentMonthYear = today.toLocaleString("default", { month: "long", year: "numeric" });
      setFormattedMonthYear(currentMonthYear);
      setMtdPlaceholder(`MTD: ${currentMonthYear}`);
    } else {
      const previousMonthDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      const previousMonthYear = previousMonthDate.toLocaleString("default", { month: "long", year: "numeric" });
      setFormattedMonthYear(previousMonthYear);
      setMtdPlaceholder(`MTD: ${previousMonthYear}`);
    }
  };

  // HANDLERS
  const handleChangeFn = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDownloadClick = async () => {
    await fetchAllStoresData();
    setTriggerDownload(true);
    setTimeout(() => setTriggerDownload(false), 500);
  };

  const resetDownload = () => {
    // In case you want to reset flags after download
  };

  const handleDownloadBaseFile = async () => {
    const baseUrl = getBaseUrl();
    try {
      const { storeDataList, kpiGradingStandards } = await fetchAllStoresData();
      console.log("All Stores Data: ", storeDataList);
      console.log("KPI Grading Standards: ", kpiGradingStandards);

      const requestData = {
        allStoresData: storeDataList,
        kpiGradingStandards,
      };

      const response = await axios.post(`${baseUrl}/api/monthly/download-base-file`, requestData, {
        responseType: "blob",
        withCredentials: true,
      });

      const today = new Date();
      const formattedDate = today
        .toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        })
        .replace(/\//g, "-");
      const fileName = `PL-Review ${formattedDate}.xlsx`;

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading the base file:", error);
      alert("Failed to download the base file");
    }
  };

  // Modal
  const handleModalClose = () => {
    setShowConfirmationModal(false);
  };
  const confirmSubmission = () => {
    setShowConfirmationModal(false);
    onModalConfirm();
  };

  // SUBMISSION
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.storeSelection === "-1") {
      alert("Please select a store");
      return;
    }
    // Show a pre-submission confirmation
    showConfirmationBeforeSubmit();
  };

  // Determine date for submission (similar logic from your code)
  const calculateSubmissionDate = () => {
    const today = new Date();
    const currentDay = today.getDate();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    const daysInCurrentMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    if (daysInCurrentMonth - currentDay < 4) {
      return { submissionMonth: currentMonth + 1, submissionYear: currentYear };
    } else {
      const previousMonth = currentMonth === 0 ? 12 : currentMonth;
      const submissionYear = currentMonth === 0 ? currentYear - 1 : currentYear;
      return { submissionMonth: previousMonth, submissionYear };
    }
  };

  // This function actually sends data to the server
  const submitData = async () => {
    setShowConfirmationModal(false);

    const { submissionMonth, submissionYear } = calculateSubmissionDate();
    console.log(`Submitting for month: ${submissionMonth}, year: ${submissionYear}`);

    const updatedFormData = {
      ...formData,
      submissionMonth,
      submissionYear,
      mysteryShop: null,
    };

    const baseUrl = getBaseUrl();
    const submitUrl = `${baseUrl}/api/monthly/monthly-manual-data-entry`;

    try {
      const response = await axios.post(submitUrl, updatedFormData, { withCredentials: true });
      alert("Data submitted successfully");
      console.log("Response from server:", response.data);

      setDownloadData(updatedFormData);
      await checkStoreEntries();

      // Reset form
      setFormData({
        storeSelection: "-1",
        combinedFoodAndPaperCost: "",
        idealFoodAndPaper: "",
        totalComplaints: "",
        productComplaints: "",
        foodSafety: "",
        hoursEndingMonth: "",
      });
    } catch (error) {
      if (error.response?.status === 409 && error.response.data.message === "Duplicate entry") {
        const id = error.response.data.id;
        handleDuplicateEntry(id);
      } else {
        console.error("Error submitting data:", error);
        alert("Error submitting data");
      }
    }
  };

  // If there's a duplicate entry, show a second modal
  const handleDuplicateEntry = (id) => {
    setModalTitle("Duplicate Entry Detected");
    setModalContent(
      `There is already a database entry for store ${formData.storeSelection}. Do you want to update the entry or cancel submission?`
    );
    setOnModalConfirm(() => () => updateData(id));
    setShowConfirmationModal(true);
  };

  // Actually updates an existing entry
  const updateData = async (id) => {
    console.log("Updating data...");
    const { submissionMonth, submissionYear } = calculateSubmissionDate();
    const updatedFormData = {
      ...formData,
      submissionMonth,
      submissionYear,
      mysteryShop: null,
    };

    const baseUrl = getBaseUrl();
    const updateUrl = `${baseUrl}/api/monthly/monthly-manual-data-entry/${id}`;

    try {
      const response = await axios.put(updateUrl, updatedFormData, { withCredentials: true });
      alert("Entry updated successfully");
      console.log("Response from server:", response.data);

      setDownloadData(updatedFormData);
      await checkStoreEntries();
      setShowConfirmationModal(false);
    } catch (error) {
      console.error("Error updating form:", error);
      alert("Error updating data");
    }
  };

  // Show a pre-submission confirmation
  const showConfirmationBeforeSubmit = () => {
    const { submissionMonth, submissionYear } = calculateSubmissionDate();
    const submissionMonthName = new Date(submissionYear, submissionMonth - 1).toLocaleString("default", {
      month: "long",
    });
    setModalTitle(`Confirm Submission`);
    setModalContent(`Submitting data for ${submissionMonthName} ${submissionYear}. Do you want to continue?`);
    setOnModalConfirm(() => submitData);
    setShowConfirmationModal(true);
  };

  return (
    <Box maxW="800px" mx="auto" mt={4} p={4} borderWidth="1px" borderColor={borderCol} borderRadius="md" bg={boxBg}>
      <Heading size="md" mb={4}>
        Monthly Manual Data Entry ({formattedMonthYear})
      </Heading>

      <Box as="form" onSubmit={handleSubmit}>
        {/* Store Selection */}
        <FormControl mb={4}>
          <FormLabel fontSize="sm" fontWeight="semibold">
            <FontAwesomeIcon icon={faStore} style={{ marginRight: "6px" }} />
            Select Store
          </FormLabel>
          <Select
            name="storeSelection"
            value={formData.storeSelection}
            onChange={handleChangeFn}
            placeholder="Please select a store"
          >
            {supervisedStores.map((store, index) => (
              <option key={index} value={store}>
                {store}
              </option>
            ))}
          </Select>
        </FormControl>

        {formData.storeSelection !== "-1" && (
          <>
            {/* Inventory Data */}
            <Heading size="sm" mb={2}>
              Inventory Data
            </Heading>
            <FormControl mb={3}>
              <FormLabel fontSize="sm">Actual Food &amp; Paper Cost</FormLabel>
              <Input
                type="number"
                name="combinedFoodAndPaperCost"
                placeholder={mtdPlaceholder}
                onChange={handleChangeFn}
                value={formData.combinedFoodAndPaperCost}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel fontSize="sm">Ideal Food &amp; Paper</FormLabel>
              <Input
                type="number"
                name="idealFoodAndPaper"
                placeholder={mtdPlaceholder}
                onChange={handleChangeFn}
                value={formData.idealFoodAndPaper}
              />
            </FormControl>

            {/* Complaints */}
            <Heading size="sm" mb={2}>
              Customer Complaints
            </Heading>
            <FormControl mb={3}>
              <FormLabel fontSize="sm">Total Complaints</FormLabel>
              <Input
                type="number"
                name="totalComplaints"
                placeholder={mtdPlaceholder}
                onChange={handleChangeFn}
                value={formData.totalComplaints}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel fontSize="sm">Product Complaints</FormLabel>
              <Input
                type="number"
                name="productComplaints"
                placeholder={mtdPlaceholder}
                onChange={handleChangeFn}
                value={formData.productComplaints}
              />
            </FormControl>

            {/* Additional Data */}
            <Heading size="sm" mb={2}>
              Additional Data
            </Heading>
            <FormControl mb={3}>
              <FormLabel fontSize="sm">Food Safety</FormLabel>
              <Input
                type="number"
                name="foodSafety"
                onChange={handleChangeFn}
                value={formData.foodSafety}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel fontSize="sm">Hours Ending Month</FormLabel>
              <Input
                type="number"
                name="hoursEndingMonth"
                placeholder={hoursEndingPlaceholder}
                onChange={handleChangeFn}
                value={formData.hoursEndingMonth}
              />
            </FormControl>

            <Box textAlign="right" mt={4}>
              <Button colorScheme="blue" type="submit" leftIcon={<FontAwesomeIcon icon={faPaperPlane} />}>
                Submit
              </Button>
            </Box>
          </>
        )}
      </Box>

      {/* Download button */}
      <Button
        mt={6}
        leftIcon={<FontAwesomeIcon icon={faDownload} />}
        colorScheme="teal"
        onClick={handleDownloadBaseFile}
        isDisabled={!isDownloadEnabled}
      >
        {isDownloadEnabled ? "Download P&L Review Document" : "Download Excel"}
      </Button>

      {/* Missing stores alert */}
      {!isDownloadEnabled && missingStores.length > 0 && (
        <Alert status="warning" mt={4}>
          <AlertIcon />
          <Box>
            <Text>The following stores still need data submitted for {selectedMonthYear}:</Text>
            <UnorderedList mt={2}>
              {missingStores.map((store, index) => (
                <ListItem key={index}>{store}</ListItem>
              ))}
            </UnorderedList>
          </Box>
        </Alert>
      )}

      {/* Confirmation Modal */}
      <ConfirmationModal
        open={showConfirmationModal}
        onClose={handleModalClose}
        onConfirm={confirmSubmission}
        title={modalTitle}
      >
        {modalContent}
      </ConfirmationModal>

      {/* Hidden Excel Download (if needed) */}
      <DownloadExcel data={[]} kpiGoals={kpiGoals} triggerDownload={triggerDownload} />
    </Box>
  );
}

export default MonthlyManual;
