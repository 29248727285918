// src/components/PdfReview.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Flex,
  Heading,
  Select,
  FormControl,
  FormLabel,
  useColorModeValue,
} from "@chakra-ui/react";

function PdfReview() {
  const [pdfFiles, setPdfFiles] = useState([]);
  const [pdfUrl, setPdfUrl] = useState("");

  // Month/Year
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  // Single store vs. multiple
  const [userStore, setUserStore] = useState(null);
  const [userGroup, setUserGroup] = useState("");
  const [availableStores, setAvailableStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState("");

  // Chakra UI color mode styling
  const bgBoxColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  // Decide base URL
  const getBaseUrl = () => {
    return process.env.NODE_ENV === "development"
      ? "http://72.167.34.236:5000"
      : "https://prolifi.app";
  };

  // Fetch user-store info on mount
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const baseUrl = getBaseUrl();
        const { data } = await axios.get(`${baseUrl}/api/user-store`, {
          withCredentials: true,
        });
        // data => { userStore, userGroup, company, username }
        setUserStore(data.userStore);
        setUserGroup(data.userGroup);

        // If user is 'supervisor' or 'director', fetch all of their stores
        if (data.userGroup === "supervisor") {
          const storeRes = await axios.get(`${baseUrl}/api/supervised-stores`, {
            withCredentials: true,
          });
          setAvailableStores(storeRes.data || []);
        } else if (data.userGroup === "director") {
          const storeRes = await axios.get(`${baseUrl}/api/director-stores`, {
            withCredentials: true,
          });
          setAvailableStores(storeRes.data || []);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchUserData();
  }, []);

  /**
   * Fetch PDF list whenever selected month, year, or store changes.
   * Then filter so only "01" or "16" day-of-month is included in filenames.
   */
  useEffect(() => {
    const fetchPdfList = async () => {
      try {
        const baseUrl = getBaseUrl();
        const storeToUse =
          userGroup === "director" || userGroup === "supervisor"
            ? selectedStore
            : userStore;

        if (!storeToUse) return;

        const response = await axios.get(`${baseUrl}/api/pdf-list`, {
          params: {
            month: selectedMonth,
            year: selectedYear,
            store: storeToUse,
          },
        });
        let allFiles = response.data || [];

        // Filter them so only day == "01" or "16", based on the date in the filename
        // e.g. "1028 12-01-24.pdf" => day is "01"
        // The second part after splitting by space might be "12-01-24.pdf"
        let filteredFiles = allFiles.filter((filename) => {
          const parts = filename.split(" "); 
          if (!parts[1]) return false; // if something is off
          const dateWithExt = parts[1]; // e.g. "12-01-24.pdf"
          const datePart = dateWithExt.replace(".pdf", ""); // => "12-01-24"
          const datePieces = datePart.split("-"); // => ["12", "01", "24"]
          if (datePieces.length < 3) return false;

          const day = datePieces[1]; // "01" or "16" or etc.
          return day === "01" || day === "16";
        });

        setPdfFiles(filteredFiles);
      } catch (error) {
        console.error("Error fetching PDF list:", error);
      }
    };

    // Only fetch if year & month are selected
    if (selectedMonth && selectedYear) {
      if (userGroup === "director" || userGroup === "supervisor") {
        if (selectedStore) {
          fetchPdfList();
        } else {
          setPdfFiles([]);
        }
      } else {
        // Normal user
        if (userStore) {
          fetchPdfList();
        } else {
          setPdfFiles([]);
        }
      }
    } else {
      setPdfFiles([]);
    }
  }, [selectedMonth, selectedYear, userStore, userGroup, selectedStore]);

  /**
   * Auto-load a single PDF if pdfFiles has exactly one,
   * or reset if multiple or none.
   */
  useEffect(() => {
    if (pdfFiles.length === 1) {
      const baseUrl = getBaseUrl();
      const encodedFileName = encodeURIComponent(pdfFiles[0]);
      setPdfUrl(`${baseUrl}/api/view-pdf/${encodedFileName}`);
    } else {
      // Reset if multiple or none
      setPdfUrl("");
    }
  }, [pdfFiles]);

  // Handlers
  const handleMonthSelect = (e) => {
    setSelectedMonth(e.target.value);
    setPdfUrl("");
  };

  const handleYearSelect = (e) => {
    setSelectedYear(e.target.value);
    setPdfUrl("");
  };

  const handlePdfSelect = (e) => {
    const fileName = e.target.value;
    if (fileName) {
      const baseUrl = getBaseUrl();
      const encodedFileName = encodeURIComponent(fileName);
      setPdfUrl(`${baseUrl}/api/view-pdf/${encodedFileName}`);
    }
  };

  const handleStoreSelect = (e) => {
    setSelectedStore(e.target.value);
    setPdfUrl("");
  };

  // Last 4 years array
  const years = Array.from({ length: 4 }, (_, i) => new Date().getFullYear() - i);

  return (
    <Flex direction="column" w="100%" h="99vh">
      {/* Top: heading + form container */}
      <Box p={4}>
        <Heading as="h2" size="md" mb={2}>
          PDF Review
        </Heading>

        <Box
          w="100%"
          borderWidth="1px"
          borderColor={borderColor}
          borderRadius="md"
          bg={bgBoxColor}
          shadow="md"
          p={3}
        >
          <Flex
            direction={{ base: "column", md: "row" }}
            gap={3}
            align="flex-end"
          >
            {/* Year Select */}
            <FormControl minW={{ base: "auto", md: "120px" }}>
              <FormLabel
                fontSize="sm"
                fontWeight="semibold"
                htmlFor="yearSelect"
                mb={1}
              >
                Year
              </FormLabel>
              <Select
                id="yearSelect"
                placeholder="Select a Year"
                value={selectedYear}
                onChange={handleYearSelect}
                focusBorderColor="blue.500"
                size="sm"
                lineHeight="1.3"
                py={1}
              >
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Select>
            </FormControl>

            {/* Month Select */}
            <FormControl minW={{ base: "auto", md: "150px" }}>
              <FormLabel
                fontSize="sm"
                fontWeight="semibold"
                htmlFor="monthSelect"
                mb={1}
              >
                Month
              </FormLabel>
              <Select
                id="monthSelect"
                placeholder="Select a Month"
                value={selectedMonth}
                onChange={handleMonthSelect}
                focusBorderColor="blue.500"
                size="sm"
                lineHeight="1.3"
                py={1}
              >
                {Array.from({ length: 12 }, (_, i) => (
                  <option key={i} value={i + 1}>
                    {new Date(0, i).toLocaleString("default", { month: "long" })}
                  </option>
                ))}
              </Select>
            </FormControl>

            {/* Store Select for Director/Supervisor */}
            {(userGroup === "director" || userGroup === "supervisor") && (
              <FormControl minW={{ base: "auto", md: "150px" }}>
                <FormLabel
                  fontSize="sm"
                  fontWeight="semibold"
                  htmlFor="storeSelect"
                  mb={1}
                >
                  Store
                </FormLabel>
                <Select
                  id="storeSelect"
                  placeholder="Select a Store"
                  value={selectedStore}
                  onChange={handleStoreSelect}
                  focusBorderColor="blue.500"
                  size="sm"
                  lineHeight="1.3"
                  py={1}
                >
                  {availableStores.map((store) =>
                    typeof store === "number" ? (
                      <option key={store} value={store}>
                        Store {store}
                      </option>
                    ) : (
                      // if returns objects { store_id, store_name }
                      <option key={store.store_id} value={store.store_id}>
                        {store.store_name} ({store.store_id})
                      </option>
                    )
                  )}
                </Select>
              </FormControl>
            )}

            {/* PDF Files Select (only if year, month, and store are set) */}
            {( (userGroup === "director" || userGroup === "supervisor") 
                ? (selectedYear && selectedMonth && selectedStore)
                : (selectedYear && selectedMonth && userStore)
              ) && (
              <FormControl minW={{ base: "auto", md: "200px" }}>
                <FormLabel
                  fontSize="sm"
                  fontWeight="semibold"
                  htmlFor="pdfSelect"
                  mb={1}
                >
                  PDF File
                </FormLabel>
                <Select
                  id="pdfSelect"
                  placeholder="Select a PDF"
                  onChange={handlePdfSelect}
                  focusBorderColor="blue.500"
                  size="sm"
                  lineHeight="1.3"
                  py={1}
                >
                  {pdfFiles.map((file, index) => (
                    <option key={index} value={file}>
                      {file}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}
          </Flex>
        </Box>
      </Box>

      {/* PDF Preview area */}
      {pdfUrl && (
        <Box
          flex=".99"
          borderWidth="1px"
          borderColor={borderColor}
          borderRadius="md"
          overflow="hidden"
          mx={4}
          mb={4}
        >
          <iframe
            src={pdfUrl}
            title="PDF Preview"
            width="100%"
            height="98%"
            style={{ border: "none" }}
          />
        </Box>
      )}
    </Flex>
  );
}

export default PdfReview;
