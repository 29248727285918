// src/components/MonthlyProcessing.js
import React, { useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./ReactDatePickerOverrides.css"; // <-- Your custom overrides (explained below)
import { format } from "date-fns";

import {
  Box,
  Flex,
  Button,
  Heading,
  Text,
  FormControl,
  FormLabel,
  useColorModeValue,
} from "@chakra-ui/react";

/**
 * A custom input for react-datepicker, using a Chakra <Button>.
 * The 'value' passed in is typically "MM/yyyy" from react-datepicker;
 * we format it => "Month yyyy" for better display.
 */
function CustomInput({ value, onClick }) {
  const formatDateDisplay = (value) => {
    const dateParts = value.split("/");
    if (dateParts.length === 2) {
      const [month, year] = dateParts;
      const date = new Date(year, month - 1);
      return format(date, "MMMM yyyy");
    }
    return value;
  };

  return (
    <Button variant="outline" colorScheme="teal" size="md" onClick={onClick}>
      {formatDateDisplay(value)}
    </Button>
  );
}

function MonthlyProcessing() {
  const [selectedDate, setSelectedDate] = useState(new Date());

  /**
   * Build baseUrl depending on domain
   */
  const getBaseUrl = () => {
    const { hostname } = window.location;
    if (hostname === "72.167.34.236") {
      return "http://72.167.34.236:5000";
    }
    return "https://prolifi.app";
  };

  /**
   * Main monthly processing => call API => possibly download a file
   */
  const handleMonthlyProcessing = async () => {
    const baseUrl = getBaseUrl();
    // Because JS months are 0-indexed, we add 1 for the actual month number
    const month = selectedDate.getMonth() + 1;
    const year = selectedDate.getFullYear();

    const monthlyProcessingUrl = `${baseUrl}/api/monthly/monthly-processing`;

    try {
      // Post to the monthly processing endpoint
      const response = await axios.post(
        monthlyProcessingUrl,
        { month, year },
        { withCredentials: true }
      );

      if (response.data.success && response.data.excelFilename) {
        const downloadUrl = `${baseUrl}/api/monthly/download/${response.data.excelFilename}`;
        // Trigger the browser to download the file
        window.location.href = downloadUrl;
      } else {
        alert("Monthly processing completed, but no file was generated.");
      }
    } catch (error) {
      console.error("Error during Monthly Processing:", error);
      alert("Error during Monthly Processing. Check the console for details.");
    }
  };

  // We optionally use Chakra theming to style our page background, container, etc.
  const pageBg = useColorModeValue("gray.50", "gray.800");
  const boxBg = useColorModeValue("white", "gray.700");

  return (
    <Box minH="100vh" bg={pageBg} py={10} px={4}>
      <Box
        maxW="500px"
        mx="auto"
        p={6}
        borderWidth="1px"
        borderRadius="md"
        bg={boxBg}
        shadow="md"
      >
        <Heading as="h1" size="md" mb={4}>
          Monthly Processing
        </Heading>

        <Text fontSize="sm" color="gray.500" mb={6}>
          Select a month and year, then click "Run Monthly Processing."
          If successful, an Excel file will be downloaded automatically.
        </Text>

        <FormControl mb={4}>
          <FormLabel fontWeight="semibold">Select Month and Year</FormLabel>
          <Flex align="center" gap={2}>
            <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              showFullMonthYearPicker
              customInput={<CustomInput />}
              popperClassName="react-datepicker-popper"    // For custom CSS overrides
              calendarClassName="react-datepicker"          // For custom CSS overrides
            />
          </Flex>
        </FormControl>

        <Button colorScheme="teal" mt={4} onClick={handleMonthlyProcessing}>
          Run Monthly Processing
        </Button>
      </Box>
    </Box>
  );
}

export default MonthlyProcessing;
