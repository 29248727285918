import React, { useState } from 'react';
import axios from 'axios';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  useToast,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Link,
  UnorderedList,
  ListItem,
  Box,
} from '@chakra-ui/react';

import { FiInfo } from 'react-icons/fi';

/**
 * Helper to determine base URL depending on the hostname
 */
function getBaseUrl() {
  const hostname = window.location.hostname;
  if (hostname === '72.167.34.236') {
    return 'http://72.167.34.236:5000';
  } else if (hostname === 'prolifi.app' || hostname === 'www.prolifi.app') {
    return 'https://prolifi.app';
  }
  return '';
}

/**
 * PROPS:
 *  - isOpen (bool): whether the UsernameUploadModal is open
 *  - onSuccess (fn): called after successful upload
 *  - onCancel (fn): called if user cancels or closes
 */
export default function UsernameUploadModal({ isOpen, onSuccess, onCancel }) {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const toast = useToast();

  // handle file input
  const handleFileChange = (e) => {
    setFile(e.target.files[0] || null);
  };

  // handle the actual upload
  const handleUpload = async () => {
    if (!file) return;
    try {
      setUploading(true);

      // 1) Build FormData
      const formData = new FormData();
      formData.append('file', file);

      // 2) Determine base URL and make request
      const baseUrl = getBaseUrl();
      await axios.post(`${baseUrl}/api/tzv2/upload-usernames`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // 3) On success, show toast or message
      toast({
        title: 'Usernames uploaded successfully!',
        status: 'success',
        duration: 4000,
        isClosable: true,
      });

      // 4) Call onSuccess => triggers parent refresh
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      console.error('Error uploading username file:', error);
      toast({
        title: 'Upload failed',
        description: error.response?.data?.message || error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setUploading(false);
    }
  };

  // handle close
  const handleClose = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="md">
      <ModalOverlay />
      <ModalContent>
        {/* Modal Header */}
        <ModalHeader>
          {/* Relatively positioned box so we can absolutely position the info icon */}
          <Box display="inline-block" position="relative">
            <Text as="span">Upload Username Report</Text>

            <Popover placement="right-start">
              <PopoverTrigger>
                {/* Absolutely-positioned icon button */}
                <IconButton
                  aria-label="More info"
                  icon={<FiInfo />}
                  variant="ghost"
                  size="sm"
                  position="absolute"
                  top="-6px"
                  left="100%"
                  ml="5px"   // add a small gap from the text
                />
              </PopoverTrigger>

              <PopoverContent w="400px">
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader fontWeight="bold" border="0">
                  How to Retrieve Usernames
                </PopoverHeader>
                <PopoverBody fontSize="sm">
                  <UnorderedList spacing={2} mb={2} ml={4}>
                    <ListItem>
                      Go to{' '}
                      <Link
                        href="https://inspirebrands.csod.com/client/inspirebrands/sonic.aspx"
                        isExternal
                        color="blue.500"
                        textDecoration="underline"
                      >
                        Totzone
                      </Link>{' '}
                      and log in under your store admin account
                    </ListItem>
                    <ListItem>
                      Click the hamburger (three lines) at the top right
                      <UnorderedList mt={1}>
                        <ListItem>Click "Admin"</ListItem>
                        <ListItem>Click "Users"</ListItem>
                      </UnorderedList>
                    </ListItem>
                    <ListItem>
                      On the page that loads, leave all boxes blank and click "Search"
                    </ListItem>
                    <ListItem>
                      This should load your list of "Active" employees
                      <UnorderedList mt={1}>
                        <ListItem>
                          Above the table, on the right, you should see two icons:
                          a printer and an "X" (Excel).
                        </ListItem>
                        <ListItem>Click the "X" to download the Excel file.</ListItem>
                      </UnorderedList>
                    </ListItem>
                    <ListItem>Upload this file here to add your usernames.</ListItem>
                  </UnorderedList>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Box>
        </ModalHeader>

        {/* Standard Close Button (in the top-right corner) */}
        <ModalCloseButton onClick={handleClose} />

        {/* Modal Body */}
        <ModalBody>
          <Text mb={2}>
            Please upload your Username Excel or CSV document to sync usernames.
          </Text>
          <input
            type="file"
            accept=".xlsx, .xls, .csv"
            onChange={handleFileChange}
          />
        </ModalBody>

        {/* Modal Footer */}
        <ModalFooter>
          <Button variant="outline" mr={3} onClick={handleClose} isDisabled={uploading}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            onClick={handleUpload}
            isLoading={uploading}
            isDisabled={!file}
          >
            Upload
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
