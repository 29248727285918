// src/components/Login.js

import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  Image,
  Flex,
  Heading,
  useColorModeValue,
} from "@chakra-ui/react";

import FloatingEmblems from "./FloatingEmblems";
import logo from "./images/ptechlogo.png";

function Login({ onLoginSuccess }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [score, setScore] = useState(0);
  const navigate = useNavigate();

  // 1) Instead of storing the child's ref in state, just use React's `useRef`
  const floatingEmblemsRef = useRef(null);

  const getBaseUrl = () => {
    if (window.location.hostname.includes("72.167.34.236")) {
      return "http://72.167.34.236:5000";
    }
    if (window.location.hostname.includes("prolifi.app")) {
      return "https://prolifi.app";
    }
    console.error("Unknown hostname:", window.location.hostname);
    return null;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage(""); // Clear any prior error

    try {
      const baseUrl = getBaseUrl();
      const response = await axios.post(
        `${baseUrl}/api/auth/login`,
        { username, password },
        { withCredentials: true }
      );

      // If first login => require password change
      if (response.data.firstLogin) {
        Swal.fire({
          title: "Complete Your Profile",
          html:
            '<input id="swal-input1" type="password" class="swal2-input" placeholder="New password">' +
            '<input id="swal-input2" type="password" class="swal2-input" placeholder="Confirm password">' +
            '<input id="swal-input3" type="email" class="swal2-input" placeholder="Email address">',
          showCancelButton: true,
          confirmButtonText: "Submit",
          focusConfirm: false,
          preConfirm: () => {
            const pw1 = (document.getElementById("swal-input1") || {}).value;
            const pw2 = (document.getElementById("swal-input2") || {}).value;
            const email = (document.getElementById("swal-input3") || {}).value;

            if (!pw1 || !pw2 || !email) {
              Swal.showValidationMessage("All fields are required");
            } else if (pw1 !== pw2) {
              Swal.showValidationMessage("Passwords do not match");
            } else {
              return { newPassword: pw1, email };
            }
          },
        }).then((result) => {
          if (result.isConfirmed) {
            handleChangePassword(result.value.newPassword, result.value.email)
              .then(() => {
                onLoginSuccess();
                popAllThenNavigate();
              })
              .catch((err) => {
                Swal.showValidationMessage(`Profile update failed: ${err.message}`);
              });
          }
        });
      } else {
        // Normal login => store session & navigate
        sessionStorage.setItem("isAuthenticated", "true");
        sessionStorage.setItem("username", username);
        onLoginSuccess();
        popAllThenNavigate();
      }
    } catch (error) {
      console.error("Login error:", error);

      let userFriendlyError = "Something went wrong. Please try again.";
      if (error.response) {
        const status = error.response.status;
        switch (status) {
          case 401:
            userFriendlyError = "Invalid username or password. Please try again.";
            break;
          case 404:
            userFriendlyError = "User not found. Please check your username.";
            break;
          case 500:
            userFriendlyError =
              "The server encountered an error. Please contact support if this continues.";
            break;
          default:
            userFriendlyError = `Server responded with status ${status}. Please try again.`;
        }
        if (error.response.data?.message) {
          userFriendlyError += `\n\nDetails: ${error.response.data.message}`;
        }
      } else if (error.request) {
        userFriendlyError =
          "No response received from server. Check your network or try again later.";
      } else {
        userFriendlyError = `Request error: ${error.message}`;
      }

      Swal.fire("Login Failed", userFriendlyError, "error");
      setErrorMessage(userFriendlyError);
    }
  };

  const popAllThenNavigate = () => {
    // 2) Check if floatingEmblemsRef.current is valid & has popAllEmblems
    if (floatingEmblemsRef.current?.popAllEmblems) {
      floatingEmblemsRef.current.popAllEmblems();
      setTimeout(() => navigate("/"), 1000);
    } else {
      navigate("/");
    }
  };

  const handleChangePassword = async (newPassword, email) => {
    const baseUrl = getBaseUrl();
    await axios.post(
      `${baseUrl}/api/auth/change-profile`,
      { username, newPassword, email },
      { withCredentials: true }
    );
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Reset Your Password",
      input: "email",
      inputPlaceholder: "Enter your email address",
      showCancelButton: true,
      confirmButtonText: "Reset Password",
      showLoaderOnConfirm: true,
      preConfirm: (email) => {
        return axios
          .post(`${getBaseUrl()}/api/auth/forgot-password`, { email }, { withCredentials: true })
          .then((res) => res.data)
          .catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Email Sent!",
          html: "A password reset link has been sent to your e-mail. It will be valid for 5 minutes.",
          icon: "success",
        });
      }
    });
  };

  // "Easter egg" pop function
  const handlePopEmblem = () => {
    setScore((prev) => prev + 1);
  };

  const containerBg = useColorModeValue("white", "gray.700");
  const overlayGradient = useColorModeValue(
    "linear(to-r, teal.50, blue.50)",
    "linear(to-r, gray.800, gray.900)"
  );

  return (
    <Box position="relative" minH="100vh" p={4} overflow="hidden">
      {/* 3) Pass ref={floatingEmblemsRef} to FloatingEmblems */}
      <FloatingEmblems
        count={10}
        onPop={handlePopEmblem}
        ref={floatingEmblemsRef}
      />

      <Box
        position="absolute"
        top={0}
        left={0}
        w="100%"
        h="100%"
        bgGradient={overlayGradient}
        pointerEvents="none"
        opacity={0.8}
        zIndex={1}
      />

      <Flex position="relative" zIndex={2} minH="100%" align="center" justify="center">
        <Box
          as="form"
          onSubmit={handleLogin}
          maxW="400px"
          w="100%"
          p={6}
          borderWidth="1px"
          borderRadius="md"
          shadow="lg"
          bg={containerBg}
        >
          <Flex align="center" justify="center" mb={4}>
            <Image src={logo} alt="Logo" maxH="60px" />
          </Flex>

          <Heading as="h1" size="md" textAlign="center" color="teal.600" mb={3}>
            Welcome
          </Heading>

          <Text fontSize="sm" textAlign="center" color="gray.600" mb={6}>
            Please sign in to continue
          </Text>

          <FormControl mb={3}>
            <FormLabel fontSize="sm" fontWeight="semibold">
              Username
            </FormLabel>
            <Input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Enter your username"
              size="sm"
            />
          </FormControl>

          <FormControl mb={4}>
            <FormLabel fontSize="sm" fontWeight="semibold">
              Password
            </FormLabel>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              size="sm"
            />
          </FormControl>

          <Button
            type="submit"
            colorScheme="teal"
            w="100%"
            size="sm"
            mt={2}
            _hover={{
              transform: "translateY(-1px)",
              boxShadow: "md",
            }}
          >
            Login
          </Button>

          <Flex justify="flex-end" mt={2}>
            <Button
              variant="link"
              onClick={handleForgotPassword}
              fontSize="xs"
              colorScheme="blue"
              _hover={{ textDecoration: "underline" }}
            >
              Forgot Password?
            </Button>
          </Flex>

          {errorMessage && (
            <Text mt={2} color="red.500" fontSize="sm" textAlign="center">
              {errorMessage}
            </Text>
          )}
        </Box>
      </Flex>

      {score > 0 && (
        <Box
          position="absolute"
          bottom="16px"
          right="16px"
          bg="blackAlpha.700"
          color="white"
          px={4}
          py={2}
          borderRadius="md"
          shadow="md"
          zIndex={3}
        >
          Score: {score}
        </Box>
      )}
    </Box>
  );
}

export default Login;
