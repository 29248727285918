// File: src/components/ProlifiField/Schedules/ShiftTooltip.jsx

import React from 'react';

// Reuse your formatTime or define it here:
function formatTime(dateObj) {
  if (!(dateObj instanceof Date)) return '';
  let hours = dateObj.getHours();
  let minutes = dateObj.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  if (hours === 0) hours = 12;
  const minStr = String(minutes).padStart(2, '0');
  return `${hours}:${minStr} ${ampm}`;
}

export default function ShiftTooltip(props) {
  // props is the event data => { Subject, StartTime, EndTime, Description, ... }
  const st = new Date(props.StartTime);
  const et = new Date(props.EndTime);

  // Format times
  const startStr = formatTime(st);
  const endStr = formatTime(et);

  return (
    <div style={{ padding: '5px' }}>
      <div style={{ fontWeight: 'bold' }}>
        {props.Subject}
      </div>
      <div>
        {startStr} - {endStr}
      </div>
      {props.Description && (
        <div style={{ marginTop: '4px', fontStyle: 'italic' }}>
          {props.Description}
        </div>
      )}
    </div>
  );
}
