const timeToSeconds = (time) => {
  if (!time || typeof time !== "string" || !time.includes(":")) {
    return 9999;
  }
  const [mins, secs] = time.split(":").map(Number);
  return mins * 60 + secs;
};

const safeParseFloat = (val) => {
  if (typeof val !== "string") return NaN;
  return parseFloat(val.replace(/[%,$()]/g, ""));
};

const gradeKPI = {
  LaborPercentage: (value, thresholds) => {
    if (!value || !thresholds || !thresholds.LaborPercentage) {
      return { color: "gray", points: 0 };
    }
    const { greenThreshold, redThreshold } = thresholds.LaborPercentage;
    const num = safeParseFloat(value);
    if (isNaN(num)) return { color: "gray", points: 0 };
    if (num <= greenThreshold) return { color: "green", points: 5 };
    if (num >= redThreshold) return { color: "red", points: 0 };
    return { color: "yellow", points: 3 };
  },

  CrewProduction: (value, thresholds) => {
    if (!value || !thresholds || !thresholds.CrewProduction) {
      return { color: "gray", points: 0 };
    }
    const { greenThreshold, redThreshold } = thresholds.CrewProduction;
    const num = safeParseFloat(value);
    if (isNaN(num)) return { color: "gray", points: 0 };
    if (num >= greenThreshold) return { color: "green", points: 5 };
    if (redThreshold != null && num <= redThreshold) return { color: "red", points: 0 };
    return { color: "yellow", points: 3 };
  },

  OnTimesPercentage: (value, thresholds) => {
    if (!value || !thresholds?.OnTimesPercentage) {
      return { color: "gray", points: 0 };
    }
    const { greenThreshold, redThreshold } = thresholds.OnTimesPercentage;
    const num = safeParseFloat(value);
    if (isNaN(num)) return { color: "gray", points: 0 };
    if (num >= greenThreshold) return { color: "green", points: 5 };
    if (num < redThreshold) return { color: "red", points: 0 };
    return { color: "yellow", points: 3 };
  },

  FormattedAveTime: (value, thresholds) => {
    if (!value || !thresholds?.FormattedAveTime) {
      return { color: "gray", points: 0 };
    }
    const { greenThreshold, redThreshold } = thresholds.FormattedAveTime;
    const seconds = timeToSeconds(value);
    if (seconds <= greenThreshold) return { color: "green", points: 5 };
    if (seconds >= redThreshold) return { color: "red", points: 0 };
    return { color: "yellow", points: 3 };
  },

  FormattedReplyTime: (value, thresholds) => {
    if (!value || !thresholds?.FormattedReplyTime) {
      return { color: "gray", points: 0 };
    }
    const { greenThreshold, redThreshold } = thresholds.FormattedReplyTime;
    const seconds = timeToSeconds(value);
    if (seconds <= greenThreshold) return { color: "green", points: 5 };
    if (seconds >= redThreshold) return { color: "red", points: 0 };
    return { color: "yellow", points: 3 };
  },

  SalesChange: (value, thresholds) => {
    if (!value || !thresholds?.SalesChange) {
      return { color: "gray", points: 0 };
    }
    const { greenThreshold, yellowThreshold, redThreshold } = thresholds.SalesChange;
    const num = safeParseFloat(value);
    if (isNaN(num)) return { color: "gray", points: 0 };
    if (num >= greenThreshold) return { color: "green", points: 5 };
    if (yellowThreshold != null && num >= yellowThreshold) return { color: "yellow", points: 3 };
    return { color: "red", points: 0 };
  },

  ComplaintsPer10k: (value, thresholds) => {
    if (!value || !thresholds?.ComplaintsPer10k) {
      return { color: "gray", points: 0 };
    }
    const { greenThreshold, redThreshold } = thresholds.ComplaintsPer10k;
    const num = safeParseFloat(value);
    if (isNaN(num)) return { color: "gray", points: 0 };
    if (num <= greenThreshold) return { color: "green", points: 5 };
    if (num >= redThreshold) return { color: "red", points: 0 };
    return { color: "yellow", points: 3 };
  },

  FormattedCash: (value, thresholds) => {
    if (!value || !thresholds?.FormattedCash) {
      return { color: "gray", points: 0 };
    }
    const { greenThreshold, redThreshold } = thresholds.FormattedCash;
    let raw = value.replace(/[\$,]/g, "");
    let isNegative = false;
    if (raw.startsWith("(") && raw.endsWith(")")) {
      isNegative = true;
      raw = raw.slice(1, -1);
    }
    const parsed = parseFloat(raw);
    if (isNaN(parsed)) return { color: "gray", points: 0 };
    const num = isNegative ? -parsed : parsed;
    if (num >= greenThreshold) return { color: "green", points: 5 };
    if (redThreshold != null && num < redThreshold) return { color: "red", points: 0 };
    return { color: "yellow", points: 3 };
  },

  FoodVarianceMonth: (value, thresholds) => {
    if (!value || !thresholds?.FoodVarianceMonth) {
      return { color: "gray", points: 0 };
    }
    const { greenThreshold, redThreshold } = thresholds.FoodVarianceMonth;
    const num = safeParseFloat(value);
    if (isNaN(num)) return { color: "gray", points: 0 };
    if (num >= greenThreshold) return { color: "green", points: 5 };
    if (num <= redThreshold) return { color: "red", points: 0 };
    return { color: "yellow", points: 3 };
  },

  ContExpenses: (value, thresholds) => {
    if (!value || !thresholds?.ContExpenses) {
      return { color: "gray", points: 0 };
    }
    const { greenThreshold, redThreshold } = thresholds.ContExpenses;
    const num = safeParseFloat(value);
    if (isNaN(num)) return { color: "gray", points: 0 };
    if (num <= greenThreshold) return { color: "green", points: 5 };
    if (num >= redThreshold) return { color: "red", points: 0 };
    return { color: "yellow", points: 3 };
  },
};

export default gradeKPI;
