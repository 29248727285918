import React, { useEffect, useState } from "react";
import axios from "axios";
import calculateScores from "./CalculateScores";
import { captureTableAndDownloadPDF } from "./generatePopsReportPDF";
import { captureTableAndDownloadExcel } from "./generatePopsReportExcel";
import { FaFilePdf, FaFileExcel } from "react-icons/fa";
import LoadingIndicator from "../LoadingIndicator"; // Ensure this path is correct
import ExcelJS from "exceljs";

// Chakra UI
import {
  Box,
  Flex,
  Heading,
  Select,
  Button,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import PopsReportTable from "./PopsReportTable";

/** Format "Month Year", e.g. "April 2023" */
function formatMonth(date) {
  return date.toLocaleString("default", { month: "long", year: "numeric" });
}

/** Parse a "Month Year" string -> {monthIndex, yearInt} */
function parseSelectedMonth(selectedMonth) {
  const [monthName, year] = selectedMonth.split(" ");
  const monthIndex = new Date(`${monthName} 1, ${year}`).getMonth();
  const yearInt = parseInt(year, 10);
  return { monthIndex, yearInt };
}

/** Days in a given monthIndex + year */
function getDaysInMonth(monthIndex, year) {
  return new Date(year, monthIndex + 1, 0).getDate();
}

/** Check if the selectedMonth is the same as the current system month/year */
function isCurrentMonthAndYear(selectedMonth) {
  const { monthIndex, yearInt } = parseSelectedMonth(selectedMonth);
  const today = new Date();
  return (
    monthIndex === today.getMonth() && 
    yearInt === today.getFullYear()
  );
}

function PopsReport() {
  // Month selection
  const [selectedMonth, setSelectedMonth] = useState(formatMonth(new Date()));
  // Logged-in user's role
  const [userGroup, setUserGroup] = useState("");
  // Director area selection
  const [selectedArea, setSelectedArea] = useState("Area Selection");
  const [directorAreas] = useState([
    "Area Selection",
    "Esch Group",
    "Northwest Arkansas",
    "Central Arkansas",
    "Tennessee",
    "St Louis",
    "Champaign",
    "Kansas & Nebraska",
  ]);

  // Loading / data states
  const [gradingStandardsLoaded, setGradingStandardsLoaded] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [gradingStandards, setGradingStandards] = useState({});
  const [areaAveragesState, setAreaAveragesState] = useState([]);

  // Chakra theming
  const pageBg = useColorModeValue("gray.50", "gray.800");
  const boxBg = useColorModeValue("white", "gray.700");
  const borderCol = useColorModeValue("gray.200", "gray.600");

  // Build an array of the last 12 months
  const months = Array.from({ length: 12 }, (_, i) => {
    const d = new Date();
    d.setMonth(d.getMonth() - i);
    return formatMonth(d);
  }).reverse();

  /**
   * On mount => fetch user group
   */
  useEffect(() => {
    const getUserGroupAndCompany = async () => {
      try {
        setIsLoading(true);
        const baseUrl = getBaseUrl();
        const response = await axios.get(`${baseUrl}/api/user-store`, {
          withCredentials: true,
        });
        const fetchedGroup = response.data.userGroup || "";
        setUserGroup(fetchedGroup);

        // If not director, load default standards
        if (fetchedGroup !== "director") {
          fetchGradingStandards();
        }
      } catch (error) {
        // handle error
      } finally {
        setIsLoading(false);
      }
    };
    getUserGroupAndCompany();
  }, []);

  /**
   * Build base URL
   */
  function getBaseUrl() {
    const hostname = window.location.hostname;
    if (hostname === "72.167.34.236") {
      return "http://72.167.34.236:5000";
    } else if (/^(www\.)?prolifi\.app$/.test(hostname)) {
      return "https://prolifi.app";
    } else {
      return "http://localhost:5000";
    }
  }

  /**
   * Fetch grading standards for chosen area (or "Default")
   */
  const fetchGradingStandards = async () => {
    if (
      userGroup === "director" &&
      (!selectedArea || selectedArea === "Area Selection")
    ) {
      setGradingStandards({});
      setGradingStandardsLoaded(false);
      return;
    }

    let areaToFetch =
      !selectedArea || selectedArea === "Area Selection"
        ? "Default"
        : selectedArea;

    try {
      setIsLoading(true);
      const baseUrl = getBaseUrl();
      const encodedArea = encodeURIComponent(areaToFetch);
      const url = `${baseUrl}/api/grading-standards/${encodedArea}`;
      const response = await axios.get(url, { withCredentials: true });

      if (response.data) {
        setGradingStandards(response.data);
        setGradingStandardsLoaded(true);
      } else {
        setGradingStandards({});
        setGradingStandardsLoaded(false);
      }
    } catch (error) {
      setGradingStandards({});
      setGradingStandardsLoaded(false);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * If director => re-fetch standards on area change
   */
  useEffect(() => {
    if (userGroup === "director") {
      fetchGradingStandards();
    }
    // eslint-disable-next-line
  }, [selectedArea, userGroup]);

  // Handle selects
  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };
  const handleAreaChange = (e) => {
    setSelectedArea(e.target.value);
  };

  /**
   * MAIN fetch => transform => scoring => final
   */
  useEffect(() => {
    if (
      userGroup === "director" &&
      (selectedArea === "Area Selection" || !selectedArea)
    ) {
      setReportData([]);
      return;
    }

    setReportData([]);
    setIsLoading(true);

    const fetchData = async () => {
      try {
        const baseUrl = getBaseUrl();
        let url = `${baseUrl}/api/pops-report/${selectedMonth}`;
        if (userGroup === "director") {
          url += `/${selectedArea}`;
        }

        const response = await axios.get(url, { withCredentials: true });
        const rawData = response.data || {};
        const originalArray = Object.values(rawData);

        // Fixed monthly manager cost
        const baseManagerCost = 3948; 

        // Determine if the selectedMonth is the current month => pro-rate
        let managerCostForCalc = baseManagerCost;
        if (isCurrentMonthAndYear(selectedMonth)) {
          // Find how many days in the chosen month, how many have fully elapsed
          const { monthIndex, yearInt } = parseSelectedMonth(selectedMonth);
          const totalDays = getDaysInMonth(monthIndex, yearInt);

          const now = new Date();
          // If today is the 6th, we consider 5 full days
          const daysElapsed = now.getDate() - 1; 
          if (daysElapsed < 0) {
            // In case it's the 1st, we effectively have 0 full days
            managerCostForCalc = 0; 
          } else {
            managerCostForCalc = (baseManagerCost / totalDays) * daysElapsed;
          }
        }

        // Transform
        const transformed = originalArray.map((store, idx) => {
          const net = parseFloat(store.NetSales) || 0;
          const lyNet = parseFloat(store.LastYearNetSales) || 0;
          const laborVal = parseFloat(store.Labor) || 0;
          const actualFPCost = parseFloat(store.actualFandPCost) || 0;

          // Use managerCostForCalc for THIS store if it's the current month
          // Otherwise, just use the baseManagerCost for older months
          let thisStoreManagerCost = isCurrentMonthAndYear(selectedMonth)
            ? managerCostForCalc
            : baseManagerCost;

          let managerPctStr = "N/A";
          if (net > 0) {
            managerPctStr = ((thisStoreManagerCost / net) * 100).toFixed(2);
          }

          let laborPctNum = 0;
          if (net > 0) {
            laborPctNum = (laborVal / net) * 100;
          }

          let sumStr = "N/A";
          if (managerPctStr !== "N/A") {
            const mgmNum = parseFloat(managerPctStr) || 0;
            const sum = mgmNum + laborPctNum + actualFPCost;
            sumStr = `${sum.toFixed(2)}%`;
          }

          let sc = "N/A";
          if (lyNet !== 0) {
            const diff = ((net - lyNet) / lyNet) * 100;
            sc = `${diff.toFixed(2)}%`;
          }

          const storeArea = store.Area || "N/A";

          let cp = "N/A";
          if (store.TotalHours && parseFloat(store.TotalHours) > 0) {
            cp = `$${(net / parseFloat(store.TotalHours)).toFixed(2)}`;
          }

          let onTimes = "N/A";
          const onVal = parseFloat(store.OnTime) || 0;
          const tkVal = parseFloat(store.Tickets) || 0;
          if (tkVal > 0) {
            onTimes = `${((onVal / tkVal) * 100).toFixed(2)}%`;
          }

          const formatTime = (sec) => {
            const s = parseFloat(sec) || 0;
            const m = Math.floor(s / 60);
            const leftover = Math.floor(s % 60);
            return `${m}:${leftover < 10 ? "0" : ""}${leftover}`;
          };

          const aveTime = formatTime(store.AveTime);
          const replyTime = formatTime(store.ReplyTime);

          let c10k = "N/A";
          const mtdSyncVal = parseFloat(store.mtdSyncReports) || 0;
          if (tkVal > 0) {
            c10k = ((mtdSyncVal / tkVal) * 10000).toFixed(2);
          }

          let cashFloat = parseFloat(store.Cash) || 0;
          let cashStr = cashFloat.toFixed(2);
          if (cashFloat < 0) {
            cashStr = `(${Math.abs(cashFloat).toFixed(2)})`;
          }

          let laborPctStr = "N/A";
          if (net > 0) {
            laborPctStr = `${laborPctNum.toFixed(2)}%`;
          }

          let fv = "N/A";
          if (store.foodVarianceMonth != null) {
            fv = parseFloat(store.foodVarianceMonth).toFixed(2);
          }

          // Log anything "controllable profit" related for all stores
          console.log("----- CONTROLLABLE PROFIT LOG -----");
          console.log("Store Name:", store.storeName);
          console.log("Net Sales:", net);
          console.log("Is Current Month?", isCurrentMonthAndYear(selectedMonth));
          console.log("DaysElasped (if current month):", isCurrentMonthAndYear(selectedMonth) ? (new Date().getDate() - 1) : "N/A");
          console.log("Manager Cost Used:", thisStoreManagerCost.toFixed(2));
          console.log("Manager Pct of Net (managerPctStr):", managerPctStr);
          console.log("Labor Value:", laborVal);
          console.log("Labor % of Net (laborPctNum):", laborPctNum.toFixed(2));
          console.log("Actual F&P Cost:", actualFPCost);
          console.log("ContExpenses (sumStr):", sumStr);
          console.log("CrewProduction (cp):", cp);
          console.log("-----------------------------------");

          return {
            storeId: idx,
            storeName: store.storeName || "Unknown",
            storeArea,
            MtdSales: net.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            }),
            SalesChange: sc,
            SalesChangeForGrading: 0,
            ContExpenses: sumStr,
            CrewProduction: cp,
            LaborPercentage: laborPctStr,
            FoodVarianceMonth: fv,
            OnTimesPercentage: onTimes,
            FormattedAveTime: aveTime,
            FormattedReplyTime: replyTime,
            ComplaintsPer10k: c10k,
            FormattedCash: cashStr,
            usedAreaAverage: false,
          };
        });

        // Compute area average for SalesChange
        const areaSalesMap = {};
        transformed.forEach((st) => {
          const area = st.storeArea;
          if (!areaSalesMap[area]) {
            areaSalesMap[area] = [];
          }
          if (st.SalesChange !== "N/A") {
            const numericVal = parseFloat(st.SalesChange.replace("%", ""));
            if (!isNaN(numericVal)) {
              areaSalesMap[area].push(numericVal);
            }
          }
        });

        const areaAvgMap = {};
        Object.keys(areaSalesMap).forEach((ar) => {
          const arr = areaSalesMap[ar];
          if (arr.length === 0) {
            areaAvgMap[ar] = 0;
          } else {
            const s = arr.reduce((acc, v) => acc + v, 0);
            areaAvgMap[ar] = s / arr.length;
          }
        });

        // Fill SalesChangeForGrading
        transformed.forEach((st) => {
          if (st.SalesChange === "N/A") {
            st.usedAreaAverage = true;
            st.SalesChangeForGrading = areaAvgMap[st.storeArea] || 0;
          } else {
            const scVal = parseFloat(st.SalesChange.replace("%", "")) || 0;
            st.SalesChangeForGrading = scVal;
          }
        });

        // scoring => rank => final
        const scoredData = calculateScores(
          transformed,
          gradingStandards,
          areaAveragesState
        );
        const finalData = scoredData
          .slice()
          .sort((a, b) => b.overallScore - a.overallScore);

        finalData.forEach((store, i) => {
          store.rank = i + 1;
        });

        setReportData(finalData);
      } catch (err) {
        setReportData([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [selectedMonth, selectedArea, userGroup]);

  // PDF/Excel handlers
  const handleDownloadPDF = async () => {
    setIsLoading(true);
    await captureTableAndDownloadPDF(
      reportData,
      selectedMonth,
      selectedArea,
      gradingStandards
    );
    setIsLoading(false);
  };

  const handleDownloadExcel = async () => {
    setIsLoading(true);
    await captureTableAndDownloadExcel(
      reportData,
      selectedMonth,
      selectedArea,
      gradingStandards,
      areaAveragesState
    );
    setIsLoading(false);
  };

  // If we're currently loading => show only the LoadingIndicator
  if (isLoading) {
    return (
      <Flex direction="column" bg={pageBg} minH="100vh" p={4} justify="center" align="center">
        <LoadingIndicator />
      </Flex>
    );
  }

  // Otherwise, render the rest of the UI
  return (
    <Flex direction="column" bg={pageBg} minH="100vh" p={4}>
      {/* Controls Container */}
      <Flex
        mb={4}
        gap={4}
        wrap="wrap"
        align="center"
        borderWidth="1px"
        borderColor={borderCol}
        p={4}
        bg={boxBg}
        borderRadius="md"
        justifyContent="space-between"
      >
        {/* Left side: Month & (Area if Director) */}
        <Flex gap={6} align="center">
          <Box>
            <Text fontSize="sm" mb={1}>
              Select a Month:
            </Text>
            <Select
              value={selectedMonth}
              onChange={handleMonthChange}
              bg="white"
              color="black"
              size="sm"
            >
              {months.map((m) => (
                <option key={m} value={m}>
                  {m}
                </option>
              ))}
            </Select>
          </Box>

          {userGroup === "director" && (
            <Box>
              <Text fontSize="sm" mb={1}>
                Select an Area:
              </Text>
              <Select
                value={selectedArea}
                onChange={handleAreaChange}
                bg="white"
                color="black"
                size="sm"
              >
                {directorAreas.map((a) => (
                  <option key={a} value={a}>
                    {a}
                  </option>
                ))}
              </Select>
            </Box>
          )}
        </Flex>

        {/* Right side: PDF/Excel buttons (minimalistic) */}
        <Flex gap={2}>
          <Button
            onClick={handleDownloadPDF}
            leftIcon={<FaFilePdf />}
            colorScheme="red"
            variant="outline"
            size="sm"
          >
            PDF
          </Button>
          <Button
            onClick={handleDownloadExcel}
            leftIcon={<FaFileExcel />}
            colorScheme="green"
            variant="outline"
            size="sm"
          >
            Excel
          </Button>
        </Flex>
      </Flex>

      {/* Table or a notice if area selection is pending */}
      {(userGroup !== "director" ||
        (gradingStandardsLoaded && selectedArea !== "Area Selection")) && (
        <Box
          borderWidth="1px"
          borderColor={borderCol}
          borderRadius="md"
          p={2}
          bg={boxBg}
        >
          <PopsReportTable data={reportData} thresholds={gradingStandards} />
        </Box>
      )}

      {userGroup === "director" &&
        (!gradingStandardsLoaded || selectedArea === "Area Selection") && (
          <Flex justify="center" mt={4}>
            <Text>Loading Grading Standards or waiting for area selection...</Text>
          </Flex>
        )}
    </Flex>
  );
}

export default PopsReport;
