// File: src/components/ProlifiField/Schedules/director/LegacyDirectorModal.jsx

import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Select
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";

import {
  MultiSelectComponent,
  CheckBoxSelection,
  Inject
} from "@syncfusion/ej2-react-dropdowns";

import "./DirectorCustomModal.css";

/**
 * If the user picks "Store Visit(s)", we let them choose from the
 * “director areas” (like "Kansas & Nebraska", "Champaign", etc.).
 * 
 * We also split "Kansas & Nebraska" → ["Kansas", "Nebraska"].
 * 
 * Start/end are date-only fields; we automatically set 7 AM for the start
 * and 11:59 PM for the end. Then we convert them to a local date/time string
 * with no trailing 'Z'.
 */

/** Helper => transform the raw areas array => split "Kansas & Nebraska" */
function transformAreas(rawAreas) {
  const result = [];
  for (const a of rawAreas) {
    const trimmed = (a || "").trim();
    if (trimmed.toLowerCase() === "kansas & nebraska") {
      result.push("Kansas");
      result.push("Nebraska");
    } else {
      result.push(trimmed);
    }
  }
  return result;
}

/** Helper => given a Date, return local YYYY-MM-DDTHH:mm:ss (no trailing 'Z'). */
function toLocalDateTimeString(dateObj) {
  if (!(dateObj instanceof Date) || isNaN(dateObj)) return "";

  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  const day = String(dateObj.getDate()).padStart(2, "0");
  const hour = String(dateObj.getHours()).padStart(2, "0");
  const min  = String(dateObj.getMinutes()).padStart(2, "0");
  const sec  = String(dateObj.getSeconds()).padStart(2, "0");

  // Example: "2025-01-23T07:00:00"
  return `${year}-${month}-${day}T${hour}:${min}:${sec}`;
}

/** The old "LegacyDirectorModal" for Director row shifts */
export default function LegacyDirectorModal({
  isOpen,
  onClose,
  onSave,
  onDelete,
  initialEvent,           // The event being edited (null if creating new)
  staticItems = ["Office", "Vacation", "Meetings"],
  availableStores = []    // The array of textual director areas
}) {
  // We rename "Store Visit(s)" => eventType => "store-visits"
  const [eventType, setEventType] = useState("");
  const [areaList,  setAreaList]  = useState([]); // user-chosen areas (multi-select)
  const [startTime, setStartTime] = useState(""); // date-only (e.g. "2025-01-23")
  const [endTime,   setEndTime]   = useState(""); // date-only (e.g. "2025-01-24")
  const [notes,     setNotes]     = useState([]);

  // We'll keep a local array => directorAreas, after splitting "Kansas & Nebraska"
  const [directorAreas, setDirectorAreas] = useState([]);

  // If user picks "store-visits" => show the multi-select
  const isStoreVisit = (eventType === "store-visits");

  // 1) Transform availableStores => directorAreas (split "Kansas & Nebraska")
  useEffect(() => {
    setDirectorAreas(transformAreas(availableStores));
  }, [availableStores]);

  // 2) On open or on `initialEvent` change => load local state from event
  useEffect(() => {
    if (!initialEvent) {
      setEventType("");
      setAreaList([]);
      setStartTime("");
      setEndTime("");
      setNotes([]);
      return;
    }

    // If storeOrEvent === "store-visits"
    if (initialEvent.storeOrEvent === "store-visits") {
      setEventType("store-visits");
      setAreaList(initialEvent.storeIds || []);
    }
    // If storeOrEvent starts with "static-", e.g. "static-Office"
    else if (initialEvent.storeOrEvent?.startsWith("static-")) {
      setEventType(initialEvent.storeOrEvent);
      setAreaList([]);
    } else {
      setEventType("");
      setAreaList([]);
    }

    // Convert old startTime/endTime => date-only
    const stDate = parseLocalDate(initialEvent.startTime);
    const etDate = parseLocalDate(initialEvent.endTime);
    setStartTime(stDate || "");
    setEndTime(etDate   || "");

    // Also load notes array
    setNotes(Array.isArray(initialEvent.notes) ? initialEvent.notes : []);
  }, [initialEvent, isOpen]);

  /** parseLocalDate => "YYYY-MM-DD HH:mm:ss" -> "YYYY-MM-DD" ignoring time-of-day. */
  function parseLocalDate(val) {
    if (!val) return "";
    const dt = new Date(val); // parse
    if (isNaN(dt.getTime())) return "";
    // Build a "YYYY-MM-DD" ignoring the time
    const yyyy = dt.getFullYear();
    const mm   = String(dt.getMonth() + 1).padStart(2, "0");
    const dd   = String(dt.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  }

  // 3) On Save => build payload with local date/time (7 AM start, 11:59 PM end)
  function handleSave() {
    if (!eventType) {
      alert("Please pick an event type.");
      return;
    }
    if (!startTime || !endTime) {
      alert("Please pick Start & End dates.");
      return;
    }

    let finalType  = eventType;
    let finalAreas = [];
    if (isStoreVisit) {
      finalType = "store-visits";
      if (!areaList.length) {
        alert("Pick at least one area if using Store Visit(s).");
        return;
      }
      finalAreas = areaList;
    }

    // 7:00 AM on start date
    const startDateObj = new Date(`${startTime}T07:00:00`);
    // 11:59 PM on end date
    const endDateObj   = new Date(`${endTime}T23:59:00`);

    // Build local datetime strings (no 'Z')
    const localStartStr = toLocalDateTimeString(startDateObj); 
    const localEndStr   = toLocalDateTimeString(endDateObj);

    // Final object => match the route’s expected shape:
    const payload = {
      storeOrEvent: finalType,
      storeIds:     finalAreas,
      startTime:    localStartStr,
      endTime:      localEndStr,
      notes
    };

    // If editing => preserve the existing DB `Id`
    if (initialEvent?.Id) {
      payload.Id = initialEvent.Id;
    }

    // Let the parent handle saving to DB
    onSave(payload);
  }

  function handleAddNote() {
    setNotes(prev => [...prev, ""]);
  }
  function handleNoteChange(idx, val) {
    setNotes(prev => {
      const copy = [...prev];
      copy[idx] = val;
      return copy;
    });
  }

  if (!isOpen) {
    return null; // Don't render anything if modal is closed
  }

  // Build our event-type dropdown (store visits or static items)
  const eventTypeOptions = [
    { value: "store-visits", label: "Store Visit(s)" },
    ...staticItems.map(s => ({ value: `static-${s}`, label: s }))
  ];

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      trapFocus={false}
      closeOnOverlayClick={false}
      blockScrollOnMount={false}
      size="xl"
      zIndex={9998}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        border="1px solid gold"
        boxShadow="0 0 10px rgba(218,165,32,0.5)"
      >
        <ModalHeader fontWeight="bold">
          {initialEvent?.Id ? "Edit Director Shift" : "Create Director Shift"}
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          {/* 1) Event Type */}
          <FormControl mb={4}>
            <FormLabel>Event Type</FormLabel>
            <Select
              placeholder="Pick an event type..."
              bg="white"
              value={eventType}
              onChange={(e) => {
                setEventType(e.target.value);
                if (e.target.value !== "store-visits") {
                  setAreaList([]);
                }
              }}
            >
              {eventTypeOptions.map(opt => (
                <option key={opt.value} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </Select>
          </FormControl>

          {/* 2) If "store-visits" => Multi-Select the directorAreas */}
          {isStoreVisit && (
            <FormControl mb={4}>
              <FormLabel>Pick Area(s)</FormLabel>
              <MultiSelectComponent
                id="multiAreaSelect"
                dataSource={directorAreas}
                placeholder="Select one or more areas..."
                mode="CheckBox"
                showSelectAll={true}
                showDropDownIcon={true}
                allowFiltering={true}
                filterBarPlaceholder="Search areas..."
                popupWidth="auto"
                width="100%"
                value={areaList}
                change={(e) => setAreaList(e.value || [])}
                cssClass="myMultiSelect"
              >
                <Inject services={[CheckBoxSelection]} />
              </MultiSelectComponent>
            </FormControl>
          )}

          {/* 3) Start/End => date-only fields */}
          <FormControl mb={4}>
            <FormLabel>Start Date</FormLabel>
            <Input
              type="date"
              bg="white"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>End Date</FormLabel>
            <Input
              type="date"
              bg="white"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
            />
          </FormControl>

          {/* 4) Multiple "notes" fields */}
          <FormControl mb={2}>
            <FormLabel>Notes (multiple)</FormLabel>
          </FormControl>
          <VStack align="stretch" spacing={2}>
            {notes.map((n, idx) => (
              <Input
                key={idx}
                bg="white"
                placeholder={`Note #${idx + 1}`}
                value={n}
                onChange={(e) => handleNoteChange(idx, e.target.value)}
              />
            ))}
          </VStack>

          <Button
            leftIcon={<AddIcon />}
            size="sm"
            mt={2}
            variant="outline"
            onClick={handleAddNote}
          >
            Add Another Note
          </Button>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSave}>
            Save
          </Button>
          {initialEvent?.Id && (
            <Button
              colorScheme="red"
              mr={3}
              onClick={() => onDelete(initialEvent.Id)}
            >
              Delete
            </Button>
          )}
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
