import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  FormControl,
  FormLabel,
  Input,
  Text
} from '@chakra-ui/react';

function LFLPromptModal({
  visible,
  onClose,
  onSkipLFL,
  onConfirmLFL
}) {
  const [lflFile, setLflFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files && e.target.files[0];
    setLflFile(file || null);
  };

  return (
    <Modal isOpen={visible} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload LFL File?</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Text mb={4}>
            Do you need to upload a <strong>Live Free Live</strong> (LFL)
            Totzone file <em>in addition</em> to the main file?
          </Text>

          <Box display="flex" flexDirection="column" gap={3}>
            <FormControl>
              <FormLabel htmlFor="lflFile" mb={1}>
                If yes, browse to select your LFL file:
              </FormLabel>
              <Input
                type="file"
                id="lflFile"
                accept=".xlsx,.xls"
                onChange={handleFileChange}
              />
            </FormControl>

            <Button
              colorScheme="blue"
              onClick={() => {
                if (lflFile) {
                  onConfirmLFL(lflFile);
                } else {
                  onSkipLFL();
                }
              }}
            >
              {lflFile ? 'Upload & Continue' : 'No, just process main file'}
            </Button>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel / Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default LFLPromptModal;
