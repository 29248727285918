// src/components/EEDataUpload.js
import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

// Chakra UI
import {
  Box,
  Button,
  Input,
  Heading,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";

// Your custom loading indicator
import LoadingIndicator from "../LoadingIndicator";

function ExcelProcessor() {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasUploaded, setHasUploaded] = useState(false);

  /**
   * Returns base URL depending on environment
   */
  const getBaseUrl = () => {
    if (process.env.NODE_ENV === "development") {
      return "http://72.167.34.236:5000";
    }
    return "https://prolifi.app";
  };

  /**
   * Handle file selection
   */
  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
      setHasUploaded(false);
    }
  };

  /**
   * Upload the selected file
   */
  const uploadFile = async () => {
    if (!file) {
      Swal.fire("Oops...", "Please select a file first", "error");
      return;
    }

    setIsLoading(true);
    setHasUploaded(false);

    const formData = new FormData();
    formData.append("file", file);

    try {
      const baseUrl = getBaseUrl();
      const response = await axios.post(`${baseUrl}/api/upload-employee-data`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        withCredentials: true,
      });

      if (response.status === 200) {
        Swal.fire("Success!", "File uploaded successfully", "success");
        setHasUploaded(true);
      } else {
        Swal.fire("Error", "Failed to upload file", "error");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      Swal.fire("Error", "An unexpected error occurred", "error");
    } finally {
      setIsLoading(false);
    }
  };

  // Chakra theming (optional)
  const pageBg = useColorModeValue("gray.50", "gray.800");
  const containerBg = useColorModeValue("white", "gray.700");

  return (
    <Box bg={pageBg} minH="100vh" p={4}>
      {/** Show your custom loading indicator if isLoading=true */}
      {isLoading && <LoadingIndicator />}

      <Box
        maxW="600px"
        mx="auto"
        p={4}
        borderWidth="1px"
        borderRadius="md"
        bg={containerBg}
        shadow="md"
      >
        <Heading mb={4} size="md">
          Employee Data Upload
        </Heading>

        {/* File Input */}
        <Input
          type="file"
          onChange={handleFileChange}
          accept=".xlsx, .xls"
          mb={4}
        />

        {/* Upload Button */}
        <Button
          colorScheme="teal"
          onClick={uploadFile}
          isDisabled={isLoading} // Optionally disable the button while loading
        >
          Upload File
        </Button>

        {/* Success message */}
        {hasUploaded && (
          <Text mt={3} color="green.500" fontSize="sm">
            Your file has been uploaded successfully!
          </Text>
        )}
      </Box>
    </Box>
  );
}

export default ExcelProcessor;
