// File: src/components/ProlifiField/Schedules/super/ChakraVariantModal.jsx

import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  // Remove ModalCloseButton => can't manually close
  Button,
  Text,
  Flex,
  VStack,
  HStack,
  Box,
  IconButton,
} from '@chakra-ui/react';

import { ArrowLeftIcon, ArrowRightIcon, AddIcon, DeleteIcon } from '@chakra-ui/icons';
import Select from 'react-select';

/**
 * Helper => Convert a Date to "YYYY-MM-DDTHH:mm" for <input type="datetime-local"/>
 */
function toDateTimeLocalString(date) {
  if (!(date instanceof Date) || isNaN(date.getTime())) return '';
  const yyyy = date.getFullYear();
  const mm   = String(date.getMonth() + 1).padStart(2, '0');
  const dd   = String(date.getDate()).padStart(2, '0');
  const hh   = String(date.getHours()).padStart(2, '0');
  const min  = String(date.getMinutes()).padStart(2, '0');
  return `${yyyy}-${mm}-${dd}T${hh}:${min}`;
}

/**
 * Return array of typical "Reasons" based on store/event ID
 */
function getReasonOptionsForCombinedId(combinedId) {
  if (!combinedId) return [];
  const lower = String(combinedId).toLowerCase();

  if (lower === 'vacation') {
    return [];
  }
  if (lower === 'office') {
    return [
      'End of Week',
      'Schedule Review',
      'Inventory Review',
      'End of Month',
      'Other',
    ];
  }
  if (lower === 'meetings') {
    return ['Store/GM', 'Supervisor', 'Class: AMLC', 'Class: EGMC', 'Other'];
  }
  // Otherwise => assume normal store
  return [
    'General Visit',
    'Struggling Sales, Profit, or KPIs',
    'Audit/Quarterly',
    'Working w/ MIT',
    'GM not Present',
    'Other',
  ];
}

/**
 * ChakraVariantModal
 *
 * Props:
 *   - isOpen: boolean => show/hide
 *   - variantShifts: array => e.g. [ { id, subject, start_time, end_time, ...}, ...]
 *   - storeData: array => e.g. [ { store_id, store_name }, ... ]
 *   - onComplete: function(resultsArr) => user-provided callback
 *   - forceCloseVariant: function => forcibly closes the modal (like setIsVariantModalOpen(false))
 *
 * Behavior:
 *   1) For each SHIFT => we create an array of lines. Each line => { storeId, reasons, reasonOther, startTime, endTime }
 *   2) "Add Another Shift" => user can add more lines
 *   3) Nav => if multiple SHIFTs => "Next" => switch to next SHIFT's array
 *   4) "Submit Shifts" => calls onComplete(results) => results => array of { id, actualShifts: [ lines ] }
 *   5) Then calls forceCloseVariant() to remove the modal
 */
export default function ChakraVariantModal({
  isOpen,
  variantShifts = [],
  storeData = [],
  onComplete,
  forceCloseVariant, // new prop => forcibly close the modal
}) {
  const [currentIndex, setCurrentIndex] = useState(0);

  /**
   * actualShiftsArr => same length as variantShifts
   * actualShiftsArr[i] => array of lines for SHIFT i
   */
  const [actualShiftsArr, setActualShiftsArr] = useState([]);

  // On mount => build initial lines
  useEffect(() => {
    if (!variantShifts || variantShifts.length === 0) {
      setCurrentIndex(0);
      setActualShiftsArr([]);
      return;
    }

    // For each SHIFT => create 1 line w/ the original SHIFT times
    const initial = variantShifts.map((sh) => {
      let st = null;
      let et = null;
      if (sh.start_time) {
        const parsed = new Date(sh.start_time);
        if (!isNaN(parsed)) st = parsed;
      }
      if (sh.end_time) {
        const parsed = new Date(sh.end_time);
        if (!isNaN(parsed)) et = parsed;
      }

      return [
        {
          storeId: '',
          reasons: [],
          reasonOther: '',
          startTime: st,
          endTime: et,
        },
      ];
    });

    setActualShiftsArr(initial);
    setCurrentIndex(0);
  }, [variantShifts]);

  // If not open or no SHIFTs => nothing to show
  if (!isOpen || variantShifts.length === 0) {
    return null;
  }

  // If we somehow exceed the SHIFT count => reset
  if (currentIndex >= variantShifts.length) {
    setCurrentIndex(0);
    return null;
  }

  // The SHIFT we are currently editing
  const shift = variantShifts[currentIndex];
  const lines = actualShiftsArr[currentIndex] || [];

  // Update a single field
  function handleLineChange(lineIdx, field, value) {
    setActualShiftsArr((prev) => {
      const newArr = [...prev];
      const lineArr = [...newArr[currentIndex]];
      lineArr[lineIdx] = { ...lineArr[lineIdx], [field]: value };
      newArr[currentIndex] = lineArr;
      return newArr;
    });
  }

  function handleReasonsChange(lineIdx, selectedOptions) {
    const arr = selectedOptions ? selectedOptions.map((o) => o.value) : [];
    handleLineChange(lineIdx, 'reasons', arr);
  }

  function handleDateTimeChange(lineIdx, field, e) {
    const dt = new Date(e.target.value);
    if (isNaN(dt.getTime())) {
      handleLineChange(lineIdx, field, null);
    } else {
      handleLineChange(lineIdx, field, dt);
    }
  }

  function handleAddLine() {
    setActualShiftsArr((prev) => {
      const newOuter = [...prev];
      const lineArr = [...newOuter[currentIndex]];

      let defStart = null;
      let defEnd = null;
      if (lineArr.length > 0) {
        const lastLine = lineArr[lineArr.length - 1];
        if (lastLine.endTime instanceof Date && !isNaN(lastLine.endTime)) {
          defStart = new Date(lastLine.endTime);
          defEnd = new Date(lastLine.endTime.getTime() + 3 * 60 * 60 * 1000);
        }
      }

      lineArr.push({
        storeId: '',
        reasons: [],
        reasonOther: '',
        startTime: defStart,
        endTime: defEnd,
      });

      newOuter[currentIndex] = lineArr;
      return newOuter;
    });
  }

  function handleRemoveLine(lineIdx) {
    setActualShiftsArr((prev) => {
      const newOuter = [...prev];
      const lineArr = [...newOuter[currentIndex]];
      lineArr.splice(lineIdx, 1);
      newOuter[currentIndex] = lineArr;
      return newOuter;
    });
  }

  // Prev/Next SHIFT
  function handlePrev() {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  }
  function handleNext() {
    if (currentIndex < variantShifts.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  }

  // final => gather results => call onComplete => then close
  function handleSubmitAll() {
    const results = variantShifts.map((origShift, idx) => ({
      id: origShift.id,
      actualShifts: actualShiftsArr[idx],
    }));
    // pass back to parent
    onComplete(results);

    // forcibly close the modal
    if (typeof forceCloseVariant === 'function') {
      forceCloseVariant();
    }
  }

  // Validation => must have storeId, startTime, endTime
  function lineValid(line) {
    return (
      line.storeId &&
      line.startTime instanceof Date && !isNaN(line.startTime) &&
      line.endTime instanceof Date && !isNaN(line.endTime)
    );
  }
  const allLinesValid = lines.every(lineValid);

  // If the user must complete all SHIFTs => check entire array
  function entireModalValid() {
    return actualShiftsArr.every((arr) => arr.every(lineValid));
  }

  // For display => original SHIFT date
  const startObj = shift.start_time ? new Date(shift.start_time) : null;
  const dateStr = startObj
    ? startObj.toLocaleDateString(undefined, {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
      })
    : '';

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {}} // no-op => can't close manually
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size="4xl"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        {/* No <ModalCloseButton /> => blocking */}
        <ModalHeader borderBottomWidth="1px" borderColor="gray.200">
          You Did Not Work This Shift
        </ModalHeader>

        <ModalBody>
          {/* SHIFT Nav => multiple SHIFTs to handle */}
          <Flex justify="space-between" align="center" mb={3}>
            <IconButton
              icon={<ArrowLeftIcon />}
              aria-label="Prev shift"
              onClick={handlePrev}
              isDisabled={currentIndex === 0}
              size="sm"
            />
            <Text fontSize="sm">
              Shift {currentIndex + 1} of {variantShifts.length}
            </Text>
            <IconButton
              icon={<ArrowRightIcon />}
              aria-label="Next shift"
              onClick={handleNext}
              isDisabled={currentIndex === variantShifts.length - 1}
              size="sm"
            />
          </Flex>

          {/* Original SHIFT info */}
          <Box
            p={2}
            bg="gray.50"
            borderRadius="md"
            mb={3}
            border="1px"
            borderColor="gray.200"
          >
            <Text fontWeight="bold" fontSize="md" mb={1}>
              Original: {shift.subject} – {dateStr}
            </Text>
            <Text fontSize="xs" color="gray.600">
              Start: {shift.start_time} / End: {shift.end_time}
            </Text>
          </Box>

          <Text fontSize="sm" mb={3}>
            Fill out what you actually worked instead:
          </Text>

          {/* For each SHIFT => multiple lines */}
          <VStack spacing={4} align="stretch">
            {lines.map((line, lineIdx) => {
              const reasonStrings = getReasonOptionsForCombinedId(line.storeId);
              const reasonOptions = reasonStrings.map((r) => ({
                value: r,
                label: r,
              }));
              const selectedReasons = reasonOptions.filter((opt) =>
                line.reasons.includes(opt.value)
              );
              const isOtherSelected = line.reasons.includes('Other');

              return (
                <Box
                  key={lineIdx}
                  position="relative"
                  bg="white"
                  p={4}
                  borderRadius="md"
                  boxShadow="md"
                  borderLeftWidth="4px"
                  borderLeftColor="blue.400"
                >
                  <Text fontSize="sm" fontWeight="semibold" mb={2}>
                    Shift #{lineIdx + 1}
                  </Text>

                  {/* remove line */}
                  <IconButton
                    icon={<DeleteIcon />}
                    aria-label="Remove shift line"
                    size="sm"
                    variant="outline"
                    colorScheme="red"
                    position="absolute"
                    top="8px"
                    right="8px"
                    onClick={() => handleRemoveLine(lineIdx)}
                  />

                  <VStack align="stretch" spacing={3} mt={1}>
                    {/* store + reasons */}
                    <HStack align="flex-start" spacing={3} wrap="wrap">
                      <Box minW="200px">
                        <Text fontSize="xs" fontWeight="bold" mb={1}>
                          Store/Event
                        </Text>
                        <select
                          style={{
                            width: '100%',
                            padding: '6px',
                            borderRadius: '4px',
                            border: '1px solid #ccc',
                          }}
                          value={line.storeId}
                          onChange={(e) =>
                            handleLineChange(lineIdx, 'storeId', e.target.value)
                          }
                        >
                          <option value="">-- Select an option --</option>
                          {storeData.map((sd) => (
                            <option key={sd.store_id} value={sd.store_id}>
                              {sd.store_name || `Store#${sd.store_id}`}
                            </option>
                          ))}
                          <option value="Vacation">Vacation</option>
                          <option value="Office">Office</option>
                          <option value="Meetings">Meetings</option>
                        </select>
                      </Box>

                      {reasonOptions.length > 0 && (
                        <Box minW="200px">
                          <Text fontSize="xs" fontWeight="bold" mb={1}>
                            Reasons
                          </Text>
                          <Select
                            isMulti
                            options={reasonOptions}
                            value={selectedReasons}
                            onChange={(vals) => handleReasonsChange(lineIdx, vals)}
                            placeholder="Select reason(s)"
                          />
                        </Box>
                      )}
                    </HStack>

                    {/* start + end times */}
                    <HStack align="flex-start" spacing={3} wrap="wrap">
                      <Box>
                        <Text fontSize="xs" fontWeight="bold" mb={1}>
                          Start
                        </Text>
                        <input
                          type="datetime-local"
                          value={toDateTimeLocalString(line.startTime)}
                          onChange={(e) => handleDateTimeChange(lineIdx, 'startTime', e)}
                          style={{
                            width: '250px',
                            minWidth: '180px',
                            padding: '6px',
                            borderRadius: '4px',
                            border: '1px solid #ccc',
                          }}
                        />
                      </Box>

                      <Box>
                        <Text fontSize="xs" fontWeight="bold" mb={1}>
                          End
                        </Text>
                        <input
                          type="datetime-local"
                          value={toDateTimeLocalString(line.endTime)}
                          onChange={(e) => handleDateTimeChange(lineIdx, 'endTime', e)}
                          style={{
                            width: '250px',
                            minWidth: '180px',
                            padding: '6px',
                            borderRadius: '4px',
                            border: '1px solid #ccc',
                          }}
                        />
                      </Box>
                    </HStack>

                    {isOtherSelected && (
                      <Box>
                        <Text fontSize="xs" fontWeight="bold" mb={1}>
                          Other Reason
                        </Text>
                        <input
                          type="text"
                          value={line.reasonOther}
                          onChange={(e) => handleLineChange(lineIdx, 'reasonOther', e.target.value)}
                          style={{
                            width: '100%',
                            padding: '6px',
                            borderRadius: '4px',
                            border: '1px solid #ccc',
                          }}
                          placeholder="Describe your custom reason..."
                        />
                      </Box>
                    )}
                  </VStack>
                </Box>
              );
            })}

            <Button
              size="sm"
              leftIcon={<AddIcon />}
              onClick={handleAddLine}
              alignSelf="flex-end"
              colorScheme="blue"
            >
              Add Another Shift
            </Button>
          </VStack>

          {/* If lines invalid => show message */}
          {!allLinesValid && (
            <Box mt={3} color="orange.600" fontSize="sm">
              Please fill Store, Start & End times for each line.
            </Box>
          )}
        </ModalBody>

        <ModalFooter borderTopWidth="1px" borderColor="gray.200">
          {currentIndex === variantShifts.length - 1 ? (
            <Button
              colorScheme="blue"
              onClick={handleSubmitAll}
              isDisabled={!actualShiftsArr.every((arr) => arr.every(lineValid))}
            >
              Submit Shifts
            </Button>
          ) : (
            <Button
              colorScheme="blue"
              onClick={handleNext}
              isDisabled={!allLinesValid}
            >
              Next
            </Button>
          )}
          {/* No Cancel => blocking */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
