import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
} from "@chakra-ui/react";

import gradeKPI from "./GradeKPIs";

function PopsReportTable({ data, thresholds }) {
  console.log(
    "Rendering table with store names (in order):",
    data.map((d) => d.storeName)
  );

  const dataCellStyle = {
    padding: "6px",
    border: "1px solid #e2e8f0",
    fontSize: "sm",
    textAlign: "center",
  };

  const headerCellStyle = {
    padding: "6px",
    fontWeight: "bold",
    fontSize: "sm",
    backgroundColor: "#f0f0f0",
    border: "1px solid #e2e8f0",
    textAlign: "center",
  };

  if (!data || data.length === 0) {
    return (
      <Box p={4}>
        <strong>No data available for this selection.</strong>
      </Box>
    );
  }

  const safeString = (val) => (typeof val === "string" ? val : "N/A");

  return (
    <Box overflowX="auto">
      <TableContainer>
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th style={headerCellStyle}>Rank</Th>
              <Th style={headerCellStyle}>Store Name</Th>
              <Th style={headerCellStyle}>MTD Sales</Th>
              <Th style={headerCellStyle}>Sales % Change</Th>
              <Th style={headerCellStyle}>Cont. Expenses</Th>
              <Th style={headerCellStyle}>Crew Production</Th>
              <Th style={headerCellStyle}>Labor %</Th>
              <Th style={headerCellStyle}>Food Variance</Th>
              <Th style={headerCellStyle}>On Times %</Th>
              <Th style={headerCellStyle}>Average Time</Th>
              <Th style={headerCellStyle}>Reply Time</Th>
              <Th style={headerCellStyle}>Complaints/10k</Th>
              <Th style={headerCellStyle}>Cash</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row, idx) => {
              if (!thresholds || Object.keys(thresholds).length === 0) {
                // No color-coding if thresholds are not loaded
                return (
                  <Tr key={row.storeId || idx}>
                    <Td style={dataCellStyle}>{row.rank}</Td>
                    <Td style={dataCellStyle} textAlign="left">
                      {row.storeName}
                    </Td>
                    <Td style={dataCellStyle}>{row.MtdSales}</Td>
                    <Td style={dataCellStyle}>{row.SalesChange}</Td>
                    <Td style={dataCellStyle}>{row.ContExpenses}</Td>
                    <Td style={dataCellStyle}>{row.CrewProduction}</Td>
                    <Td style={dataCellStyle}>{row.LaborPercentage}</Td>
                    <Td style={dataCellStyle}>{row.FoodVarianceMonth}</Td>
                    <Td style={dataCellStyle}>{row.OnTimesPercentage}</Td>
                    <Td style={dataCellStyle}>{row.FormattedAveTime}</Td>
                    <Td style={dataCellStyle}>{row.FormattedReplyTime}</Td>
                    <Td style={dataCellStyle}>{row.ComplaintsPer10k}</Td>
                    <Td style={dataCellStyle}>{row.FormattedCash}</Td>
                  </Tr>
                );
              }

              // If we have thresholds, color-code by grading
              let displayedSalesChange = row.SalesChange;
              let numericSalesChangeForColor = row.SalesChange;
              if (row.SalesChange === "N/A") {
                const scVal = row.SalesChangeForGrading || 0;
                numericSalesChangeForColor = `${scVal.toFixed(2)}%`;
              }

              const safeContExpenses = safeString(row.ContExpenses);
              const safeCrewProduction = safeString(row.CrewProduction);
              const safeLaborPct = safeString(row.LaborPercentage);
              const safeFoodVar = safeString(row.FoodVarianceMonth);
              const safeOnTimes = safeString(row.OnTimesPercentage);
              const safeAveTime = safeString(row.FormattedAveTime);
              const safeReplyTime = safeString(row.FormattedReplyTime);
              const safeComplaints = safeString(row.ComplaintsPer10k);
              const safeCash = safeString(row.FormattedCash);

              const salesChangeColor = gradeKPI.SalesChange(
                numericSalesChangeForColor,
                thresholds
              ).color;
              const contExpensesColor = gradeKPI.ContExpenses(
                safeContExpenses,
                thresholds
              ).color;
              const crewProductionColor = gradeKPI.CrewProduction(
                safeCrewProduction,
                thresholds
              ).color;
              const laborPercentageColor = gradeKPI.LaborPercentage(
                safeLaborPct,
                thresholds
              ).color;
              const foodVarianceColor = gradeKPI.FoodVarianceMonth(
                safeFoodVar,
                thresholds
              ).color;
              const onTimesColor = gradeKPI.OnTimesPercentage(
                safeOnTimes,
                thresholds
              ).color;
              const aveTimeColor = gradeKPI.FormattedAveTime(
                safeAveTime,
                thresholds
              ).color;
              const replyTimeColor = gradeKPI.FormattedReplyTime(
                safeReplyTime,
                thresholds
              ).color;
              const complaintsColor = gradeKPI.ComplaintsPer10k(
                safeComplaints,
                thresholds
              ).color;
              const cashColor = gradeKPI.FormattedCash(safeCash, thresholds)
                .color;

              return (
                <Tr key={row.storeId || idx}>
                  <Td style={dataCellStyle}>{row.rank}</Td>
                  <Td style={dataCellStyle} textAlign="left">
                    {row.storeName}
                  </Td>
                  <Td style={dataCellStyle}>{row.MtdSales}</Td>
                  <Td
                    style={{
                      ...dataCellStyle,
                      backgroundColor: salesChangeColor,
                    }}
                  >
                    {displayedSalesChange}
                  </Td>
                  <Td
                    style={{
                      ...dataCellStyle,
                      backgroundColor: contExpensesColor,
                    }}
                  >
                    {safeContExpenses}
                  </Td>
                  <Td
                    style={{
                      ...dataCellStyle,
                      backgroundColor: crewProductionColor,
                    }}
                  >
                    {safeCrewProduction}
                  </Td>
                  <Td
                    style={{
                      ...dataCellStyle,
                      backgroundColor: laborPercentageColor,
                    }}
                  >
                    {safeLaborPct}
                  </Td>
                  <Td
                    style={{
                      ...dataCellStyle,
                      backgroundColor: foodVarianceColor,
                    }}
                  >
                    {safeFoodVar}
                  </Td>
                  <Td
                    style={{
                      ...dataCellStyle,
                      backgroundColor: onTimesColor,
                    }}
                  >
                    {safeOnTimes}
                  </Td>
                  <Td
                    style={{
                      ...dataCellStyle,
                      backgroundColor: aveTimeColor,
                    }}
                  >
                    {safeAveTime}
                  </Td>
                  <Td
                    style={{
                      ...dataCellStyle,
                      backgroundColor: replyTimeColor,
                    }}
                  >
                    {safeReplyTime}
                  </Td>
                  <Td
                    style={{
                      ...dataCellStyle,
                      backgroundColor: complaintsColor,
                    }}
                  >
                    {safeComplaints}
                  </Td>
                  <Td
                    style={{
                      ...dataCellStyle,
                      backgroundColor: cashColor,
                    }}
                  >
                    {safeCash}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default PopsReportTable;
