import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/react";
import ProlifiFieldMiniNav from "./ProlifiFieldMiniNav";

function ProlifiFieldLayout(userGroup) {
  // Toggle state: collapsed vs expanded
  const [navExpanded, setNavExpanded] = useState(false);


  return (
    <Flex
      direction="column"
      position="relative"
      width="99vw"
      height="auto"
      overflow="hidden"
      bg="white"
    >
      {/* The mini nav is absolutely positioned above main content. */}
      <Box
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height={navExpanded ? "30px" : "3px"}
        transition="height 0.2s ease-in-out"
        overflow="hidden"
        zIndex={10}   // <-- nav is on top
      >
        <ProlifiFieldMiniNav onExpand={setNavExpanded} userGroup={userGroup} /> />
      </Box>

      {/* Main content behind the mini nav. 
          Give it a lower z-index so it sits beneath the nav. */}
      <Box
        flex="1"
        //overflow="auto"
        position="relative"
        zIndex={1}
        ml="10px"
        maxH="99vh"
      >
        <Outlet />
      </Box>
    </Flex>
  );
}

export default ProlifiFieldLayout;
