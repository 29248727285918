// src/utils/timeFormatting.js

/**
 * Convert a native JS date/time into "YYYY-MM-DD HH:mm:ss" local time for MySQL.
 */
export function toMySqlDateTime(value) {
  const dateObj = new Date(value);
  // Subtract the timezone offset (minutes) => convert to local
  const localTime = new Date(
    dateObj.getTime() - dateObj.getTimezoneOffset() * 60000
  );
  return localTime.toISOString().slice(0, 19).replace('T', ' ');
}
