// src/components/FloatingEmblems.js
import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Box, Image } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { keyframes, css } from "@emotion/react";

import ptechp from "./images/ptech-p.png";
import ConfettiBurst from "./ConfettiBurst";

// 1) Keyframes for drifting
const driftKeyframes = keyframes`
  0%   { transform: translate(0,0); }
  25%  { transform: translate(-20px, -10px); }
  50%  { transform: translate(0, -20px); }
  75%  { transform: translate(20px, -10px); }
  100% { transform: translate(0,0); }
`;

// 2) Keyframes for popping
const popKeyframes = keyframes`
  0%   { transform: scale(1) rotate(0deg);   opacity: 1;   }
  50%  { transform: scale(1.7) rotate(180deg); opacity: 0.7;}
  100% { transform: scale(0.01) rotate(360deg); opacity: 0; }
`;

// 3) Keyframes for fade-in
const fadeInKeyframes = keyframes`
  0%   { opacity: 0; }
  100% { opacity: 1; }
`;

/**
 * Helper to create one random emblem object
 */
function createRandomEmblem(id) {
  return {
    id,
    left: Math.random() * 100,
    top: Math.random() * 100,
    duration: 4 + Math.random() * 6, // how long the drift cycle is
    delay: Math.random() * 3,       // how long before drifting starts
    scale: 0.4 + Math.random() * 0.6,
    popping: false,
  };
}

/**
 * EmblemBox - a styled version of Chakra's <Box>
 * We'll accept custom props (popping, duration, delay) for dynamic animations
 */
const EmblemBox = styled(Box)`
  position: absolute;
  pointer-events: auto;
  cursor: default;
  z-index: 2;
  left: ${({ left }) => left}%;
  top: ${({ top }) => top}%;

  ${({ scale }) => `transform: translate(-50%, -50%) scale(${scale});`}

  ${({ popping, duration, delay }) =>
    popping
      ? css`
          animation: ${popKeyframes} 0.6s forwards;
        `
      : css`
          /* Combine fade-in + drift */
          animation: ${fadeInKeyframes} 0.8s ease-in-out forwards,
            ${driftKeyframes} ${duration}s ease-in-out ${delay}s infinite;
        `}
`;

/**
 * FloatingEmblems: forwardRef => parent can call popAllEmblems
 */
const FloatingEmblems = forwardRef(function FloatingEmblems(
  { count = 8, onPop },
  ref
) {
  const [emblems, setEmblems] = useState(() => {
    const arr = [];
    for (let i = 0; i < count; i++) {
      arr.push(createRandomEmblem(i));
    }
    return arr;
  });

  const [confettiBursts, setConfettiBursts] = useState([]);

  // Expose "popAllEmblems" to parent
  useImperativeHandle(ref, () => ({
    popAllEmblems: () => {
      setEmblems((prev) =>
        prev.map((em) => {
          spawnConfetti(em);
          return { ...em, popping: true };
        })
      );
      // after 600ms => remove them entirely (so they won't respawn)
      setTimeout(() => {
        setEmblems([]);
      }, 600);
    },
  }));

  /**
   * Single pop => spawn confetti => remove old => add new
   */
  const handlePop = (emblem) => {
    spawnConfetti(emblem);
    // Mark popping => triggers pop anim
    setEmblems((prev) =>
      prev.map((item) => (item.id === emblem.id ? { ...item, popping: true } : item))
    );
    if (onPop) onPop();

    setTimeout(() => {
      setEmblems((prev) => {
        const filtered = prev.filter((x) => x.id !== emblem.id);
        const newId = prev.length ? Math.max(...prev.map((p) => p.id)) + 1 : 0;
        return [...filtered, createRandomEmblem(newId)];
      });
    }, 600);
  };

  // Create confetti at emblem's position
  const spawnConfetti = (emblem) => {
    setConfettiBursts((prev) => [
      ...prev,
      {
        id: Date.now() + Math.random(),
        left: emblem.left,
        top: emblem.top,
      },
    ]);
  };

  // Remove confetti after anim
  const handleConfettiComplete = (id) => {
    setConfettiBursts((prev) => prev.filter((cb) => cb.id !== id));
  };

  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      w="100vw"
      h="100vh"
      zIndex={1}
      cursor="none"
    >
      {/* Render all EmblemBox items */}
      {emblems.map((em) => (
        <EmblemBox
          key={em.id}
          popping={em.popping}
          duration={em.duration}
          delay={em.delay}
          scale={em.scale}
          left={em.left}
          top={em.top}
          onClick={() => handlePop(em)}
        >
          <Image src={ptechp} alt="emblem" />
        </EmblemBox>
      ))}

      {/* Render confetti bursts */}
      {confettiBursts.map((burst) => (
        <ConfettiBurst
          key={burst.id}
          x={`${burst.left}%`}
          y={`${burst.top}%`}
          onComplete={() => handleConfettiComplete(burst.id)}
        />
      ))}
    </Box>
  );
});

export default FloatingEmblems;
