import React, { useEffect, useState, useRef } from 'react';

export default function StoreHoursOverlay({
  resourceHoursMap,
  storeTotalHours,
  officeTotalHours,
  overallHours,
  nameToIdMap,
  categoryIdMap,
}) {
  const [rowOverlays, setRowOverlays] = useState([]);
  const overlayRef = useRef(null);

  function recalcPositions() {
    // 1) Find the resource-column area
    const resourceColumnWrap = document.querySelector('.e-resource-column-wrap');
    if (!resourceColumnWrap) {
      console.warn('[StoreHoursOverlay] Could not find .e-resource-column-wrap!');
      setRowOverlays([]);
      return;
    }

    // 2) Find the "e-table-wrap" which usually has "e-timeline-day-view" or "e-timeline-week-view"
    const scheduleElem = document.querySelector('.e-schedule');
    let isDayView = false;
    let isWeekView = false;
    if (scheduleElem) {
      // We expect something like: <div class="e-table-wrap e-timeline-day-view ...">
      const tableWrap = scheduleElem.querySelector('.e-table-wrap');
      if (tableWrap) {
        isDayView = tableWrap.classList.contains('e-timeline-day-view');
        isWeekView = tableWrap.classList.contains('e-timeline-week-view');
      }
    }

    // 3) Apply offsets based on the detected view
    let verticalOffsetBase = 90; // your default
    if (isDayView) {
      console.log('Detected: TIMELINE DAY view');
      verticalOffsetBase = 125; // smaller offset for day, for example
    } else if (isWeekView) {
      console.log('Detected: TIMELINE WEEK view');
      verticalOffsetBase = 90; // or something else you want
    }

    const containerRect = resourceColumnWrap.getBoundingClientRect();

    // 4) Grab all possible row elements
    const potentialRows = resourceColumnWrap.querySelectorAll(`
      .e-resource-cells.e-child-node,
      .e-resource-cells.e-parent-node,
      .e-resource-cells.e-child-node.e-resource-leaf
    `);

    const rowArray = Array.from(potentialRows);
    const uniqueElems = Array.from(new Set(rowArray));

    const newOverlays = [];
    uniqueElems.forEach((rowElem) => {
      const textDiv = rowElem.querySelector('.e-resource-text');
      if (!textDiv) return;

      const resourceText = textDiv.textContent.trim();
      if (!resourceText) return;

      // Convert resourceText => CombinedId
      const combinedId = nameToIdMap[resourceText];
      if (!combinedId) return;

      const hoursValue = resourceHoursMap[combinedId] ?? 0;
      const cat = categoryIdMap[combinedId] || 0;

      const rowRect = rowElem.getBoundingClientRect();
      // Use dynamic offset
      const offsetTop = rowRect.top - containerRect.top + verticalOffsetBase;
      const offsetLeft = rowRect.left - containerRect.left + 6;

      newOverlays.push({
        combinedId,
        resourceText,
        cat,
        hours: hoursValue,
        top: offsetTop,
        left: offsetLeft,
      });
    });

    setRowOverlays(newOverlays);
  }

  useEffect(() => {
    recalcPositions();

    window.addEventListener('resize', recalcPositions);
    const resourceColumnWrap = document.querySelector('.e-resource-column-wrap');
    if (resourceColumnWrap) {
      resourceColumnWrap.addEventListener('scroll', recalcPositions, { passive: true });
    }

    return () => {
      window.removeEventListener('resize', recalcPositions);
      if (resourceColumnWrap) {
        resourceColumnWrap.removeEventListener('scroll', recalcPositions);
      }
    };
  }, [
    resourceHoursMap,
    storeTotalHours,
    officeTotalHours,
    overallHours,
    nameToIdMap,
    categoryIdMap,
  ]);

  return (
    <div
      ref={overlayRef}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
        zIndex: 10,
      }}
    >
      {rowOverlays.map((o) => {
        // default color
        let textColor = '#333';
        let borderColor = '#eee';
        let boxShadow = '0 1px 3px rgba(0,0,0,0.1)';

        // Condition 1: If it's a store => cat=1 => hours <4 => red
        if (o.cat === 1 && o.hours < 4) {
          textColor = 'red';
        }

        // Condition 2: If resourceText === 'Office', check ratio => red if >=10%
        else if (o.resourceText === 'Office') {
          if (storeTotalHours > 0) {
            const ratio = officeTotalHours / storeTotalHours;
            if (ratio >= 0.1) {
              textColor = 'red';
            }
          }
        }

        // If our text is red, let's also give a slight red glow
        if (textColor === 'red') {
          borderColor = 'red';
          boxShadow = '0 0 6px 2px rgba(255,0,0,0.3)';
        }

        return (
          <div
            key={o.combinedId}
            style={{
              position: 'absolute',
              top: o.top,
              left: o.left,
              backgroundColor: 'rgba(248,248,248,0.35)',
              color: textColor,
              padding: '2px 6px',
              fontSize: '0.75em',
              border: `1px solid ${borderColor}`,
              borderRadius: 3,
              boxShadow,
            }}
          >
            {o.hours} hrs
          </div>
        );
      })}
    </div>
  );
}
