// src/components/WeeklyServiceTable.js
import React, { useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  useColorModeValue,
  Icon,
} from "@chakra-ui/react";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";

function convertSecondsToMinutes(secondsStr) {
  const totalSeconds = Math.floor(parseFloat(secondsStr));
  if (isNaN(totalSeconds)) {
    return "N/A";
  }
  const minutes = Math.floor(totalSeconds / 60);
  const remainingSeconds = totalSeconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
}

function calculateOnTimePercentage(data) {
  const ontimeTickets = parseFloat(data.currentWeek.ontimeTickets) || 0;
  const totalTickets = parseFloat(data.currentWeek.tickets) || 0;
  return totalTickets > 0 ? (ontimeTickets / totalTickets) * 100 : 0;
}

export function WeeklyServiceTable({ weeklyServiceData }) {
  // 1) Declare Hooks at the top, before any `if` returns.
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const containerBg = useColorModeValue("gray.50", "gray.800");

  // 2) Now do the early return, if needed.
  if (!weeklyServiceData) {
    return <Text>No service data available.</Text>;
  }

  // 3) The rest of your logic remains unchanged.
  const allData = Object.entries(weeklyServiceData).map(([storeId, data]) => {
    const onTime = calculateOnTimePercentage(data);
    return {
      supervisorName: data.supervisorName,
      storeName: data.storeName,
      onTimePct: onTime,
      overallAveTime: data.service.overallAveTime,
      dtAveTime: data.service.dtAveTime,
      stallAveTime: data.service.stallAveTime,
      orderAheadAveTime: data.service.orderAheadAveTime,
      replyTime: data.service.replyTime,
    };
  });

  const headers = [
    { key: "supervisorName", label: "Supervisor" },
    { key: "storeName", label: "Store" },
    { key: "onTimePct", label: "On Times" },
    { key: "overallAveTime", label: "Ave. Time - Overall" },
    { key: "dtAveTime", label: "Ave. Time - DT" },
    { key: "stallAveTime", label: "Ave. Time - Stall" },
    { key: "orderAheadAveTime", label: "Ave. Time - OA" },
    { key: "replyTime", label: "Reply Time" },
  ];

  const handleSort = (key) => {
    if (sortConfig.key === key) {
      setSortConfig({
        key,
        direction: sortConfig.direction === "asc" ? "desc" : "asc",
      });
    } else {
      setSortConfig({ key, direction: "asc" });
    }
  };

  const parseIfNumeric = (val) => {
    if (val === "N/A") return Number.NaN;
    const parsed = parseFloat(val);
    return isNaN(parsed) ? val : parsed;
  };

  const parseTime = (timeStr) => {
    if (!timeStr || timeStr === "N/A") return Number.NaN;
    const [mins, secs] = timeStr.split(":").map(Number);
    if (isNaN(mins) || isNaN(secs)) return Number.NaN;
    return mins * 60 + secs;
  };

  const sortedData = [...allData].sort((a, b) => {
    const { key, direction } = sortConfig;
    if (!key) return 0;

    let valA, valB;
    if (
      key === "overallAveTime" ||
      key === "dtAveTime" ||
      key === "stallAveTime" ||
      key === "orderAheadAveTime" ||
      key === "replyTime"
    ) {
      // Convert "MM:SS" to total seconds
      valA = parseTime(a[key]);
      valB = parseTime(b[key]);
    } else {
      // Parse numeric or "N/A"
      valA = parseIfNumeric(a[key]);
      valB = parseIfNumeric(b[key]);
    }

    // Numeric sorting
    if (typeof valA === "number" && typeof valB === "number") {
      if (isNaN(valA)) return 1; // push "N/A" down
      if (isNaN(valB)) return -1; // push "N/A" down
      return direction === "asc" ? valA - valB : valB - valA;
    }
    // String sorting
    if (typeof valA === "string" && typeof valB === "string") {
      return direction === "asc"
        ? valA.localeCompare(valB)
        : valB.localeCompare(valA);
    }
    return 0;
  });

  const renderSortIcon = (colKey) => {
    if (sortConfig.key !== colKey) {
      return <FaSort />;
    }
    return sortConfig.direction === "asc" ? <FaSortUp /> : <FaSortDown />;
  };

  return (
    <Box w="100%" maxW="100%" overflowX="auto" bg={containerBg} p={2}>
      <TableContainer maxW="100%" overflowX="auto">
        <Table variant="striped" colorScheme="pink" size="md" minW="900px">
          <Thead>
            <Tr>
              {headers.map((h) => (
                <Th
                  key={h.key}
                  onClick={() => handleSort(h.key)}
                  cursor="pointer"
                  whiteSpace="nowrap"
                >
                  {h.label} <Icon as={() => renderSortIcon(h.key)} ml={1} />
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {sortedData.map((row, idx) => (
              <Tr key={idx}>
                <Td>{row.supervisorName}</Td>
                <Td>{row.storeName}</Td>
                <Td>{row.onTimePct.toFixed(2) + "%"}</Td>
                <Td>{convertSecondsToMinutes(row.overallAveTime)}</Td>
                <Td>{convertSecondsToMinutes(row.dtAveTime)}</Td>
                <Td>{convertSecondsToMinutes(row.stallAveTime)}</Td>
                <Td>{convertSecondsToMinutes(row.orderAheadAveTime)}</Td>
                <Td>{convertSecondsToMinutes(row.replyTime)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}
