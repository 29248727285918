// src/components/SalesDataChecker.js

import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  format as formatFns,
  startOfISOWeek,
  endOfISOWeek,
  getISODay,
} from "date-fns";

// Chakra UI (v2) imports
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tooltip,
  Divider,
  useColorModeValue,
} from "@chakra-ui/react";

// Icons from react-icons (replacements for MUI icons)
import { MdReportProblem, MdTrendingUp } from "react-icons/md";

const SalesDataChecker = () => {
  const [dateRange, setDateRange] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [supervisedStores, setSupervisedStores] = useState([]);
  const [storeSalesData, setStoreSalesData] = useState({});
  const [expandedStores, setExpandedStores] = useState(new Set());

  // 1) Define your color values at the top level
  const accordionBorderColor = useColorModeValue("gray.200", "gray.700");
  const accordionExpandedBg = useColorModeValue("gray.100", "gray.600");

  useEffect(() => {
    let today = new Date();
    // If it's Monday, show the previous ISO week
    if (getISODay(today) === 1) {
      today.setDate(today.getDate() - 7);
    }
    const start = startOfISOWeek(today);
    const end = endOfISOWeek(today);

    const formattedRange = `${formatFns(start, "MMM dd")} - ${formatFns(end, "MMM dd, yyyy")}`;
    const formattedStart = formatFns(start, "yyyy-MM-dd");
    const formattedEnd = formatFns(end, "yyyy-MM-dd");

    setDateRange(formattedRange);
    setStartDate(formattedStart);
    setEndDate(formattedEnd);

    fetchSupervisedStores();
  }, []);

  useEffect(() => {
    // Once we have startDate, endDate, and the supervisedStores list,
    // fetch the sales data for each store
    if (startDate && endDate && supervisedStores.length > 0) {
      fetchSalesData(supervisedStores);
    }
  }, [startDate, endDate, supervisedStores]);

  const getBaseUrl = () => {
    return process.env.NODE_ENV === "development"
      ? "http://72.167.34.236:5000"
      : "https://prolifi.app";
  };

  const fetchSupervisedStores = async () => {
    try {
      const baseUrl = getBaseUrl();
      const response = await axios.get(`${baseUrl}/api/supervised-stores`, {
        withCredentials: true,
      });
      setSupervisedStores(response.data);
    } catch (error) {
      console.error("Error fetching supervised stores:", error);
    }
  };

  const fetchSalesData = async (stores) => {
    const baseUrl = getBaseUrl();
    const data = {};

    for (let store of stores) {
      try {
        const url = `${baseUrl}/api/sales-data/${store}/${startDate}/${endDate}`;
        const response = await axios.get(url, {
          withCredentials: true,
        });
        if (
          response.data &&
          response.data.dailyData &&
          response.data.dailyData.length > 0 &&
          !response.data.error
        ) {
          data[store] = response.data;
        }
      } catch (error) {
        console.error(`Error fetching data for store ${store}:`, error);
      }
    }
    setStoreSalesData(data);
    console.log(data);
  };

  const toggleStore = (storeId) => {
    const newSet = new Set(expandedStores);
    if (newSet.has(storeId)) {
      newSet.delete(storeId);
    } else {
      newSet.add(storeId);
    }
    setExpandedStores(newSet);
  };

  // Helper function to get an array of dates in 'yyyy-MM-dd' between two dates
  const getDatesInRange = (startDateStr, endDateStr) => {
    const start = new Date(startDateStr);
    const end = new Date(endDateStr);

    const dates = [];
    for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
      dates.push(d.toISOString().split("T")[0]);
    }
    return dates;
  };

  // We rename this to avoid overwriting date-fns format
  const formatDateToYMD = (dateObj) => {
    return dateObj.toISOString().split("T")[0];
  };

  const findMissingDates = (dailyData, startDateStr, endDateStr) => {
    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0); // Start of today

    let adjustedEndDate = new Date(endDateStr);
    if (adjustedEndDate > todayDate) {
      // If endDate is today or in future, set endDate to yesterday
      adjustedEndDate = new Date(todayDate);
      adjustedEndDate.setDate(adjustedEndDate.getDate() - 1);
    }

    const allDates = getDatesInRange(
      startDateStr,
      formatDateToYMD(adjustedEndDate)
    ).filter((date) => {
      const dateToCompare = new Date(date);
      dateToCompare.setHours(0, 0, 0, 0);
      return dateToCompare < todayDate;
    });

    const storeDates = dailyData.map((data) =>
      formatDateToYMD(new Date(data.date))
    );
    const missingDates = allDates.filter((date) => !storeDates.includes(date));
    return missingDates;
  };

  // Group consecutive dates into ranges (for missing data)
  const groupConsecutiveDates = (dates) => {
    if (!dates.length) return [];
    const sortedDates = dates.sort((a, b) => new Date(a) - new Date(b));

    let ranges = [];
    let rangeStart = new Date(sortedDates[0]);
    let rangeEnd = new Date(sortedDates[0]);

    for (let i = 1; i < sortedDates.length; i++) {
      const currentDate = new Date(sortedDates[i]);
      const nextDay = new Date(rangeEnd);
      nextDay.setDate(nextDay.getDate() + 1);

      if (+nextDay === +currentDate) {
        // Extend the range
        rangeEnd = currentDate;
      } else {
        ranges.push(formatRange(rangeStart, rangeEnd));
        rangeStart = currentDate;
        rangeEnd = currentDate;
      }
    }
    // Push the final range
    ranges.push(formatRange(rangeStart, rangeEnd));
    return ranges;
  };

  // Format a start/end date range (or single date)
  const formatRange = (start, end) => {
    const startStr = formatFns(start, "MMM d");
    const endStr = formatFns(end, "MMM d, yyyy");
    if (startStr === endStr) {
      return startStr; // Single date
    }
    return `${startStr} - ${endStr}`;
  };

  const formatSecondsToMinSec = (seconds) => {
    if (isNaN(seconds) || seconds == null) {
      return "N/A";
    }
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  return (
    <Box px={4} py={6} width="100%">
      {/* Title and date range */}
      <Box mb={4} textAlign="center">
        <Heading as="h2" size="lg" mb={2}>
          ProlifiData
        </Heading>
        <Text fontSize="md" color="gray.500">
          {dateRange}
        </Text>
      </Box>

      {/* If we have supervised stores, map them */}
      {supervisedStores.length > 0 ? (
        supervisedStores.map((storeId) => {
          const storeData = storeSalesData[storeId] ?? { dailyData: [] };

          if (storeData.error) {
            return (
              <Box key={storeId} color="red.500" mb={4}>
                Store {storeId} has an error loading data.
              </Box>
            );
          }

          const missingDates = findMissingDates(
            storeData.dailyData,
            startDate,
            endDate
          );
          const missingDateRanges = groupConsecutiveDates(
            missingDates.map((d) => new Date(d))
          );

          if (!storeData) {
            return (
              <Box key={storeId} mb={4}>
                Loading data for store #{storeId}...
              </Box>
            );
          }

          // aggregatedData might have totals
          const aggregatedData = storeData.aggregatedData ?? {};
          const aggregateSales = Number(aggregatedData.totalNetsales) || 0;
          const aggregateOntime =
            Number(
              (aggregatedData.totOntime / aggregatedData.totalTickets) * 100
            ) || 0;
          const aggregateTickets = Number(aggregatedData.totalTickets) || 0;

          const isExpanded = expandedStores.has(storeId);

          return (
            <Accordion
              key={storeId}
              allowMultiple
              mb={4}
              border="1px solid"
              borderColor={accordionBorderColor}
              borderRadius="md"
              width="100%"
            >
              <AccordionItem>
                <h2>
                  <AccordionButton
                    onClick={() => toggleStore(storeId)}
                    _expanded={{ bg: accordionExpandedBg }}
                  >
                    <Box
                      flex="1"
                      textAlign="left"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      {/* Store Title and aggregated info */}
                      <Box>
                        <Text fontWeight="bold" fontSize="md">
                          Store #{storeId}
                        </Text>
                        {/* Aggregated data row */}
                        <Box
                          display="flex"
                          alignItems="center"
                          mt={1}
                          flexWrap="wrap"
                        >
                          <Box display="flex" alignItems="center" mr={4}>
                            <MdTrendingUp size="18" />
                            <Text ml={1} fontSize="sm">
                              <strong>Net Sales:</strong>{" "}
                              {new Intl.NumberFormat("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 2,
                              }).format(aggregateSales)}
                            </Text>
                          </Box>
                          <Box display="flex" alignItems="center" mr={4}>
                            <Text fontSize="sm">
                              <strong>On Time:</strong>{" "}
                              {aggregateOntime.toFixed(2)}
                            </Text>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Text fontSize="sm">
                              <strong>Tickets:</strong>{" "}
                              {aggregateTickets.toFixed(0)}
                            </Text>
                          </Box>
                        </Box>
                      </Box>

                      {/* Missing date warning if missing ranges exist */}
                      {missingDateRanges.length > 0 && (
                        <Tooltip
                          label={`Sales data is missing for: ${missingDateRanges.join(
                            ", "
                          )}`}
                          placement="top"
                          hasArrow
                        >
                          <Box color="red.500">
                            <MdReportProblem size="20" />
                          </Box>
                        </Tooltip>
                      )}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>

                <AccordionPanel pb={4}>
                  {/* Aggregated Data Table (single row) */}
                  <Box overflowX="auto" mb={4} width="100%">
                    <Table variant="striped" size="sm">
                      <Thead>
                        <Tr>
                          <Th textAlign="right" fontWeight="bold">
                            Net Sales
                          </Th>
                          <Th textAlign="right" fontWeight="bold">
                            Tickets
                          </Th>
                          <Th textAlign="right" fontWeight="bold">
                            Average Time ALL
                          </Th>
                          <Th textAlign="right" fontWeight="bold">
                            Average Time STALL
                          </Th>
                          <Th textAlign="right" fontWeight="bold">
                            Average Time DT
                          </Th>
                          <Th textAlign="right" fontWeight="bold">
                            Average Time OA
                          </Th>
                          <Th textAlign="right" fontWeight="bold">
                            Average Reply Time
                          </Th>
                          <Th textAlign="right" fontWeight="bold">
                            On Time
                          </Th>
                          <Th textAlign="right" fontWeight="bold">
                            Labor
                          </Th>
                          <Th textAlign="right" fontWeight="bold">
                            Over/Short
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td textAlign="right">
                            {aggregatedData &&
                            !isNaN(aggregatedData.totalNetsales)
                              ? new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                }).format(aggregatedData.totalNetsales)
                              : "N/A"}
                          </Td>
                          <Td textAlign="right">
                            {aggregatedData &&
                            !isNaN(aggregatedData.totalTickets)
                              ? (+aggregatedData.totalTickets).toFixed(2)
                              : "N/A"}
                          </Td>
                          <Td textAlign="right">
                            {aggregatedData
                              ? formatSecondsToMinSec(
                                  aggregatedData.weightedAve_timeALL
                                )
                              : "N/A"}
                          </Td>
                          <Td textAlign="right">
                            {aggregatedData
                              ? formatSecondsToMinSec(
                                  aggregatedData.weightedAve_timeSTALL
                                )
                              : "N/A"}
                          </Td>
                          <Td textAlign="right">
                            {aggregatedData
                              ? formatSecondsToMinSec(
                                  aggregatedData.weightedAve_timeDT
                                )
                              : "N/A"}
                          </Td>
                          <Td textAlign="right">
                            {aggregatedData
                              ? formatSecondsToMinSec(
                                  aggregatedData.weightedAve_timeOA
                                )
                              : "N/A"}
                          </Td>
                          <Td textAlign="right">
                            {aggregatedData
                              ? formatSecondsToMinSec(
                                  aggregatedData.weightedAve_timeReplyTime
                                )
                              : "N/A"}
                          </Td>
                          <Td textAlign="right">
                            {aggregateOntime.toFixed(2)}
                          </Td>
                          <Td textAlign="right">
                            {aggregatedData &&
                            !isNaN(aggregatedData.totalLabor) &&
                            !isNaN(aggregatedData.totalNetsales) &&
                            +aggregatedData.totalNetsales !== 0
                              ? (
                                  (aggregatedData.totalLabor /
                                    aggregatedData.totalNetsales) *
                                  100
                                ).toFixed(2)
                              : "N/A"}
                          </Td>
                          <Td textAlign="right">
                            {aggregatedData &&
                            !isNaN(aggregatedData.totalOvershort)
                              ? (+aggregatedData.totalOvershort).toFixed(2)
                              : "N/A"}
                          </Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Box>

                  <Divider mb={4} />

                  {/* If expanded, show daily data table */}
                  {isExpanded && storeData.dailyData && (
                    <Box overflowX="auto" width="100%">
                      <Table size="sm" variant="simple">
                        <Thead>
                          <Tr>
                            <Th></Th>
                            {storeData.dailyData.map((dayData) => (
                              <Th
                                key={dayData.date}
                                textAlign="right"
                                fontWeight="bold"
                              >
                                {new Date(dayData.date)
                                  .toISOString()
                                  .split("T")[0]}
                              </Th>
                            ))}
                          </Tr>
                        </Thead>
                        <Tbody>
                          <Tr>
                            <Td fontWeight="bold" textAlign="right">
                              Net Sales
                            </Td>
                            {storeData.dailyData.map((dayData) => (
                              <Td key={dayData.date} textAlign="right">
                                {dayData.netsales}
                              </Td>
                            ))}
                          </Tr>
                          <Tr>
                            <Td fontWeight="bold" textAlign="right">
                              Average Time(ALL)
                            </Td>
                            {storeData.dailyData.map((dayData) => (
                              <Td key={dayData.date} textAlign="right">
                                {formatSecondsToMinSec(dayData.ave_timeALL)}
                              </Td>
                            ))}
                          </Tr>
                          {/* Additional rows for other metrics... */}
                          <Tr>
                            <Td fontWeight="bold" textAlign="right">
                              On Time (%)
                            </Td>
                            {storeData.dailyData.map((dayData) => (
                              <Td key={dayData.date} textAlign="right">
                                {!isNaN(dayData.ontime) &&
                                !isNaN(dayData.tickets) &&
                                +dayData.tickets !== 0
                                  ? (
                                      (dayData.ontime / dayData.tickets) *
                                      100
                                    ).toFixed(2)
                                  : "N/A"}
                              </Td>
                            ))}
                          </Tr>
                          <Tr>
                            <Td fontWeight="bold" textAlign="right">
                              Labor (%)
                            </Td>
                            {storeData.dailyData.map((dayData) => (
                              <Td key={dayData.date} textAlign="right">
                                {!isNaN(dayData.labor) &&
                                !isNaN(dayData.netsales) &&
                                +dayData.netsales !== 0
                                  ? (
                                      (dayData.labor / dayData.netsales) *
                                      100
                                    ).toFixed(2)
                                  : "N/A"}
                              </Td>
                            ))}
                          </Tr>
                        </Tbody>
                      </Table>
                    </Box>
                  )}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          );
        })
      ) : (
        <Text mt={4}>No supervised stores found.</Text>
      )}
    </Box>
  );
};

export default SalesDataChecker;
