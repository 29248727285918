// src/utils/scheduleHelpers.js

// Category data for the ejs Schedule "resources".
export const categoryData = [
  { CategoryId: 1, CategoryName: 'Stores' },
  { CategoryId: 2, CategoryName: 'Static Items' },
];

/**
 * Build the combined resource array from storeData + static items
 * so they can all appear on the timeline schedule.
 */
export function buildCombinedResource(storeData) {
  if (!Array.isArray(storeData)) return [];

  const storeResources = storeData.map((store) => ({
    CategoryId: 1,
    CombinedId: String(store.store_id),
    text: store.store_name,
    color: '#2196f3',
  }));

  // Example static items. Adjust as needed for your application.
  const staticItems = [
    { CategoryId: 2, CombinedId: 'Office', text: 'Office', color: '#FF9800' },
    { CategoryId: 2, CombinedId: 'Meetings', text: 'Meetings', color: '#4CAF50' },
    { CategoryId: 2, CombinedId: 'Vacation', text: 'Vacation', color: '#9C27B0' },
  ];

  return [...storeResources, ...staticItems];
}
