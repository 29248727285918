// src/components/MultiStoreModal.js
import React, { useEffect } from 'react';

// --- CHAKRA IMPORTS ---
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
  Text,
  FormControl,
  FormLabel,
  Select,
  Button,
  Grid,
  GridItem,
  Divider,
} from '@chakra-ui/react';

const MultiStoreModal = ({
  needsAssignment,
  singleStoreData,
  onAssign,
  assignments,
  setAssignments,
  onClose,
}) => {
  useEffect(() => {
    // console.log('MultiStoreModal received needsAssignment:', needsAssignment);
  }, [needsAssignment]);

  const handleSelectChange = (event, identifier) => {
    const { value } = event.target;
    setAssignments((prev) => ({
      ...prev,
      [identifier]: value,
    }));
  };

  const handleSubmit = () => {
    const updatedSingleStoreData = { ...singleStoreData };
    const assignmentData = {};
    const assignedStoreData = [];

    for (const identifier in assignments) {
      const store = assignments[identifier];

      // Find the matching entry(ies) in needsAssignment
      const entries = needsAssignment.filter(
        (entry) => entry.identifier === identifier
      );

      entries.forEach((entry) => {
        if (!updatedSingleStoreData[store]) {
          updatedSingleStoreData[store] = [];
        }
        // Add homeStore property to each product entry
        const entryWithHomeStore = {
          ...entry,
          products: entry.products.map((product) => ({
            ...product,
            homeStore: store,
          })),
        };
        updatedSingleStoreData[store].push(entryWithHomeStore);

        if (!assignmentData[store]) {
          assignmentData[store] = [];
        }
        assignmentData[store].push({
          name: `${entry.FN} ${entry.LN}`,
          product: entry.product,
          identifier: entry.identifier,
          totDue: entry.totDue,
        });

        // Add to assignedStoreData
        assignedStoreData.push({
          employeeName: `${entry.FN} ${entry.LN}`,
          assignedStore: store,
        });
      });
    }

    console.log('Updated Single Store Data (in modal):', updatedSingleStoreData);
    console.log('Assignment Data:', assignmentData);
    console.log('Assigned Store Data:', assignedStoreData);

    onAssign(updatedSingleStoreData, {}, assignmentData, assignedStoreData);
  };

  // Create a unique list of employees based on identifier
  const uniqueEmployees = {};
  needsAssignment.forEach((entry) => {
    if (!uniqueEmployees[entry.identifier]) {
      uniqueEmployees[entry.identifier] = entry;
    }
  });

  // Convert the uniqueEmployees object into an array for easier mapping
  const employeeArray = Object.values(uniqueEmployees);

  return (
    <Modal isOpen={true} onClose={onClose} isCentered>
      {/* A blurred overlay gives a modern feel */}
      <ModalOverlay bg="blackAlpha.600" backdropFilter="blur(8px)" />
      
      <ModalContent borderRadius="md" boxShadow="xl" overflow="hidden">
        {/* A subtle gradient in the header for a professional look */}
        <ModalHeader
          bgGradient="linear(to-r, blue.600, purple.500)"
          color="white"
          py={4}
        >
          <Text fontSize="lg" fontWeight="bold">
            Assign Employees to Store or EGM
          </Text>
        </ModalHeader>
        <ModalCloseButton color="white" _hover={{ bg: 'blackAlpha.300' }} />

        {/* Body with a light background color for contrast */}
        <ModalBody p={6} bg="gray.50">
          {employeeArray.map((entry, index) => (
            <Box key={entry.identifier}>
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={2}
                // Give each row enough vertical space
                minH="50px"
                mb={2}
              >
                {/* Column for Employee Name, centered vertically */}
                <GridItem alignSelf="center">
                  <Text fontWeight="semibold">
                    {entry.FN} {entry.LN}
                  </Text>
                </GridItem>

                {/* Column for Assign To label and dropdown, aligned to top */}
                <GridItem alignSelf="start">
                  <FormControl>
                    <FormLabel fontSize="sm" mb={1}>
                      Assign to
                    </FormLabel>
                    <Select
                      placeholder="Select store or EGM"
                      value={assignments[entry.identifier] || ''}
                      onChange={(e) => handleSelectChange(e, entry.identifier)}
                      variant="outline"
                      size="sm"
                    >
                      {entry.stores &&
                        entry.stores.map((storeEntry) => (
                          <option
                            key={`${entry.identifier}-${storeEntry.store}`}
                            value={storeEntry.store}
                          >
                            {storeEntry.storeName} - {storeEntry.department}
                          </option>
                        ))}
                      <option value="EGM">EGM</option>
                    </Select>
                  </FormControl>
                </GridItem>
              </Grid>

              {/* Insert a divider after each employee except the last one */}
              {index < employeeArray.length - 1 && <Divider mb={0.5} />}
            </Box>
          ))}
        </ModalBody>

        {/* Footer with a divider on top for separation */}
        <ModalFooter bg="gray.50" borderTopWidth="1px">
          <Button variant="ghost" onClick={onClose} mr={3}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MultiStoreModal;
