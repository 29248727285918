import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from 'react';
import axios from 'axios';

import CustomModal from './CustomModal';
import ShiftTooltip from './ShiftTooltip';
import EJSScheduler from './EJSScheduler';
import StoreHoursOverlay from './StoreHoursOverlay';

import { getBaseUrl } from '../../../../utils/getBaseUrl';
import { toMySqlDateTime } from '../../../../utils/timeFormatting';
import { validateShift } from '../../../../utils/shiftValidation';  // <--- your existing validation
import { categoryData, buildCombinedResource } from '../../../../utils/scheduleHelpers';

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    minHeight: '62vh',
    position: 'relative',
  },
};

const ScheduleSection = forwardRef(function ScheduleSection(props, ref) {
  const {
    storeData,
    events,
    setEvents,
    shiftSuggestions = {},
    onDateRangeChange,
    startDate,
    endDate,
    onEventsChanged,
    fetchEventsForRange,
    onAggregatorChanged,
    // The aggregator-based map from the container: { <combinedId>: <weekHours>, ... }
    storeHoursMap,
  } = props;

  const scheduleRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(new Date());

  // SHIFT copy/paste
  const [copiedEvent, setCopiedEvent] = useState(null);
  const hoveredShiftRef = useRef(null);
  const hoveredCellRef = useRef(null);

  // SHIFT modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentEventData, setCurrentEventData] = useState(null);

  // 1) Build resources from storeData
  const combinedResource = useMemo(() => buildCombinedResource(storeData), [storeData]);
  const combinedOptions = useMemo(
    () => combinedResource.map((item) => ({ id: item.CombinedId, text: item.text })),
    [combinedResource]
  );

  // aggregator => local day-based sums
  const [resourceHoursMap, setResourceHoursMap] = useState({});
  const [storeTotalHours, setStoreTotalHours] = useState(0);
  const [officeTotalHours, setOfficeTotalHours] = useState(0);
  const [overallHours, setOverallHours] = useState(0);

  // Build stable maps with useMemo
  const textMap = useMemo(() => {
    const map = {};
    combinedResource.forEach((res) => {
      map[res.CombinedId] = res.text;
    });
    return map;
  }, [combinedResource]);

  const categoryIdMap = useMemo(() => {
    const map = {};
    combinedResource.forEach((res) => {
      map[res.CombinedId] = res.CategoryId;
    });
    return map;
  }, [combinedResource]);

  const nameToIdMap = useMemo(() => {
    const acc = {};
    combinedResource.forEach((r) => {
      if (r.text) acc[r.text] = r.CombinedId;
    });
    return acc;
  }, [combinedResource]);

  // Provide imperatives: switchView, refresh, setSelectedDate
  useImperativeHandle(ref, () => ({
    switchView(newView) {
      if (scheduleRef.current) {
        scheduleRef.current.currentView = newView;
        scheduleRef.current.dataBind();
      }
    },
    refresh() {
      scheduleRef.current?.refreshEvents();
      scheduleRef.current?.dataBind();
    },
    setSelectedDate(date) {
      if (scheduleRef.current) {
        scheduleRef.current.selectedDate = date;
        scheduleRef.current.dataBind();
      }
    },
  }));

  // If we are in Month view => auto-set the parent's date range once we know the full month
  useEffect(() => {
    if (!scheduleRef.current) return;
    if (scheduleRef.current.currentView === 'Month') {
      setTimeout(() => {
        const viewDates = scheduleRef.current.getCurrentViewDates();
        if (viewDates?.length) {
          const earliest = new Date(viewDates[0]);
          earliest.setHours(0, 0, 0, 0);
          const latest = new Date(viewDates[viewDates.length - 1]);
          latest.setHours(23, 59, 59, 999);
          onDateRangeChange?.(earliest.toISOString(), latest.toISOString(), 'Month');
        }
      }, 0);
    }
  }, [onDateRangeChange]);

  // If parent hasn't set start/end, we set them from the schedule's visible date range
  useEffect(() => {
    if (!scheduleRef.current) return;
    if (!startDate || !endDate) {
      const viewDates = scheduleRef.current.getCurrentViewDates();
      if (viewDates?.length) {
        const earliest = new Date(viewDates[0]);
        earliest.setHours(0, 0, 0, 0);
        const latest = new Date(viewDates[viewDates.length - 1]);
        latest.setHours(23, 59, 59, 999);
        onDateRangeChange?.(earliest.toISOString(), latest.toISOString());
      }
    }
  }, [onDateRangeChange, startDate, endDate]);

  /**
   *  Keyboard => copy/paste/delete SHIFT
   */
  useEffect(() => {
    function handleKeyDown(e) {
      if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
        e.preventDefault();
        if (hoveredShiftRef.current) {
          const hoveredObj = events.find(ev => ev.Id === hoveredShiftRef.current);
          if (hoveredObj?.CategoryId === 1) {
            setCopiedEvent(hoveredObj);
          } else {
            alert('Cannot copy => not a store SHIFT');
          }
        }
      } else if ((e.ctrlKey || e.metaKey) && e.key === 'v') {
        e.preventDefault();
        if (!copiedEvent || copiedEvent.CategoryId !== 1) {
          alert('No valid store SHIFT is copied. Copy a store SHIFT first.');
          return;
        }
        if (!hoveredCellRef.current) {
          alert('No hovered cell to paste into. Hover a cell first.');
          return;
        }
        handlePasteToCell(copiedEvent, hoveredCellRef.current);
      } else if (!e.ctrlKey && !e.metaKey && e.key === 'Delete') {
        if (hoveredShiftRef.current) {
          e.preventDefault();
          handleDeleteShift(hoveredShiftRef.current);
        }
      }
    }
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [copiedEvent, events]);

  /**
   * aggregator => recalc each time "events" or date range changes
   */
  useEffect(() => {
    const newMap = {};
    let storeSum = 0;
    let officeSum = 0;
    let totalSum = 0;

    events.forEach(ev => {
      const rid = ev.CombinedId || 'unknown';
      const st = new Date(ev.StartTime);
      const et = new Date(ev.EndTime);
      const dur = (et - st) / (1000 * 60 * 60);

      if (!newMap[rid]) newMap[rid] = 0;
      newMap[rid] += dur;

      if (ev.CategoryId === 1) {
        storeSum += dur;
      }
      const resText = textMap[rid];
      if (resText === 'Office') {
        officeSum += dur;
      }
      totalSum += dur;
    });

    Object.keys(newMap).forEach(k => {
      newMap[k] = parseFloat(newMap[k].toFixed(2));
    });

    setResourceHoursMap(newMap);
    setStoreTotalHours(parseFloat(storeSum.toFixed(2)));
    setOfficeTotalHours(parseFloat(officeSum.toFixed(2)));
    setOverallHours(parseFloat(totalSum.toFixed(2)));

    onAggregatorChanged?.({
      store: parseFloat(storeSum.toFixed(2)),
      office: parseFloat(officeSum.toFixed(2)),
      overall: parseFloat(totalSum.toFixed(2)),
    });
  }, [events, startDate, endDate, textMap, onAggregatorChanged]);

  /**
   * aggregator resource header => uses local aggregator if needed
   */
  function resourceHeaderTemplate(props) {
    const rid = props.resourceData?.CombinedId;
    const resourceName = props.resourceData?.text || '(No Name)';
    const hrs = resourceHoursMap[rid] || 0; // local aggregator
    return (
      <div>
        <div style={{ fontWeight: 'bold' }}>{resourceName}</div>
        <div style={{ fontSize: '0.9em', color: '#666' }}>({hrs} Hours)</div>
      </div>
    );
  }

  /**
   * SHIFT copy => paste
   */
  async function handlePasteToCell(copied, cell) {
    try {
      if (!scheduleRef.current) return;

      // 1) figure out new CombinedId + Category
      let newCombinedId = copied.CombinedId;
      let newCategoryId = copied.CategoryId;
      if (typeof cell.groupIndex === 'number') {
        const details = scheduleRef.current.getResourcesByIndex(cell.groupIndex);
        if (details?.groupData) {
          newCombinedId = details.groupData.CombinedId || '';
          newCategoryId = details.groupData.CategoryId || 1;
        }
      }
      if (newCategoryId !== 1) {
        alert('Cannot paste store SHIFT onto non-store row.');
        return;
      }

      // 2) figure out new Start/End
      const oldSt = new Date(copied.StartTime);
      const oldEt = new Date(copied.EndTime);
      const dur = oldEt - oldSt;
      const hoveredDate = new Date(cell.date);

      let newStart, newEnd;
      const cv = scheduleRef.current.currentView;
      if (cv === 'TimelineDay') {
        newStart = hoveredDate;
        newEnd = new Date(newStart.getTime() + dur);
      } else {
        newStart = new Date(hoveredDate);
        newStart.setHours(oldSt.getHours(), oldSt.getMinutes(), oldSt.getSeconds(), 0);
        newEnd = new Date(newStart.getTime() + dur);
      }

      // 3) Construct new Subject
      const storeItem = combinedOptions.find(o => o.id === newCombinedId);
      const resourceName = storeItem ? storeItem.text : '';
      let oldSubj = copied.Subject || '';
      let newSubj = '';
      if (oldSubj.includes(' - ')) {
        const idx = oldSubj.indexOf(' - ');
        const oldDetail = oldSubj.slice(idx + 3).trim();
        newSubj = `${resourceName} - ${oldDetail}`;
      } else {
        newSubj = `${resourceName} - ${oldSubj}`;
      }

      // 4) Overlap check
      const newShiftTemp = {
        Id: 0,
        CombinedId: newCombinedId,
        StartTime: newStart,
        EndTime: newEnd,
      };
      const fakeArgs = { requestType: 'eventCreate', data: [newShiftTemp], changedRecords: [newShiftTemp] };
      const { cancel, message } = validateShift(fakeArgs, events, scheduleRef);
      if (cancel) {
        alert(message || 'Cannot paste SHIFT => overlap or invalid.');
        return;
      }

      // 5) If OK => POST to DB
      const postUrl = `${getBaseUrl()}/pfield_events`;
      const res = await axios.post(
        postUrl,
        {
          Subject: newSubj,
          StartTime: toMySqlDateTime(newStart),
          EndTime: toMySqlDateTime(newEnd),
          Description: copied.Description || '',
          CombinedId: newCombinedId,
          CategoryId: newCategoryId,
        },
        { withCredentials: true }
      );
      const newId = res.data?.insertId || Date.now();
      const newLocal = {
        Id: newId,
        Subject: newSubj,
        StartTime: newStart,
        EndTime: newEnd,
        Description: copied.Description || '',
        CombinedId: newCombinedId,
        CategoryId: newCategoryId,
      };
      setEvents(prev => [...prev, newLocal]);
      onEventsChanged?.();
    } catch (err) {
      console.error('[ScheduleSection] Error pasting SHIFT =>', err);
      alert('Error: ' + err.message);
    }
  }

  /**
   * SHIFT deletion
   */
  async function handleDeleteShift(shiftId) {
    try {
      const delUrl = `${getBaseUrl()}/pfield_events/${shiftId}`;
      await axios.delete(delUrl, { withCredentials: true });
      setEvents(prev => prev.filter(e => e.Id !== shiftId));
      setIsModalOpen(false);
      onEventsChanged?.();
    } catch (err) {
      console.error('Error deleting SHIFT =>', err);
      alert(err.message);
    }
  }

  /**
   * SHIFT creation or update => overlap check => POST/PUT
   */
  async function handleSaveEvent(evtData) {
    try {
      // Overlap check
      const newShiftTemp = {
        Id: evtData.Id || 0,
        CombinedId: evtData.CombinedId,
        StartTime: evtData.StartTime,
        EndTime: evtData.EndTime,
      };
      const fakeArgs = {
        requestType: evtData.Id ? 'eventChange' : 'eventCreate',
        data: [newShiftTemp],
        changedRecords: [newShiftTemp],
      };
      const { cancel, message } = validateShift(fakeArgs, events, scheduleRef);
      if (cancel) {
        alert(message || 'Overlap / invalid SHIFT creation');
        return;
      }

      // Persist to DB
      const resource = combinedResource.find(r => r.CombinedId === evtData.CombinedId);
      const forcedCat = resource ? resource.CategoryId : 1;
      const stMySql = toMySqlDateTime(evtData.StartTime);
      const etMySql = toMySqlDateTime(evtData.EndTime);

      if (!evtData.Id) {
        // CREATE
        const postUrl = `${getBaseUrl()}/pfield_events`;
        const res = await axios.post(
          postUrl,
          {
            Subject: evtData.Subject || '',
            StartTime: stMySql,
            EndTime: etMySql,
            Description: evtData.Description || '',
            CombinedId: evtData.CombinedId || '',
            CategoryId: forcedCat,
          },
          { withCredentials: true }
        );
        const newId = res.data?.insertId || Date.now();
        const newShift = { ...evtData, Id: newId, CategoryId: forcedCat };
        setEvents(prev => [...prev, newShift]);
      } else {
        // UPDATE
        const putUrl = `${getBaseUrl()}/pfield_events/${evtData.Id}`;
        await axios.put(
          putUrl,
          {
            Subject: evtData.Subject || '',
            StartTime: stMySql,
            EndTime: etMySql,
            Description: evtData.Description || '',
            CombinedId: evtData.CombinedId,
            CategoryId: forcedCat,
          },
          { withCredentials: true }
        );
        setEvents(prev =>
          prev.map(e => (e.Id === evtData.Id ? { ...evtData, CategoryId: forcedCat } : e))
        );
      }
      setIsModalOpen(false);
      onEventsChanged?.();
    } catch (err) {
      console.error('Error saving SHIFT =>', err);
      alert('Error saving SHIFT => ' + err.message);
    }
  }

  /**
   * SHIFT tooltips or custom cell rendering
   */
  function onRenderCell(args) {
    if (args.elementType === 'workCells' || args.elementType === 'monthCells') {
      const cellElem = args.element;
      cellElem.addEventListener('mouseenter', () => {
        if (!scheduleRef.current) return;
        const details = scheduleRef.current.getCellDetails(cellElem);
        if (details?.startTime) {
          hoveredCellRef.current = {
            date: details.startTime,
            groupIndex: details.groupIndex,
          };
        }
      });
      cellElem.addEventListener('mouseleave', () => {
        hoveredCellRef.current = null;
      });
    }
  }

  function onEventRendered(args) {
    const evId = args.data.Id;
    if (shiftSuggestions[evId]?.length) {
      args.element.style.border = '2px solid gold';
      const label = document.createElement('span');
      label.textContent = '💡';
      label.style.marginLeft = '4px';
      const detailDiv = args.element.querySelector('.e-appointment-details');
      if (detailDiv) {
        detailDiv.appendChild(label);
      } else {
        args.element.appendChild(label);
      }
    }
    args.element.addEventListener('mouseenter', () => {
      hoveredShiftRef.current = evId;
    });
    args.element.addEventListener('mouseleave', () => {
      if (hoveredShiftRef.current === evId) {
        hoveredShiftRef.current = null;
      }
    });
  }

  // Double-click => open SHIFT modal
  function handleCellDoubleClick(args) {
    args.cancel = true; // skip default editor
    if (!scheduleRef.current) return;

    const cv = scheduleRef.current.currentView;
    let defStart = new Date(args.startTime);
    let defEnd = new Date(args.endTime);

    // If user double-clicks in TimelineWeek or Month => 9am-5pm
    if (cv === 'TimelineWeek' || cv === 'Month') {
      defStart.setHours(9, 0, 0, 0);
      defEnd.setHours(17, 0, 0, 0);
      if (defEnd.getDate() !== defStart.getDate()) {
        defEnd.setFullYear(defStart.getFullYear(), defStart.getMonth(), defStart.getDate());
      }
    }

    let defCombId = '';
    let defCatId = 1;
    if (args.groupIndex != null) {
      const rDet = scheduleRef.current.getResourcesByIndex(args.groupIndex);
      if (rDet?.groupData) {
        defCombId = rDet.groupData.CombinedId || '';
        defCatId = rDet.groupData.CategoryId || 1;
      }
    }

    let subject = '';
    if (defCatId === 1) {
      // Find store name
      const storeResource = combinedResource.find(r => r.CombinedId === defCombId);
      const storeName = storeResource?.text || '';
      subject = `${storeName} - General Visit`;
    }

    setCurrentEventData({
      Id: null,
      Subject: subject,
      StartTime: defStart,
      EndTime: defEnd,
      Description: '',
      CombinedId: defCombId,
      CategoryId: defCatId,
    });

    setIsModalOpen(true);
  }

  function handleEventDoubleClick(args) {
    args.cancel = true;
    setCurrentEventData({ ...args.event });
    setIsModalOpen(true);
  }

  // Single-click => in Month => go TimelineDay
  function handleCellClick(args) {
    if (!scheduleRef.current) return;
    const cv = scheduleRef.current.currentView;

    if (cv === 'Month') {
      if (args.isMonthDate) {
        setSelectedDate(new Date(args.startTime));
        scheduleRef.current.selectedDate = new Date(args.startTime);
        scheduleRef.current.currentView = 'TimelineDay';
        scheduleRef.current.dataBind();
        return;
      }
      const cls = args.event?.target?.className || '';
      if (cls.includes('e-navigate') || cls.includes('e-date-header')) {
        setSelectedDate(new Date(args.startTime));
        scheduleRef.current.selectedDate = new Date(args.startTime);
        scheduleRef.current.currentView = 'TimelineDay';
        scheduleRef.current.dataBind();
        return;
      }
    } else if (cv === 'TimelineWeek') {
      if (args.isHeaderCell) {
        setSelectedDate(new Date(args.startTime));
        scheduleRef.current.selectedDate = new Date(args.startTime);
        scheduleRef.current.currentView = 'TimelineDay';
        scheduleRef.current.dataBind();
        return;
      }
      const cls = args.event?.target?.className || '';
      if (cls.includes('e-header-date') || cls.includes('e-navigate')) {
        setSelectedDate(new Date(args.startTime));
        scheduleRef.current.selectedDate = new Date(args.startTime);
        scheduleRef.current.currentView = 'TimelineDay';
        scheduleRef.current.dataBind();
        return;
      }
    }
  }

  // SHIFT validation for drag/drop or built-in create
  const onActionBegin = (args) => {
    if (args.requestType === 'eventCreate' || args.requestType === 'eventChange') {
      const { cancel, message } = validateShift(args, events, scheduleRef);
      if (cancel) {
        args.cancel = true;
        if (message) alert(message);
        return;
      }
    }
    // SHIFT removal => handle ourselves
    if (args.requestType === 'eventRemove') {
      args.cancel = true;
      const removed = Array.isArray(args.data) ? args.data : [args.data];
      removed.forEach(r => r.Id && handleDeleteShift(r.Id));
    }
  };

  // SHIFT changed => push that change to DB
  const onActionComplete = async (args) => {
    if (!scheduleRef.current) return;

    // If user navigates => update parent's date range
    if (args.requestType === 'viewNavigate' || args.requestType === 'dateNavigate') {
      setTimeout(() => {
        const viewDates = scheduleRef.current.getCurrentViewDates();
        if (viewDates?.length) {
          const earliest = new Date(viewDates[0]);
          earliest.setHours(0, 0, 0, 0);
          const latest = new Date(viewDates[viewDates.length - 1]);
          latest.setHours(23, 59, 59, 999);

          const newView = scheduleRef.current.currentView;
          const selDate = scheduleRef.current.selectedDate;
          onDateRangeChange?.(earliest.toISOString(), latest.toISOString(), newView, selDate);
        }
      }, 0);
    }

    // SHIFT changed via drag => update DB
    if (args.requestType === 'eventChange' || args.requestType === 'eventChanged') {
      try {
        const updated = args.changedRecords || [];
        if (!updated.length) return;
        const updatedEv = { ...updated[0] };

        const resource = combinedResource.find(r => r.CombinedId === updatedEv.CombinedId);
        const forcedCat = resource ? resource.CategoryId : 1;
        updatedEv.CategoryId = forcedCat;

        if (updatedEv.CategoryId === 1) {
          // Re-construct subject
          const storeItem = combinedOptions.find(o => o.id === updatedEv.CombinedId);
          const resourceName = storeItem?.text || '';
          if (resourceName) {
            let oldSubj = updatedEv.Subject || '';
            let newSubj = '';
            if (oldSubj.includes(' - ')) {
              const idx = oldSubj.indexOf(' - ');
              const oldDetail = oldSubj.slice(idx + 3).trim();
              newSubj = `${resourceName} - ${oldDetail}`;
            } else {
              newSubj = `${resourceName} - ${oldSubj}`;
            }
            updatedEv.Subject = newSubj;
          }
        }

        // PUT to DB
        const putUrl = `${getBaseUrl()}/pfield_events/${updatedEv.Id}`;
        await axios.put(
          putUrl,
          {
            Subject: updatedEv.Subject,
            StartTime: toMySqlDateTime(updatedEv.StartTime),
            EndTime: toMySqlDateTime(updatedEv.EndTime),
            Description: updatedEv.Description,
            CombinedId: updatedEv.CombinedId,
            CategoryId: forcedCat,
          },
          { withCredentials: true }
        );
        // update local
        setEvents(prev => prev.map(e => (e.Id === updatedEv.Id ? updatedEv : e)));
        onEventsChanged?.();
      } catch (err) {
        console.error('Error updating SHIFT =>', err);
      }
    }
  };

  // SHIFT info template
  function shiftDurationTemplate(props) {
    const st = new Date(props.StartTime);
    const et = new Date(props.EndTime);
    const hrs = ((et - st) / 36e5).toFixed(1);
    return (
      <div style={{ padding: '2px' }}>
        <div style={{ fontWeight: 'bold' }}>{props.Subject}</div>
        <div>
          {st.toLocaleString()} - {et.toLocaleString()} ({hrs} hrs)
        </div>
      </div>
    );
  }

  // We'll override popupOpen => if "EventContainer", use min/max widths
  function handlePopupOpen(args) {
    if (args.type === 'Editor' || args.type === 'QuickInfo') {
      args.cancel = true;
    }
    if (args.type === 'EventContainer') {
      const parent = args.element.closest('.e-more-popup-wrapper');
      if (parent) {
        parent.style.minWidth = '350px';
        parent.style.maxWidth = '700px';
      }
    }
  }

  // ─────────────────────────────────────────────────────────────
  //  NEW: handler for the custom toolbar item
  // ─────────────────────────────────────────────────────────────
  function handleToolbarClick(args) {
    if (args.item?.id === 'Schedule_MarkWeekComplete') {
      // Your real logic can go here
      alert('Mark Week as Complete clicked!');
    }
  }

  return (
    <div style={styles.container}>
      {/* SHIFT creation/edit modal */}
      <CustomModal
        isOpen={isModalOpen}
        eventData={currentEventData}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSaveEvent}
        onDelete={handleDeleteShift}
        allEvents={events}
        combinedOptions={combinedOptions}
      />

      <EJSScheduler
        ref={scheduleRef}
        currentView="Month" // default
        selectedDate={selectedDate}
        height="Auto"
        width="100%"
        firstDayOfWeek={1}
        showQuickInfo={false}
        allowDragAndDrop
        allowResizing
        categoryData={categoryData}
        combinedResource={combinedResource}
        resourceHeaderTemplate={resourceHeaderTemplate}
        renderCell={onRenderCell}
        eventRendered={onEventRendered}
        cellClick={handleCellClick}
        cellDoubleClick={handleCellDoubleClick}
        eventDoubleClick={handleEventDoubleClick}
        actionBegin={onActionBegin}
        actionComplete={onActionComplete}
        popupOpen={handlePopupOpen}

        // 1) Provide the custom toolbar array:
        //    - This example includes built-in "TimelineDay", "TimelineWeek", "Month", 
        //      and your new { text, id } item.
        toolbar={[
          'TimelineDay',
          'TimelineWeek',
          'Month',
          { text: 'Mark Week as Complete', id: 'MarkWeekComplete' }
        ]}

        // 2) Provide the click handler:
        toolbarClick={handleToolbarClick}

        // Event Settings
        eventSettings={{
          dataSource: events,
          template: shiftDurationTemplate,
          fields: {
            id: 'Id',
            subject: { name: 'Subject' },
            startTime: { name: 'StartTime' },
            endTime: { name: 'EndTime' },
            description: { name: 'Description' },
            groupID: { name: 'CategoryId' },
          },
          tooltipTemplate: ShiftTooltip,
        }}
      />

      <StoreHoursOverlay
        resourceHoursMap={
          Object.keys(storeHoursMap || {}).length > 0
            ? storeHoursMap
            : resourceHoursMap
        }
        storeTotalHours={storeTotalHours}
        officeTotalHours={officeTotalHours}
        overallHours={overallHours}
        nameToIdMap={nameToIdMap}
        categoryIdMap={categoryIdMap}
      />
    </div>
  );
});

export default ScheduleSection;
