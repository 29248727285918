// src/components/EmployeeChangeAnalyzer.js
import React, { useState, useEffect } from 'react';
import api from './services/api'; // Adjust the path based on your project structure
import { utils, writeFile } from 'xlsx'; // Importing SheetJS functions

// --- CHAKRA IMPORTS ---
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Select,
  Text,
  Progress,
  useToast,
  Divider,
} from '@chakra-ui/react';

function EmployeeChangeAnalyzer() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [uploadStatus, setUploadStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [records, setRecords] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [warnings, setWarnings] = useState([]);

  // Filter States
  const [uniqueFields, setUniqueFields] = useState([]);
  const [uniqueDepartmentCodes, setUniqueDepartmentCodes] = useState([]);
  const [selectedField, setSelectedField] = useState('');
  const [selectedChangeDateRange, setSelectedChangeDateRange] = useState('90');
  const [selectedDepartmentCode, setSelectedDepartmentCode] = useState('');

  const toast = useToast();

  // Effect to extract unique 'Field' and 'Department Code' values once records are loaded
  useEffect(() => {
    if (records.length > 0) {
      const fields = [
        ...new Set(records.map((record) => record['Field']).filter(Boolean)),
      ];
      setUniqueFields(fields);

      const deptCodes = [
        ...new Set(
          records.map((record) => record['Department Code']).filter(Boolean),
        ),
      ];
      setUniqueDepartmentCodes(deptCodes);

      // Set default filters if needed
      const defaultField = 'EmployeeDirectDeposit: AccountNumber';
      setSelectedField(fields.includes(defaultField) ? defaultField : '');
      setSelectedDepartmentCode(deptCodes.includes('500') ? '500' : '');
    }
  }, [records]);

  // Effect to apply filters whenever any filter state changes
  useEffect(() => {
    let tempRecords = [...records];

    if (selectedField) {
      tempRecords = tempRecords.filter((record) => record['Field'] === selectedField);
    }

    if (selectedChangeDateRange) {
      const currentDate = new Date();
      const pastDate = new Date();
      pastDate.setDate(currentDate.getDate() - Number(selectedChangeDateRange));

      tempRecords = tempRecords.filter((record) => {
        if (!record['Change Date']) return false;
        const changeDate = new Date(record['Change Date']);
        return changeDate >= pastDate && changeDate <= currentDate;
      });
    }

    if (selectedDepartmentCode) {
      tempRecords = tempRecords.filter(
        (record) => record['Department Code'] === selectedDepartmentCode,
      );
    }

    setFilteredRecords(tempRecords);
  }, [selectedField, selectedChangeDateRange, selectedDepartmentCode, records]);

  // Handler for file input change
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setSelectedFileName(file.name);
      setUploadStatus('');
      setProgress(0);
      setRecords([]);
      setFilteredRecords([]);
      setSelectedField('');
      setSelectedChangeDateRange('90');
      setSelectedDepartmentCode('');
      setWarnings([]);
    }
  };

  // Handler for form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedFile) {
      setUploadStatus('Please select a file before submitting.');
      return;
    }

    setIsLoading(true);
    setUploadStatus('Uploading and processing...');
    setRecords([]);
    setFilteredRecords([]);
    setWarnings([]);

    const formData = new FormData();
    formData.append('excelFile', selectedFile);

    try {
      const response = await api.post('/direct-deposit-change/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        withCredentials: true,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          setProgress(percentCompleted);
        },
      });

      setUploadStatus('Upload and processing completed successfully.');
      setRecords(response.data.data);
      setWarnings(response.data.warnings || []);
    } catch (error) {
      const errorMessage =
        error.response?.data?.error || 'Failed to process the file.';
      setUploadStatus(`Error: ${errorMessage}`);
    } finally {
      setIsLoading(false);
      setProgress(0);
    }
  };

  // Function to export filtered data to Excel with additional sheets
  const exportToExcel = () => {
    if (filteredRecords.length === 0) {
      toast({
        title: 'No records available to export',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const workbook = utils.book_new();
    const worksheet = utils.json_to_sheet(filteredRecords);
    utils.book_append_sheet(workbook, worksheet, 'FilteredData');

    const employeeNamesInFilteredRecords = filteredRecords.map(
      (record) => record['Name'],
    );

    const noActiveStatusWarnings = warnings
      .filter(
        (warning) =>
          warning.warning === 'No active entries' &&
          employeeNamesInFilteredRecords.includes(warning.employeeName),
      )
      .map((warning) => ({
        Employee: warning.employeeName,
        Warning: warning.warning,
      }));

    if (noActiveStatusWarnings.length > 0) {
      const noActiveSheet = utils.json_to_sheet(noActiveStatusWarnings);
      utils.book_append_sheet(workbook, noActiveSheet, 'No Active Status');
    }

    const noMatchedEntriesWarnings = warnings
      .filter(
        (warning) =>
          warning.warning === 'No matched entries' &&
          employeeNamesInFilteredRecords.includes(warning.employeeName),
      )
      .map((warning) => ({
        Employee: warning.employeeName,
        Warning: warning.warning,
      }));

    if (noMatchedEntriesWarnings.length > 0) {
      const noMatchedSheet = utils.json_to_sheet(noMatchedEntriesWarnings);
      utils.book_append_sheet(workbook, noMatchedSheet, 'Employee not found in pApp Data');
    }

    const fileName = `DirectDepositChanges_${new Date()
      .toISOString()
      .slice(0, 10)}.xlsx`;
    writeFile(workbook, fileName);
  };

  return (
    <Box bg="gray.50" minH="100vh" py={8}>
      <Container maxW="container.md">

        {/* File Upload Form */}
        <Box
          as="form"
          onSubmit={handleSubmit}
          mb={6}
          p={6}
          bg="white"
          borderRadius="md"
          boxShadow="md"
        >
          <Heading as="h2" size="md" mb={4}>
            Upload Your File
          </Heading>
          <Text fontSize="sm" color="gray.600" mb={4}>
            Please upload your Employee Change Log. Accepted file types: .xlsx, .xls.
          </Text>

          <Box mb={4}>
            <Button
              as="label"
              htmlFor="excelFile"
              variant="solid"
              colorScheme="teal"
              cursor="pointer"
            >
              {selectedFileName ? `Selected: ${selectedFileName}` : 'Choose File'}
              <Input
                id="excelFile"
                type="file"
                accept=".xlsx, .xls"
                display="none"
                onChange={handleFileChange}
                required
              />
            </Button>
          </Box>

          <Box>
            <Button
              type="submit"
              variant="solid"
              colorScheme="blue"
              isLoading={isLoading}
            >
              {isLoading ? 'Processing...' : 'Submit'}
            </Button>
          </Box>

          {/* Display Upload Status */}
          {uploadStatus && (
            <Text
              mt={4}
              fontSize="md"
              color={
                uploadStatus.toLowerCase().includes('error') ? 'red.500' : 'gray.700'
              }
            >
              {uploadStatus}
            </Text>
          )}

          {/* Display Progress Bar */}
          {isLoading && (
            <Box mt={4}>
              <Progress value={progress} size="md" colorScheme="teal" hasStripe />
              <Text fontSize="sm" color="gray.600" mt={1}>
                {progress}%
              </Text>
            </Box>
          )}
        </Box>

        {/* Filters and Download Button */}
        {records.length > 0 && (
          <Box
            mt={8}
            p={6}
            bg="white"
            borderRadius="md"
            boxShadow="md"
          >
            <Heading as="h2" size="md" mb={4}>
              Filters
            </Heading>
            <Text fontSize="sm" color="gray.500" mb={4}>
              Narrow down results based on Field, Change Date, and Department Code.
            </Text>

            <Grid templateColumns="repeat(auto-fit, minmax(200px, 1fr))" gap={4}>
              {/* Filter by Field */}
              <GridItem>
                <FormControl>
                  <FormLabel>Field</FormLabel>
                  <Select
                    value={selectedField}
                    onChange={(e) => setSelectedField(e.target.value)}
                  >
                    <option value="">All</option>
                    {uniqueFields.map((field, index) => (
                      <option key={index} value={field}>
                        {field}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>

              {/* Filter by Change Date */}
              <GridItem>
                <FormControl>
                  <FormLabel>Change Date</FormLabel>
                  <Select
                    value={selectedChangeDateRange}
                    onChange={(e) => setSelectedChangeDateRange(e.target.value)}
                  >
                    <option value="">All</option>
                    <option value="30">Last 30 Days</option>
                    <option value="60">Last 60 Days</option>
                    <option value="90">Last 90 Days</option>
                  </Select>
                </FormControl>
              </GridItem>

              {/* Filter by Department Code */}
              <GridItem>
                <FormControl>
                  <FormLabel>Department Code</FormLabel>
                  <Select
                    value={selectedDepartmentCode}
                    onChange={(e) => setSelectedDepartmentCode(e.target.value)}
                  >
                    <option value="">All</option>
                    {uniqueDepartmentCodes.map((dept, index) => (
                      <option key={index} value={dept}>
                        {dept}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
            </Grid>

            <Divider my={6} />

            {/* Download Button */}
            <Button
              variant="solid"
              colorScheme="teal"
              onClick={exportToExcel}
              isDisabled={filteredRecords.length === 0}
            >
              Download Data as Excel
            </Button>
            {filteredRecords.length === 0 && (
              <Text fontSize="sm" color="gray.500" mt={2}>
                No filtered records available to download.
              </Text>
            )}
          </Box>
        )}
      </Container>
    </Box>
  );
}

export default EmployeeChangeAnalyzer;
