// WeeklyDataEntryForm.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  Text,
  Collapse,
  useColorModeValue,
} from "@chakra-ui/react";

import {
  format,
  startOfISOWeek,
  endOfISOWeek,
  getISOWeek,
  subDays,
  getMonth,
  getYear,
} from "date-fns";

function WeeklyDataEntryForm() {
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [isUserAuthorizedForPoPs, setIsUserAuthorizedForPoPs] = useState(false);

  const [formData, setFormData] = useState({
    storeSelection: "-1",
    hoursEndingMonth: "",
    hoursBeginningMonth: "",
    foodVarianceWeek: "",
    foodVarianceMonth: "",
    entryDate: "",
  });

  const [dateRanges, setDateRanges] = useState({
    hoursEndMonthRange: "",
    hoursStartNextMonthRange: "",
    currentMonth: "",
  });

  const [popsFormData, setPopsFormData] = useState({
    mysteryShopScore: "0",
    syncReports: "",
    actualFoodAndPaperCost: "",
  });

  const [supervisedStores, setSupervisedStores] = useState([]);

  // Theming / styling
  const panelBg = useColorModeValue("white", "gray.700");
  const borderCol = useColorModeValue("gray.200", "gray.600");

  // Toggle the form visibility
  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
  };

  const getBaseUrl = () => {
    const hostname = window.location.hostname;
    if (hostname === "72.167.34.236") {
      return "http://72.167.34.236:5000";
    } else if (/^(www\.)?prolifi\.app$/.test(hostname)) {
      return "https://prolifi.app";
    } else {
      return "http://localhost:5000";
    }
  };

  useEffect(() => {
    fetchSupervisedStores();
    calculateDateRangesForISOWeek();
    checkUserAuthorization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Check PoPs Authorization
  const checkUserAuthorization = async () => {
    const baseUrl = getBaseUrl();
    try {
      // 1) get session data => userId
      const sessionResponse = await axios.get(`${baseUrl}/api/session-data`, {
        withCredentials: true,
      });
      const userId = sessionResponse.data.data.id;

      // 2) check if user is authorized
      if (userId) {
        const authorizationResponse = await axios.get(
          `${baseUrl}/api/check-user-authorization`,
          {
            params: { userId },
            withCredentials: true,
          }
        );
        setIsUserAuthorizedForPoPs(!!authorizationResponse.data.isAuthorized);
      } else {
        console.error("User ID not found in session data");
        setIsUserAuthorizedForPoPs(false);
      }
    } catch (error) {
      console.error("Error checking user authorization:", error);
      setIsUserAuthorizedForPoPs(false);
    }
  };

  // Re-fetch existing data if store changes
  useEffect(() => {
    if (formData.storeSelection !== "-1") {
      // Clear out old data first
      setFormData((prev) => ({
        ...prev,
        hoursEndingMonth: "",
        hoursBeginningMonth: "",
        foodVarianceWeek: "",
        foodVarianceMonth: "",
        entryDate: "",
      }));
      // Then fetch new data
      fetchExistingData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.storeSelection]);

  const fetchExistingData = async () => {
    const baseUrl = getBaseUrl();
    const today = new Date();
    const isMondayTuesdayOrWednesday =
      today.getDay() === 1 || today.getDay() === 2 || today.getDay() === 3;
    const entryDate = isMondayTuesdayOrWednesday
      ? endOfISOWeek(subDays(today, 7))
      : endOfISOWeek(today);
    const weekNumber = `${entryDate.getFullYear()}${String(
      getISOWeek(entryDate)
    ).padStart(2, "0")}`;

    try {
      // Fetch normal data
      const response = await axios.get(
        `${baseUrl}/api/weekly-data-entry/fetch-data`,
        {
          params: {
            storeId: formData.storeSelection,
            weekNumber,
          },
        }
      );
      if (response.data) {
        const fetchedData = response.data;
        const isSplitWeek =
          fetchedData.hours_ending_month !== null &&
          fetchedData.hours_beginning_month !== null;

        setFormData((prev) => ({
          ...prev,
          storeSelection: fetchedData.store_id.toString(),
          hoursEndingMonth: isSplitWeek
            ? fetchedData.hours_ending_month
            : fetchedData.hours_full_week || "",
          hoursBeginningMonth: isSplitWeek ? fetchedData.hours_beginning_month : "",
          foodVarianceWeek: fetchedData.food_variance_week,
          foodVarianceMonth: fetchedData.food_variance_month,
        }));
      }
    } catch (error) {
      console.error("Error fetching existing data:", error);
    }

    try {
      // Fetch PoPs data
      const popsResponse = await axios.get(
        `${baseUrl}/api/weekly-data-entry/fetch-pops-data`,
        {
          params: { storeId: formData.storeSelection },
        }
      );
      if (popsResponse.data) {
        const popsData = popsResponse.data;
        setPopsFormData({
          mysteryShopScore: popsData.mystery_shop_score || "",
          syncReports: popsData.mtd_sync_reports || "",
          actualFoodAndPaperCost: popsData.actual_fandp_cost || "",
        });
      }
    } catch (error) {
      console.error("Error fetching PoPs data:", error);
    }
  };

  const fetchSupervisedStores = async () => {
    const baseUrl = getBaseUrl();
    try {
      const response = await axios.get(`${baseUrl}/api/supervised-stores`, {
        withCredentials: true,
      });
      setSupervisedStores(response.data);
    } catch (error) {
      console.error("Error fetching supervised stores:", error);
    }
  };

  const calculateDateRangesForISOWeek = () => {
    const currentDate = new Date();
    const dayOfWeek = currentDate.getDay();
    let weekStart, weekEnd;

    if (dayOfWeek === 1) {
      const lastMonday = subDays(currentDate, 7);
      weekStart = startOfISOWeek(lastMonday);
      weekEnd = endOfISOWeek(lastMonday);
    } else {
      weekStart = startOfISOWeek(currentDate);
      weekEnd = endOfISOWeek(currentDate);
    }

    const currentMonthFormatted = format(currentDate, "MMMM");
    const isSplitWeek = getMonth(weekStart) !== getMonth(weekEnd);

    let hoursEndMonthRangeFormatted = "";
    let hoursStartNextMonthRangeFormatted = "";

    if (isSplitWeek) {
      const lastDayOfStartMonth = new Date(
        getYear(weekStart),
        getMonth(weekStart) + 1,
        0
      );
      hoursEndMonthRangeFormatted = `${format(
        weekStart,
        "MM/dd"
      )} - ${format(lastDayOfStartMonth, "MM/dd")}`;

      const firstDayOfEndMonth = new Date(
        getYear(weekEnd),
        getMonth(weekEnd),
        1
      );
      hoursStartNextMonthRangeFormatted = `${format(
        firstDayOfEndMonth,
        "MM/dd"
      )} - ${format(weekEnd, "MM/dd")}`;
    } else {
      hoursEndMonthRangeFormatted = `${format(weekStart, "MM/dd")} - ${format(
        weekEnd,
        "MM/dd"
      )}`;
    }

    setDateRanges({
      currentMonth: currentMonthFormatted,
      hoursEndMonthRange: hoursEndMonthRangeFormatted,
      hoursStartNextMonthRange: hoursStartNextMonthRangeFormatted,
    });
  };

  // Handle normal form field changes
  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // Handle PoPs form field changes
  const handlePopsChange = (e) => {
    const { name, value } = e.target;
    setPopsFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.storeSelection === "-1") {
      alert("Please select a store before submitting");
      return;
    }

    const isSplitWeek = dateRanges.hoursStartNextMonthRange !== "";
    const { hoursBeginningMonth, ...restOfFormData } = formData;

    const combinedFormData = {
      ...restOfFormData,
      isUserAuthorizedForPoPs,
      ...(isSplitWeek ? { hoursBeginningMonth } : {}),
      ...(isUserAuthorizedForPoPs ? popsFormData : {}),
    };

    console.log("Final form data being sent:", combinedFormData);

    const baseUrl = getBaseUrl();
    try {
      const response = await axios.post(`${baseUrl}/api/weekly-data-entry`, combinedFormData, {
        withCredentials: true,
      });
      console.log("Response from server:", response.data);
      alert("Data submitted successfully");
    } catch (error) {
      console.error("Submission error:", error);
      alert("Error submitting data");
    }
  };

  return (
    <Box maxW="700px" mx="auto" borderWidth="1px" borderColor={borderCol} borderRadius="md" mb={4} mt={2}>
      {/* Toggle bar at top */}
      <Box
        as="button"
        w="100%"
        textAlign="center"
        onClick={toggleFormVisibility}
        // Thin + gradient
        bgGradient="linear(to-r, blue.500, blue.300)"
        color="white"
        _hover={{
          bgGradient: "linear(to-r, blue.600, blue.400)",
        }}
        borderBottomWidth="1px"
        borderColor={borderCol}
        py={1}            // keep it as small as possible
      >
        <Text fontSize="xs" fontWeight="bold">
          {isFormVisible ? "▲ Hide Form" : "▼ Show Form"}
        </Text>
      </Box>

      {/* Collapsible form */}
      <Collapse in={isFormVisible} animateOpacity>
        <Box
          as="form"
          onSubmit={handleSubmit}
          bg={panelBg}
          p={4}
          borderBottomRadius="md"
          shadow="md"
        >
          <Heading size="md" mb={2}>
            Weekly Data Entry
          </Heading>
          <Text fontSize="sm" mb={4}>
            Please fill in the form below
          </Text>

          {/* Store Selection */}
          <FormControl mb={4}>
            <FormLabel fontSize="sm" fontWeight="semibold">
              Select Store
            </FormLabel>
            <Select
              name="storeSelection"
              value={formData.storeSelection}
              onChange={handleChange}
              placeholder="Please select a store"
            >
              {supervisedStores.map((store) => (
                <option key={store} value={store}>
                  Store #{store}
                </option>
              ))}
            </Select>
          </FormControl>

          {/* If store selected => show the rest */}
          {formData.storeSelection !== "-1" && (
            <>
              {/* Hours => either split week or full */}
              {dateRanges.hoursStartNextMonthRange ? (
                <>
                  <FormControl mb={2}>
                    <FormLabel fontSize="sm" fontWeight="semibold">
                      Hours Worked (Ending Month)
                    </FormLabel>
                    <Input
                      type="number"
                      name="hoursEndingMonth"
                      placeholder={`Hours: ${dateRanges.hoursEndMonthRange}`}
                      value={formData.hoursEndingMonth}
                      onChange={handleChange}
                    />
                  </FormControl>

                  <FormControl mb={4}>
                    <FormLabel fontSize="sm" fontWeight="semibold">
                      Hours Worked (Beginning Next Month)
                    </FormLabel>
                    <Input
                      type="number"
                      name="hoursBeginningMonth"
                      placeholder={`Hours: ${dateRanges.hoursStartNextMonthRange}`}
                      value={formData.hoursBeginningMonth}
                      onChange={handleChange}
                    />
                  </FormControl>
                </>
              ) : (
                <FormControl mb={4}>
                  <FormLabel fontSize="sm" fontWeight="semibold">
                    Hours for the Week
                  </FormLabel>
                  <Input
                    type="number"
                    name="hoursEndingMonth"
                    placeholder={`Hours: ${dateRanges.hoursEndMonthRange}`}
                    value={formData.hoursEndingMonth}
                    onChange={handleChange}
                  />
                </FormControl>
              )}

              {/* Food Variance */}
              <FormControl mb={2}>
                <FormLabel fontSize="sm" fontWeight="semibold">
                  Food Variance (Week)
                </FormLabel>
                <Input
                  type="number"
                  name="foodVarianceWeek"
                  placeholder="Food Variance (Week)"
                  value={formData.foodVarianceWeek}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel fontSize="sm" fontWeight="semibold">
                  Food Variance (Month to Date: {dateRanges.currentMonth})
                </FormLabel>
                <Input
                  type="number"
                  name="foodVarianceMonth"
                  placeholder="Food Variance (Month)"
                  value={formData.foodVarianceMonth}
                  onChange={handleChange}
                />
              </FormControl>

              {/* PoPs Data (authorized user + store selected) */}
              {isUserAuthorizedForPoPs && (
                <Box
                  borderWidth="1px"
                  p={3}
                  mb={4}
                  borderColor={borderCol}
                  borderRadius="md"
                >
                  <Heading size="sm" mb={2}>
                    PoPs Data
                  </Heading>

                  {/* Mystery Shop Score (hidden in your original, but let's just keep it hidden or remove) */}
                  <Input
                    type="hidden"
                    name="mysteryShopScore"
                    value={popsFormData.mysteryShopScore}
                    onChange={handlePopsChange}
                  />

                  <FormControl mb={2}>
                    <FormLabel fontSize="sm" fontWeight="semibold">
                      Sync Reports (MTD)
                    </FormLabel>
                    <Input
                      type="number"
                      name="syncReports"
                      placeholder="Sync Reports MTD"
                      value={popsFormData.syncReports}
                      onChange={handlePopsChange}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel fontSize="sm" fontWeight="semibold">
                      Actual F&P (MTD)
                    </FormLabel>
                    <Input
                      type="number"
                      name="actualFoodAndPaperCost"
                      placeholder="Enter e.g. 28.77"
                      value={popsFormData.actualFoodAndPaperCost}
                      onChange={handlePopsChange}
                    />
                  </FormControl>
                </Box>
              )}
            </>
          )}

          {/* Submit Button aligned to right */}
          <Flex justify="flex-end">
            <Button colorScheme="blue" mt={2} type="submit">
              Submit
            </Button>
          </Flex>
        </Box>
      </Collapse>
    </Box>
  );
}

export default WeeklyDataEntryForm;
