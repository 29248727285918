// File: src/components/ProlifiField/Schedules/DirectorSchedulerContainer.jsx

import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';

import DirectorDashboardSection from './dDashboardSec';   // optional collapse bar
import DirectorScheduleSection from './DirectorScheduleSection';
import { getBaseUrl } from '../../../utils/getBaseUrl';
import './MyScheduler.css';

export default function DirectorSchedulerContainer() {
  const [dashboardCollapsed, setDashboardCollapsed] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate,   setEndDate]   = useState(null);

  const [events, setEvents] = useState([]);
  const [supervisorResource, setSupervisorResource] = useState([]);
  const [directorStoreData, setDirectorStoreData]   = useState([]);

  // We'll choose a special resource ID for the director row
  const DIRECTOR_RESOURCE_ID = -999;

  const scheduleSectionRef = useRef(null);

  function toggleDashboard() {
    setDashboardCollapsed(prev => !prev);
  }

  // Whenever date range changes => fetch from our back end
  useEffect(() => {
    if (startDate && endDate) {
      fetchDirectorShifts(startDate, endDate);
    }
  }, [startDate, endDate]);

  async function fetchDirectorShifts(start, end) {
    const baseUrl = getBaseUrl();
    const url = `${baseUrl}/pfield_events/pfield_director/director_shifts`;
    const payload = { StartDate: start, EndDate: end };
    console.log('[fetchDirectorShifts] => posting:', payload);

    try {
      const res = await axios.post(url, payload, { withCredentials: true });
      const {
        supervisors = [],
        directorShifts = [],
        directorName   = 'Unknown Director',
        directorIsSupervisor = false,
        directorStores = [],
		directorAreas = [],
      } = res.data || {};

      // If director has dual role => append (Director)
      let finalDirectorName = directorName;
      if (directorIsSupervisor) {
        finalDirectorName += ' (Director)';
      }

      // Save the store data => pass to the modal
      setDirectorStoreData(directorAreas);

      // Build combined array => supervisor events + director events
      const combinedEvents = [];

      // A) Supervisor events => normal supId
      supervisors.forEach(sup => {
        sup.shifts.forEach(sh => {
          combinedEvents.push({
            Id: sh.Id,
            SupervisorId: sup.supId,
            Subject: `${sup.name} - ${sh.Subject}`,
            StartTime: sh.StartTime,    // "YYYY-MM-DD HH:mm:ss"
            EndTime:   sh.EndTime,      // "YYYY-MM-DD HH:mm:ss"
            Description: sh.Description,
            CombinedId: sh.CombinedId,
            CategoryId: sh.CategoryId
          });
        });
      });

      // B) Director events => SupervisorId = -999
      directorShifts.forEach(dr => {
        combinedEvents.push({
          Id: dr.Id,
          SupervisorId: DIRECTOR_RESOURCE_ID,
          Subject: dr.Subject,
          StartTime: dr.StartTime,
          EndTime:   dr.EndTime,
          Description: dr.Description,
          CombinedId: dr.CombinedId,
          CategoryId: dr.CategoryId,
          // Additional fields for the modal
          storeOrEvent: dr.storeOrEvent,
          storeIds:     dr.storeIds || [],
          notes:        dr.notes || []
        });
      });

      setEvents(combinedEvents);

      // Resource array => Director row first
      const directorResource = {
        id:   DIRECTOR_RESOURCE_ID,
        text: finalDirectorName,
        color:'#FFD700'
      };

      // Then each supervisor
      const colorPalette = [
        '#2196f3','#FF9800','#4CAF50','#9C27B0','#f44336',
        '#795548','#E91E63','#3F51B5','#009688','#CDDC39',
      ];
      let colorIndex = 0;
      const supResources = supervisors.map(sup => {
        const color = colorPalette[colorIndex % colorPalette.length];
        colorIndex++;
        return {
          id: sup.supId,
          text: sup.name,
          color
        };
      });

      setSupervisorResource([directorResource, ...supResources]);

    } catch (err) {
      console.error('Error fetching director shift data =>', err);
      setEvents([]);
      setSupervisorResource([]);
      setDirectorStoreData([]);
    }
  }

  function handleEventsChanged() {
    if (startDate && endDate) {
      fetchDirectorShifts(startDate, endDate);
    }
  }

  function expandCollapseAndSwitchView(newView) {
    const oldCollapsed = dashboardCollapsed;
    setDashboardCollapsed(false);
    requestAnimationFrame(() => {
      if (scheduleSectionRef.current?.switchView) {
        scheduleSectionRef.current.switchView(newView);
      }
      requestAnimationFrame(() => {
        setDashboardCollapsed(oldCollapsed);
      });
    });
  }

  function handleDateRangeChange(start, end) {
    setStartDate(start);
    setEndDate(end);
  }

  return (
    <div style={styles.pageContainer}>
      <div style={{ width:'100%', height:'99vh', position:'relative' }}>
        <DirectorDashboardSection
          collapsed={dashboardCollapsed}
          onToggle={toggleDashboard}
        />

        <DirectorScheduleSection
          ref={scheduleSectionRef}
          events={events}
          setEvents={setEvents}
          supervisorResource={supervisorResource}
          onDateRangeChange={handleDateRangeChange}
          expandCollapseAndSwitchView={expandCollapseAndSwitchView}
          startDate={startDate}
          endDate={endDate}
          onEventsChanged={handleEventsChanged}
          directorStores={directorStoreData}
        />
      </div>
    </div>
  );
}

const styles = {
  pageContainer: {
    display:'flex',
    flexDirection:'column',
    height:'99vh',
    overflow:'hidden',
    margin:1,
    padding:1
  }
};
