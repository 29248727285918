// File: src/components/ProlifiField/Schedules/ProlifiFieldMiniNav.jsx

import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { FiCalendar } from "react-icons/fi";
import {
  Flex,
  IconButton,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";

/**
 * ProlifiFieldMiniNav
 *
 * Props:
 *  - onExpand (function): callback to expand/collapse the mini nav
 *  - userGroup (string): e.g. "director" or "supervisor" from session data
 *
 * This component decides whether to link the calendar icon to
 * "/director-schedule" or "/schedule" based on userGroup.
 */
function ProlifiFieldMiniNav({ onExpand, userGroup }) {
  // Background color in light vs. dark mode
  const bgColor = useColorModeValue("gray.50", "gray.700");

  // Hover events to expand/collapse
  const handleMouseEnter = () => onExpand(true);
  const handleMouseLeave = () => onExpand(false);

	const role = userGroup.userGroup;

  // Decide the route path based on userGroup
  const scheduleRoute = 
    role === "director" ? "/prolifi-field/director-schedule" : "/prolifi-field/schedule";

  return (
    <Flex
      position="relative"
      alignItems="center"
      justifyContent="center"
      bg={bgColor}
      width="100%"
      boxShadow="sm"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      _before={{
        content: '""',
        position: "absolute",
        left: 0,
        top: 0,
        bottom: 0,
        width: "50px",
        background: `linear-gradient(to right, transparent 0%, ${bgColor} 100%)`,
        zIndex: 1,
      }}
      _after={{
        content: '""',
        position: "absolute",
        right: 0,
        top: 0,
        bottom: 0,
        width: "50px",
        background: `linear-gradient(to left, transparent 0%, ${bgColor} 100%)`,
        zIndex: 1,
      }}
    >
      <Flex zIndex={2} gap={4} justifyContent="center" alignItems="center">
        <Tooltip label="Schedule" aria-label="Schedule tooltip">
          <IconButton
            as={RouterLink}
            to={scheduleRoute}
            icon={<FiCalendar />}
            variant="ghost"
            size="sm"
            aria-label="Schedule"
          />
        </Tooltip>

        {/* Add more icons here if desired */}
      </Flex>
    </Flex>
  );
}

export default ProlifiFieldMiniNav;
