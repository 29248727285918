// src/components/TruckConverter.js

import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment-timezone";
import "react-toastify/dist/ReactToastify.css";

import {
  Flex,
  Box,
  Button,
  IconButton,
  Text,
  Input,
  useColorModeValue,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import ConverterInstructions from "./ConverterInstructions";

function TruckConverter() {
  const [file, setFile] = useState(null);
  const [instructionsVisible, setInstructionsVisible] = useState(false);

  const bgColor = useColorModeValue("gray.50", "gray.800");
  const bgBoxColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const inputBgHover = useColorModeValue("blue.50", "gray.600");
  const dropBorderColor = useColorModeValue("blue.300", "blue.600");

  // Toggle the instructions panel
  const toggleInstructions = () => setInstructionsVisible(!instructionsVisible);

  // Handle file selection
  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };

  // Determine base URL for dev/prod
  const getBaseUrl = () => {
    const hostname = window.location.hostname;
    if (hostname === "72.167.34.236") {
      return "http://72.167.34.236:5000";
    } else if (/^(www\.)?prolifi\.app$/.test(hostname)) {
      return "https://prolifi.app";
    } else {
      console.error("Unknown host");
      return "";
    }
  };

  // Main upload + convert
  const handleUpload = async () => {
    if (!file) {
      toast.warn("Please select a file first", { position: "top-center" });
      return;
    }

    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (fileExtension !== "csv") {
      toast.warn("Please upload a valid CSV file.", { position: "top-center" });
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const baseUrl = getBaseUrl();
      const response = await axios.post(`${baseUrl}/api/truckConverter`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        responseType: "blob",
      });

      const blob = new Blob([response.data]);
      const downloadUrl = window.URL.createObjectURL(blob);

      const now = moment().tz("America/Chicago");
      const dateStr = now.format("YYYY-MM-DD");
      const timeStr = now.format("HHmmss");
      const fileName = `ProlifiTruck_${dateStr}_${timeStr}.csv`;

      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();

      toast.success("File converted successfully! Download has started.", {
        position: "top-center",
      });
    } catch (error) {
      handleError(error);
    }
  };

  // General error handling
  const handleError = (error) => {
    if (error.response) {
      const statusCode = error.response.status;
      if (statusCode >= 400 && statusCode < 500) {
        handleClientError(error);
      } else if (statusCode >= 500) {
        toast.error("Server error occurred. Please try again later.", {
          position: "top-center",
        });
      }
    } else if (error.request) {
      if (error.message.includes("Network Error")) {
        toast.error("Network error. Please check your connection.", {
          position: "top-center",
        });
      } else if (error.code === "ECONNABORTED") {
        toast.error("The request timed out. Please try again.", {
          position: "top-center",
        });
      } else {
        toast.error("There was a problem with the request. Please try again.", {
          position: "top-center",
        });
      }
    } else {
      toast.error("An unexpected error occurred. Please try again.", {
        position: "top-center",
      });
    }
    console.error("Error uploading the file:", error);
  };

  // Handle 4xx client errors specifically
  const handleClientError = (error) => {
    if (error.response && error.response.data instanceof Blob) {
      const reader = new FileReader();
      reader.onload = function () {
        const errorMessage = reader.result || "";
        if (errorMessage.includes("Invalid file format: Code must be exactly 5 characters")) {
          toast.error(
            <div>
              <strong>Invalid File Format:</strong>
              <br />
              Please check that you are using the correct Inv Xpress file.
              <br />
              <ul>
                <li>Did you use the 'Export' option in Xpress Order?</li>
                <li>
                  The structure should be:
                  <em>
                    Column A: code, Column B: quantity, Column C: description
                  </em>
                </li>
              </ul>
            </div>,
            {
              position: "top-center",
              className: "custom-toast",
              progressClassName: "custom-toast-progress",
              icon: "🚨",
              autoClose: 5500,
            }
          );
        } else {
          toast.error("There was an error processing the file. Please check the format.", {
            position: "top-center",
          });
        }
      };
      reader.readAsText(error.response.data);
    } else {
      toast.error("Invalid file format. Please check your file.", {
        position: "top-center",
      });
    }
  };

  return (
    <Flex direction="column" p={4} minH="100vh" bg={bgColor}>
      <ToastContainer />

      {/* Main converter box */}
      <Box
        borderWidth="1px"
        borderColor={borderColor}
        borderRadius="md"
        bg={bgBoxColor}
        p={4}
        shadow="md"
        mb={4}
        position="relative"
      >
        {/* Info icon in top-right corner of the container */}
        <IconButton
          aria-label="Toggle Converter Instructions"
          icon={<InfoIcon />}
          size="sm"
          variant="ghost"
          colorScheme="blue"
          onClick={toggleInstructions}
          title="Converter Instructions"
          position="absolute"
          top="8px"
          right="8px"
          zIndex={1}
        />

        {/* Drag + Click zone, with some top margin so the icon is clearly above */}
        <Box
          as="label"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          p={5}
          mt={5}            // Add margin-top here so the icon doesn't sit on the border
          mb={4}
          border="2px dashed"
          borderColor={dropBorderColor}
          borderRadius="md"
          cursor="pointer"
          transition="background-color 0.2s"
          _hover={{ bg: inputBgHover }}
        >
          {!file ? (
            <Text fontSize="sm" color="gray.500">
              Click or drag a .CSV file here to upload
            </Text>
          ) : (
            <Text fontSize="sm" color="blue.500" fontWeight="medium">
              Selected file: {file.name}
            </Text>
          )}
          {/* Hidden file input */}
          <Input type="file" accept=".csv" onChange={handleFileChange} hidden />
        </Box>

        {/* Upload button */}
        <Button onClick={handleUpload} isDisabled={!file} colorScheme="blue" size="sm">
          Upload and Convert
        </Button>
      </Box>

      {/* Instructions panel */}
      {instructionsVisible && (
        <Box
          borderWidth="1px"
          borderColor={borderColor}
          borderRadius="md"
          bg={bgBoxColor}
          p={4}
          shadow="md"
          mb={4}
        >
          <ConverterInstructions />
        </Box>
      )}
    </Flex>
  );
}

export default TruckConverter;
