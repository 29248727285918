// src/components/WeeklyProfitabilityTable.js
import React, { useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  useColorModeValue,
  Icon,
} from "@chakra-ui/react";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";

function calculateValues(data) {
  const weekNetSales = parseFloat(data?.currentWeek?.netSales ?? 0);
  const monthNetSales = parseFloat(data?.netSalesMonth ?? 0);
  const weekLabor = parseFloat(data?.currentWeek?.labor ?? 0);
  const monthLabor = parseFloat(data?.totalLaborMonth ?? 0);
  const hoursFullWeek = parseFloat(data?.profitData?.hours_full_week ?? 0);
  const totalHoursForMonth = parseFloat(data?.profitData?.totalHoursForMonth ?? 0);

  const weekProduction = hoursFullWeek ? weekNetSales / hoursFullWeek : 0;
  const monthProduction = totalHoursForMonth ? monthNetSales / totalHoursForMonth : 0;
  const weekLaborPercentage = weekNetSales ? weekLabor / weekNetSales : 0;
  const monthLaborPercentage = monthNetSales ? monthLabor / monthNetSales : 0;

  return {
    weekProduction,
    monthProduction,
    weekLaborPercentage,
    monthLaborPercentage,
  };
}

export function WeeklyProfitabilityTable({ weeklyProfitabilityData }) {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const containerBg = useColorModeValue("gray.50", "gray.800");

  if (!weeklyProfitabilityData) {
    return <Text>No profitability data available.</Text>;
  }

  const allData = Object.entries(weeklyProfitabilityData).map(([storeId, data]) => {
    const { weekProduction, monthProduction, weekLaborPercentage, monthLaborPercentage } =
      calculateValues(data);

    return {
      supervisorName: data?.supervisorName ?? "N/A",
      storeName: data?.storeName ?? "N/A",
      weekProduction: isNaN(weekProduction) ? "N/A" : weekProduction,
      monthProduction: isNaN(monthProduction) ? "N/A" : monthProduction,
      weekLaborPercentage: isNaN(weekLaborPercentage) ? "N/A" : weekLaborPercentage * 100,
      monthLaborPercentage: isNaN(monthLaborPercentage) ? "N/A" : monthLaborPercentage * 100,
      foodVarianceWeek: data?.profitData?.food_variance_week ?? "N/A",
      foodVarianceMonth: data?.profitData?.food_variance_month ?? "N/A",
      overshortWeek: data?.currentWeek?.overshort ?? "N/A",
      overshortMonth: data?.profitData?.totalOvershortMonth ?? "N/A",
    };
  });

  const headers = [
    { key: "supervisorName", label: "Supervisor" },
    { key: "storeName", label: "Store" },
    { key: "weekProduction", label: "Crew Production (Week)" },
    { key: "monthProduction", label: "Crew Production (Month)" },
    { key: "weekLaborPercentage", label: "Crew Labor (Week)" },
    { key: "monthLaborPercentage", label: "Crew Labor (Month)" },
    { key: "foodVarianceWeek", label: "Variance (Week)" },
    { key: "foodVarianceMonth", label: "Variance (Month)" },
    { key: "overshortWeek", label: "Cash +/- (Week)" },
    { key: "overshortMonth", label: "Cash +/- (Month)" },
  ];

  const handleSort = (key) => {
    if (sortConfig.key === key) {
      setSortConfig({
        key,
        direction: sortConfig.direction === "asc" ? "desc" : "asc",
      });
    } else {
      setSortConfig({ key, direction: "asc" });
    }
  };

  const parseIfNumeric = (val) => {
    if (val === "N/A") return Number.NaN;
    const parsed = parseFloat(val);
    return isNaN(parsed) ? val : parsed;
  };

  const sortedData = [...allData].sort((a, b) => {
    const { key, direction } = sortConfig;
    if (!key) return 0;
    let valA = parseIfNumeric(a[key]);
    let valB = parseIfNumeric(b[key]);

    if (typeof valA === "number" && typeof valB === "number") {
      if (isNaN(valA)) return 1;
      if (isNaN(valB)) return -1;
      return direction === "asc" ? valA - valB : valB - valA;
    }
    if (typeof valA === "string" && typeof valB === "string") {
      return direction === "asc"
        ? valA.localeCompare(valB)
        : valB.localeCompare(valA);
    }
    return 0;
  });

  const renderSortIcon = (colKey) => {
    if (sortConfig.key !== colKey) {
      return <FaSort />;
    }
    return sortConfig.direction === "asc" ? <FaSortUp /> : <FaSortDown />;
  };

  return (
    <Box w="100%" maxW="100%" overflowX="auto" bg={containerBg} p={2}>
      <TableContainer maxW="100%" overflowX="auto">
        <Table variant="striped" colorScheme="orange" size="md" minW="900px">
          <Thead>
            <Tr>
              {headers.map((h) => (
                <Th
                  key={h.key}
                  onClick={() => handleSort(h.key)}
                  cursor="pointer"
                  whiteSpace="nowrap"
                >
                  {h.label} <Icon as={() => renderSortIcon(h.key)} ml={1} />
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {sortedData.map((row, idx) => (
              <Tr key={idx}>
                <Td>{row.supervisorName}</Td>
                <Td>{row.storeName}</Td>
                <Td>
                  {row.weekProduction === "N/A"
                    ? "N/A"
                    : row.weekProduction.toFixed(2)}
                </Td>
                <Td>
                  {row.monthProduction === "N/A"
                    ? "N/A"
                    : row.monthProduction.toFixed(2)}
                </Td>
                <Td>
                  {row.weekLaborPercentage === "N/A"
                    ? "N/A"
                    : row.weekLaborPercentage.toFixed(2)}
                </Td>
                <Td>
                  {row.monthLaborPercentage === "N/A"
                    ? "N/A"
                    : row.monthLaborPercentage.toFixed(2)}
                </Td>
                <Td>{row.foodVarianceWeek}</Td>
                <Td>{row.foodVarianceMonth}</Td>
                <Td>{row.overshortWeek}</Td>
                <Td>{row.overshortMonth}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}
