import gradeKPI from "./GradeKPIs";
import { rankStoresByKPI } from "./RankKPIs"; // optional
import { calculateDynamicScores } from "./DynamicScoring"; // optional

/**
 * calculateScores takes the array of store data + gradingStandards + optional areaAverages
 * 1) Grades each KPI
 * 2) Optionally uses rank-based logic or dynamic scores
 * 3) Returns an updated array with overallScore, totalGreen, etc.
 */
const calculateScores = (storesData, gradingStandards, areaAveragesState) => {
  const kpiKeys = [
    "LaborPercentage",
    "CrewProduction",
    "OnTimesPercentage",
    "FormattedAveTime",
    "FormattedReplyTime",
    "SalesChange",
    "ComplaintsPer10k",
    "FormattedCash",
    "FoodVarianceMonth",
    "ContExpenses",
  ];

  // rank
  const kpiRankings = {};
  kpiKeys.forEach((kpiKey) => {
    kpiRankings[kpiKey] = rankStoresByKPI(storesData, kpiKey);
  });

  let firstPlaceCounts = {};
  kpiKeys.forEach((kpiKey) => {
    Object.entries(kpiRankings[kpiKey]).forEach(([storeId, rank]) => {
      if (rank === 1) {
        firstPlaceCounts[storeId] = (firstPlaceCounts[storeId] || 0) + 1;
      }
    });
  });

  // dynamic scoring (optional)
  const dynamicScores = calculateDynamicScores(
    storesData,
    kpiRankings,
    storesData.length,
    areaAveragesState
  );

  // Calculate final scores
  return storesData.map((store) => {
    const storeId = store.storeId;
    const storeName = store.storeName; // for debugging

    // We build the string for the store’s SalesChangeForGrading
    const salesChangeForScoringStr = `${store.SalesChangeForGrading.toFixed(2)}%`;

    let totalGreen = 0;
    const checkAndCountGreen = (res) => {
      if (res.color === "green") totalGreen++;
      return res.points;
    };

    // Make sure each KPI's value is a string that can be parsed by gradeKPI
    const laborPctStr = store.LaborPercentage || "N/A";
    const crewProdStr = (store.CrewProduction || "").replace(/[$,]/g, "");
    const onTimesStr = store.OnTimesPercentage || "N/A";
    const aveTimeStr = store.FormattedAveTime || "N/A";
    const replyTimeStr = store.FormattedReplyTime || "N/A";
    const salesChStr = salesChangeForScoringStr; // use the computed for grading
    const complaintsStr = store.ComplaintsPer10k || "N/A";
    const cashStr = store.FormattedCash || "N/A";
    const foodVarStr = store.FoodVarianceMonth || "N/A";
    const contExpStr = (store.ContExpenses || "").replace("%", "");

    // Grade each KPI
    const scores = {
      laborScore: checkAndCountGreen(
        gradeKPI.LaborPercentage(laborPctStr, gradingStandards)
      ),
      crewProductionScore: checkAndCountGreen(
        gradeKPI.CrewProduction(crewProdStr, gradingStandards)
      ),
      onTimesScore: checkAndCountGreen(
        gradeKPI.OnTimesPercentage(onTimesStr, gradingStandards)
      ),
      aveTimeScore: checkAndCountGreen(
        gradeKPI.FormattedAveTime(aveTimeStr, gradingStandards)
      ),
      replyTimeScore: checkAndCountGreen(
        gradeKPI.FormattedReplyTime(replyTimeStr, gradingStandards)
      ),
      salesChangeScore: checkAndCountGreen(
        gradeKPI.SalesChange(salesChStr, gradingStandards)
      ),
      complaintsPer10kScore: checkAndCountGreen(
        gradeKPI.ComplaintsPer10k(complaintsStr, gradingStandards)
      ),
      cashScore: checkAndCountGreen(
        gradeKPI.FormattedCash(cashStr, gradingStandards)
      ),
      foodVarianceScore: checkAndCountGreen(
        gradeKPI.FoodVarianceMonth(foodVarStr, gradingStandards)
      ),
      contExpensesScore: checkAndCountGreen(
        gradeKPI.ContExpenses(contExpStr, gradingStandards)
      ),
    };

    // optional ranking
    const rankings = {
      laborRank: kpiRankings["LaborPercentage"][storeId],
      crewProductionRank: kpiRankings["CrewProduction"][storeId],
      onTimesRank: kpiRankings["OnTimesPercentage"][storeId],
      aveTimeRank: kpiRankings["FormattedAveTime"][storeId],
      replyTimeRank: kpiRankings["FormattedReplyTime"][storeId],
      salesChangeRank: kpiRankings["SalesChange"][storeId],
      complaintsPer10kRank: kpiRankings["ComplaintsPer10k"][storeId],
      cashRank: kpiRankings["FormattedCash"][storeId],
      foodVarianceRank: kpiRankings["FoodVarianceMonth"][storeId],
      contExpensesRank: kpiRankings["ContExpenses"][storeId],
    };

    const dynamicScore =
      dynamicScores.find((s) => s.storeId === storeId)?.dynamicScore || 0;
    const totalScore = Object.values(scores).reduce((acc, x) => acc + x, 0);
    const firstPlaces = firstPlaceCounts[storeId] || 0;

    // You could add some bonus for first places, e.g. * 1, or omit:
    const overallScore = totalScore + dynamicScore + firstPlaces * 0;

    // Debug log for store scoring
    console.log(
      `[Scoring] Store "${storeName}" => Scores:`,
      scores,
      `| dynamicScore=${dynamicScore}, firstPlaces=${firstPlaces}, totalScore=${totalScore}, overallScore=${overallScore}`
    );

    return {
      ...store,
      totalGreen,
      rankings,
      dynamicScore,
      firstPlaces,
      overallScore,
      totalScore,
    };
  });
};

export default calculateScores;
