/**
 * File: src/components/ProlifiField/Schedules/super/ChakraConfirmModal.jsx
 *
 * We add setIsConfirmModalOpen(false) (or any "close" logic)
 * in handleConfirm() whenever variantIds.length === 0,
 * so the modal disappears if everything is "Yes."
 */

import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  // ModalCloseButton,  // <--- remove to block closing
  Button,
  Text,
  VStack,
  Box,
  Switch,
  Divider,
  Flex
} from '@chakra-ui/react';

/**
 * Helper => if subject is "Airpark - General Visit" => "Airpark"
 */
function getStoreNameFromSubject(subject) {
  if (typeof subject !== 'string') return subject || '';
  const idx = subject.indexOf(' - ');
  if (idx === -1) return subject;
  return subject.substring(0, idx).trim();
}

export default function ChakraConfirmModal({
  isOpen,
  shifts = [],
  onSave,             // function(confirmedIds)
  onVariantNeeded,    // function(variantIds)
  // ADDED: pass in a "forceClose()" from parent, or pass setIsConfirmModalOpen
  forceClose,         // function => sets "isConfirmModalOpen(false)"
}) {
  const [yesMap, setYesMap] = useState({});

  // On mount => default all SHIFTs to "No" (false) or some logic
  useEffect(() => {
    const defaultMap = {};
    shifts.forEach((sh) => {
      defaultMap[sh.id] = false;
    });
    setYesMap(defaultMap);
  }, [shifts]);

  function handleToggle(shiftId) {
    setYesMap(prev => ({
      ...prev,
      [shiftId]: !prev[shiftId],
    }));
  }

  function handleConfirm() {
    const confirmedIds = [];
    const variantIds = [];

    shifts.forEach((sh) => {
      if (yesMap[sh.id]) {
        confirmedIds.push(sh.id);
      } else {
        variantIds.push(sh.id);
      }
    });

    // If all are "Yes," variantIds.length=0 => close the modal
    if (variantIds.length === 0) {
      // Confirm them in DB
      if (confirmedIds.length > 0) {
        onSave(confirmedIds);
      }
      // Now forcibly close the modal => no SHIFT remain
      if (typeof forceClose === 'function') {
        forceClose();
      }
      return;
    }

    // Otherwise => partial confirm
    if (confirmedIds.length > 0) {
      onSave(confirmedIds);
    }
    onVariantNeeded(variantIds);

    // The parent logic might hide this modal and show Variant next
    // or you can forcibly close if you want
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {}}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size="xl"
      isCentered
    >
      <ModalOverlay />
      <ModalContent bg="white" borderRadius="md" boxShadow="lg" overflow="hidden">
        {/* No <ModalCloseButton /> => blocking */}
        <ModalHeader fontWeight="bold" fontSize="lg" borderBottomWidth="1px">
          Confirm Past Shifts
          <Text fontSize="sm" mt={1} color="gray.600">
            Are these shifts accurate to what you recently worked?
          </Text>
        </ModalHeader>

        <ModalBody py={3}>
          <VStack align="stretch" spacing={2}>
            {shifts.map((shift, idx) => {
              const startObj = new Date(shift.start_time);
              const endObj   = new Date(shift.end_time);
              const dateStr = startObj.toLocaleDateString(undefined, {
                weekday: 'long',
                month: 'short',
                day: 'numeric',
              });
              const timeStr = `${startObj.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit'
              })} - ${endObj.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit'
              })}`;
              const storeName = getStoreNameFromSubject(shift.subject);

              return (
                <Box key={shift.id} w="100%">
                  {idx > 0 && <Divider mb={2} borderColor="gray.100" />}
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    flexWrap="wrap"
                  >
                    <Box>
                      <Text fontWeight="bold" fontSize="sm">
                        {dateStr}
                      </Text>
                      <Text fontSize="xs" color="gray.600">
                        {storeName} ({timeStr})
                      </Text>
                    </Box>

                    <Flex gap={2} alignItems="center">
                      <Text fontSize="sm" color="red.600">
                        No
                      </Text>
                      <Switch
                        size="md"
                        isChecked={yesMap[shift.id] || false}
                        onChange={() => handleToggle(shift.id)}
                        colorScheme="teal"
                      />
                      <Text fontSize="sm" color="green.600">
                        Yes
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
              );
            })}
          </VStack>
        </ModalBody>

        <ModalFooter borderTopWidth="1px" borderColor="gray.200">
          {/* Single button => user must pick Yes or No for each SHIFT, then Save */}
          <Button colorScheme="blue" onClick={handleConfirm}>
            Save
          </Button>
          {/* No Cancel => user can't escape */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
