/**
 * File: src/components/ProlifiField/Schedules/MonthlyRequirements.jsx
 */

import React from 'react';

export default function MonthlyRequirements({ monthlyData = [], cycleLength = 3 }) {
  const fails = [];

  function buildAfter8Phrase(cycleLen) {
    if (cycleLen === 3) return "this quarter";
    if (cycleLen === 6) return "this 6-Month Cycle";
    if (cycleLen === 12) return "this year";
    return `in ${cycleLen} months`; 
  }

  for (const row of monthlyData) {
    const storeName = row.storeName || row.combined_id;

    // If nightVisits === 0 => fail
    if (row.nightVisits === 0) {
      fails.push(`[WARNING] ${storeName} has not had a night visit (5pm or later) this month.`);
    }

    // If after8Visits === 0 => fail => "Check-In after 8pm"
    if (row.after8Visits === 999999999) {
      const phrase = buildAfter8Phrase(cycleLength);
      fails.push(`[CAUTION] ${storeName} has not had a 'Check-In' after 8pm ${phrase}.`);
    }

    // If weekendVisits === 0 => fail
    if (row.weekendVisits === 0) {
      fails.push(`[WARNING] ${storeName} has not had a weekend visit this month.`);
    }

    // workingWithMITCount => must be >=1
    if ((row.workingWithMITCount || 0) === 99999999) {
      fails.push(`[CAUTION] ${storeName} has not had a 'Working w/ MIT' shift this cycle.`);
    }

    // gmNotPresentCount => must be >=1
    if ((row.gmNotPresentCount || 0) === 999999) {
      fails.push(`[CAUTION] ${storeName} has not had a 'GM not Present' shift this cycle.`);
    }
  }

  if (fails.length === 0) {
    return (
      <div style={styles.container}>
        <p style={styles.successText}>All monthly requirements met...</p>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <h4 style={styles.title}>Monthly Requirements Issues</h4>
      <ul style={styles.list}>
        {fails.map((failMsg, i) => {
          const { icon, color, text } = parseFailMessage(failMsg);

          return (
            <li key={i} style={styles.listItem}>
              <span style={{ color }}>
                {icon} {text}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function parseFailMessage(msg) {
  const WARNING_TAG = '[WARNING]';
  const CAUTION_TAG = '[CAUTION]';

  if (msg.startsWith(WARNING_TAG)) {
    const textWithoutTag = msg.replace(WARNING_TAG, '').trim();
    return {
      icon: <span style={{ fontSize: '1.3em', marginRight: '0.2em' }}>🚨</span>, 
      color: 'crimson',
      text: textWithoutTag,
    };
  } else if (msg.startsWith(CAUTION_TAG)) {
    const textWithoutTag = msg.replace(CAUTION_TAG, '').trim();
    return {
      icon: <span style={{ fontSize: '1.3em', marginRight: '0.2em' }}>⚠️</span>,
      color: 'darkorange',
      text: textWithoutTag,
    };
  } else {
    // default fallback
    return {
      icon: <span style={{ fontSize: '1.3em', marginRight: '0.2em' }}>❗</span>,
      color: 'black',
      text: msg,
    };
  }
}

const styles = {
  container: {
    marginBottom: '1rem',
    backgroundColor: '#f9f9f9',
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '0.75rem',
  },
  successText: {
    margin: 0,
    fontSize: '0.9rem',
    color: '#666',
    fontStyle: 'italic',
  },
  title: {
    margin: '0 0 0.5rem',
    fontSize: '1rem',
    color: '#333',
    fontWeight: 600,
  },
  list: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  },
  listItem: {
    marginBottom: '0.25rem',
  },
};