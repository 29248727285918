// src/components/BaseLayout.js

import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Box, Button, Flex } from "@chakra-ui/react";
import axios from "axios";

import NavBar from "./NavBar";

function BaseLayout({ username, userGroup }) {
  const navigate = useNavigate();

  /**
   * A small helper to dynamically build the base URL
   * depending on environment / domain.
   */
  const getBaseUrl = () => {
    const hostname = window.location.hostname;
    if (hostname === "72.167.34.236") {
      return "http://72.167.34.236:5000";
    } else if (/^(www\.)?prolifi\.app$/.test(hostname)) {
      return "https://prolifi.app";
    } else {
      // Fallback (possibly localhost)
      return "";
    }
  };

  const handleLogout = async () => {
    try {
      // Build the logout endpoint with the proper base URL
      const baseUrl = getBaseUrl();
      await axios.get(`${baseUrl}/api/auth/logout`, { withCredentials: true });
      // Redirect to login
      navigate("/login");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    // Root container forced to exactly 100vh
    <Box 
      height="100vh" 
      display="flex" 
      flexDirection="column"
    >
      {/* Top NavBar */}
      <NavBar username={username} userGroup={userGroup} />

      {/*
        Main content area:
        - flex="1" so it takes up remaining space
        - overflowY="auto" so if content is too tall, it scrolls
      */}
      <Box 
        as="main" 
        flex="1" 
        overflowY="auto"
      >
        <Outlet />
      </Box>

      {/* Footer pinned to the bottom (no flex grow) */}
      <Box as="footer" bg="gray.100" p={1}>
        <Flex justify="flex-end">
          <Button size="sm" colorScheme="red" onClick={handleLogout}>
            Logout
          </Button>
        </Flex>
      </Box>
    </Box>
  );
}

export default BaseLayout;
