/**
 * File: src/utils/getBaseUrl.js
 * A simple utility function to determine the base URL for axios calls
 */

export const getBaseUrl = () => {
  const host = window.location.host;
  // If we are on the IP-based domain
  if (host.includes('72.167.34.236:3000')) {
    return 'http://72.167.34.236:5000/api';
  }
  // Otherwise default to our named domain
  return 'https://prolifi.app/api';
};
