import React, { useState, useMemo } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
} from '@chakra-ui/react';

import { FiPrinter } from 'react-icons/fi';
import UsernameUploadModal from './UsernameUploadModal';

// <-- Import your new PDF helper
import { generateIncompletesPDF } from '../utils/tzPDFHelpers'; 
// (Adjust the import path as needed)

export default function IncompletesModal({ open, onClose, incompletes, onRefresh }) {
  const [isUsernameModalOpen, setIsUsernameModalOpen] = useState(false);

  const handleOpenUsernameUploadModal = () => {
    setIsUsernameModalOpen(true);
  };

  const handleUsernameUploadSuccess = () => {
    setIsUsernameModalOpen(false);
    if (onRefresh) {
      onRefresh();
    }
  };

  const handleUsernameUploadCancel = () => {
    setIsUsernameModalOpen(false);
  };

  function transformToFirstLast(fullName) {
    if (!fullName) return '';
    const trimmed = fullName.trim();
    if (trimmed.includes(',')) {
      const [last, first] = trimmed.split(',').map((s) => s.trim());
      return `${capitalize(first)} ${capitalize(last)}`;
    } else {
      return trimmed
        .split(/\s+/)
        .map((s) => capitalize(s))
        .join(' ');
    }
  }

  function capitalize(str) {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  // Build your grouped data
  const groupedData = useMemo(() => {
    if (!incompletes) return [];
    const filtered = incompletes.filter(item =>
      !item.trainingTitle.toLowerCase().includes('live free l!ve')
    );
    const mapObj = new Map();
    filtered.forEach((item) => {
      const displayName = transformToFirstLast(item.userFullName);
      const uname = item.username || null;
      const key = `${displayName}||${uname || ''}`;

      if (!mapObj.has(key)) {
        mapObj.set(key, {
          name: displayName,
          username: uname,
          objectives: [],
        });
      }
      const trainingStr = item.trainingTitle + (item.ctp ? ` (${item.ctp})` : '');
      mapObj.get(key).objectives.push(trainingStr);
    });
    return Array.from(mapObj.values());
  }, [incompletes]);

  // Determine max number of objectives for columns
  const maxObjectives = useMemo(() => {
    let max = 0;
    groupedData.forEach((emp) => {
      if (emp.objectives.length > max) {
        max = emp.objectives.length;
      }
    });
    return max;
  }, [groupedData]);

  // The function that calls our PDF helper
  const handlePrintEmployees = async () => {
    try {
      await generateIncompletesPDF(groupedData); 
      // Optionally pass a second argument if you want a custom filename
      // e.g. generateIncompletesPDF(groupedData, 'My_Custom_Filename.pdf');
    } catch (error) {
      alert('Failed to generate PDF.');
    }
  };

  return (
    <>
      <Modal isOpen={open} onClose={onClose} size="4xl">
        <ModalOverlay />
        <ModalContent>
          {/* Header with "Incomplete Objectives" and an IconButton to print */}
          <ModalHeader position="relative">
            <Text>Incomplete Objectives</Text>

            {/* Icon for printing, near the close button */}
            <IconButton
              aria-label="Print employees"
              icon={<FiPrinter />}
              onClick={handlePrintEmployees}
              variant="ghost"
              position="absolute"
              top="8px"
              right="45px"
            />

            <ModalCloseButton />
          </ModalHeader>

          <ModalBody>
            {groupedData.length === 0 ? (
              <Text color="#555">No incomplete data available.</Text>
            ) : (
              <Table variant="striped" size="sm">
                <Thead>
                  <Tr>
                    <Th rowSpan={2}>Employee Name</Th>
                    <Th rowSpan={2}>Employee Username</Th>
                    <Th colSpan={maxObjectives} textAlign="center">
                      Missing Objectives
                    </Th>
                  </Tr>
                  <Tr>
                    {Array.from({ length: maxObjectives }).map((_, idx) => (
                      <Th key={idx}>Objective {idx + 1}</Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {groupedData.map((emp, rowIdx) => (
                    <Tr key={rowIdx}>
                      <Td>{emp.name}</Td>
                      <Td>
                        {emp.username ? (
                          emp.username
                        ) : (
                          <Text
                            as="span"
                            fontSize="xs"
                            color="blue.600"
                            cursor="pointer"
                            onClick={handleOpenUsernameUploadModal}
                            textDecoration="underline"
                          >
                            Upload Username Report
                          </Text>
                        )}
                      </Td>
                      {emp.objectives.map((obj, objIdx) => (
                        <Td key={objIdx}>{obj}</Td>
                      ))}
                      {emp.objectives.length < maxObjectives &&
                        Array.from({ length: maxObjectives - emp.objectives.length }).map(
                          (_, exIdx) => <Td key={`empty-${exIdx}`} />
                        )}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}
          </ModalBody>

          <ModalFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* USERNAME UPLOAD MODAL */}
      <UsernameUploadModal
        isOpen={isUsernameModalOpen}
        onSuccess={handleUsernameUploadSuccess}
        onCancel={handleUsernameUploadCancel}
      />
    </>
  );
}
