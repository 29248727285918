// src/index.js
import React, { useState, useMemo } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

// Chakra UI
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Modal from 'react-modal';

// (In your top-level file like index.js OR your main app component)

// 1) Base and Layout styles
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-layouts/styles/material.css';

// 2) Syncfusion components that the Scheduler depends on
import '@syncfusion/ej2-buttons/styles/material.css';
import '@syncfusion/ej2-calendars/styles/material.css';
import '@syncfusion/ej2-dropdowns/styles/material.css';
import '@syncfusion/ej2-inputs/styles/material.css';
import '@syncfusion/ej2-lists/styles/material.css';
import '@syncfusion/ej2-navigations/styles/material.css';
import '@syncfusion/ej2-popups/styles/material.css';

// 3) Finally, the actual Scheduler’s styles
import '@syncfusion/ej2-react-schedule/styles/material.css';



// Syncfusion license
import { registerLicense } from "@syncfusion/ej2-base";
try {
  // Register license for v28
registerLicense('Ngo9BigBOggjHTQxAR8/V1NMaF5cXmBCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdmWX5edHVSRGJeUER3WkM=');

  
} catch (err) {
  console.error("Error registering Syncfusion license:", err);
}



export const ColorModeContext = React.createContext({
  toggleColorMode: () => {},
});

Modal.setAppElement('#root');

function Main() {
  const [mode, setMode] = useState("light");

  const colorModeCtx = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prev) => (prev === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = extendTheme({
    config: {
      initialColorMode: mode,
      useSystemColorMode: false,
    },
  });

  return (
    <DndProvider backend={HTML5Backend}>
      <ColorModeContext.Provider value={colorModeCtx}>
        <ChakraProvider theme={theme}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ChakraProvider>
      </ColorModeContext.Provider>
    </DndProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Main />);

reportWebVitals();
