// File: src/components/ProlifiField/Schedules/DirectorScheduleSection.jsx

import React, {
  useRef, useEffect, forwardRef, useImperativeHandle, useState
} from 'react';
import {
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  TimelineViews,
  Month,
  DragAndDrop,
  Resize,
  ResourcesDirective,
  ResourceDirective,
  Inject
} from '@syncfusion/ej2-react-schedule';
import axios from 'axios';

import CustomModal from './super/CustomModal';
import DirectorCustomModal from './director/DirectorCustomModal';
import ShiftTooltip from './super/ShiftTooltip';
import { validateShift } from '../../../utils/shiftValidation';
import { getBaseUrl } from '../../../utils/getBaseUrl';

const DIRECTOR_RESOURCE_ID = -999;

// For bar text
function shiftDurationTemplate(props) {
  const st = new Date(props.StartTime);
  const et = new Date(props.EndTime);
  const startStr = st.toLocaleTimeString([], { hour:'2-digit', minute:'2-digit' });
  const endStr   = et.toLocaleTimeString([], { hour:'2-digit', minute:'2-digit' });

  return (
    <div style={{ padding:'2px' }}>
      <div style={{ fontWeight:'bold' }}>{props.Subject}</div>
      <div>{startStr} - {endStr}</div>
    </div>
  );
}

// Convert JS Date => local "YYYY-MM-DD HH:mm:ss"
function toLocalDateTimeString(dateObj) {
  if (!(dateObj instanceof Date) || isNaN(dateObj)) return '';
  const yyyy = dateObj.getFullYear();
  const mm   = String(dateObj.getMonth() + 1).padStart(2,'0');
  const dd   = String(dateObj.getDate()).padStart(2,'0');
  const HH   = String(dateObj.getHours()).padStart(2,'0');
  const MM   = String(dateObj.getMinutes()).padStart(2,'0');
  const SS   = String(dateObj.getSeconds()).padStart(2,'0');
  return `${yyyy}-${mm}-${dd} ${HH}:${MM}:${SS}`;
}

const styles = {
  container: {
    display:'flex',
    flex:1,
    overflow:'hidden',
    height:'100%',
    maxHeight:'99vh'
  }
};

const DirectorScheduleSection = forwardRef(function DirectorScheduleSection(props, ref) {
  const {
    events,
    setEvents,
    supervisorResource,
    onDateRangeChange,
    expandCollapseAndSwitchView,
    startDate,
    endDate,
    onEventsChanged,
    directorStores
  } = props;

  const scheduleRef = useRef(null);
  const originalTimesRef = useRef({}); // store old times during drag

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);

  const [isDirectorModalOpen, setIsDirectorModalOpen] = useState(false);
  const [directorEventBeingEdited, setDirectorEventBeingEdited] = useState(null);

  useImperativeHandle(ref, () => ({
    switchView,
    refresh() {
      scheduleRef.current?.refreshEvents();
      scheduleRef.current?.dataBind();
    }
  }));

  useEffect(() => {
    if (scheduleRef.current && (!startDate || !endDate)) {
      const vDates = scheduleRef.current.getCurrentViewDates();
      if (vDates?.length>0) {
        const earliest = new Date(vDates[0]);
        earliest.setHours(0,0,0,0);
        const latest   = new Date(vDates[vDates.length-1]);
        latest.setHours(23,59,59,999);
        onDateRangeChange?.(earliest.toISOString(), latest.toISOString());
      }
    }
  }, [onDateRangeChange, startDate, endDate]);

  function switchView(newView) {
    if (scheduleRef.current) {
      scheduleRef.current.currentView = newView;
      scheduleRef.current.dataBind();
    }
  }

  // DELETE (director)
  async function deleteDirectorEvent(id) {
    try {
      const baseUrl = getBaseUrl();
      await axios.delete(`${baseUrl}/pfield_events/pfield_director/directorEvent/${id}`, {
        withCredentials:true
      });
      console.log('[deleteDirectorEvent] =>', id);
    } catch (err) {
      console.error('Error deleting director event =>', err);
    }
  }

  // UPDATE (director) => drag/resize
  async function updateDirectorEvent(ev) {
    const startLocal = toLocalDateTimeString(ev.StartTime);
    const endLocal   = toLocalDateTimeString(ev.EndTime);

    // final object => log it right before axios
    const payload = {
      id: ev.Id,
      storeOrEvent: ev.storeOrEvent || 'store-visit',
      storeIds: ev.storeIds || [],
      startTime: startLocal,
      endTime:   endLocal,
      notes: ev.notes || []
    };
    console.log('[DirectorScheduleSection] => updateDirectorEvent => final payload:', payload);

    try {
      const baseUrl = getBaseUrl();
      const saveUrl = `${baseUrl}/pfield_events/pfield_director/saveDirectorEvent`;
      // now post
      const res = await axios.post(saveUrl, payload, { withCredentials:true });
      console.log('[updateDirectorEvent] => response:', res.data);
    } catch (err) {
      console.error('Error updating director event =>', err);
    }
  }

  // Double-click blank => new director event
  const handleCellDoubleClick = (args) => {
    args.cancel = true;
    const day = new Date(args.startTime);
    day.setHours(9,0,0,0);
    const dayEnd = new Date(day);
    dayEnd.setHours(17,0,0,0);

    const newEvent = {
      Id: null,
      SupervisorId: null,
      Subject: '',
      StartTime: day,
      EndTime:   dayEnd,
      Description:'',
      notes:[]
    };
    setDirectorEventBeingEdited(newEvent);
    setIsDirectorModalOpen(true);
  };

  // Double-click existing => only if director
  const handleEventDoubleClick = (args) => {
    args.cancel = true;
    const ev = args.event || {};
    if (ev.SupervisorId && ev.SupervisorId!==DIRECTOR_RESOURCE_ID) {
      alert('Supervisor shift => read-only');
      return;
    }
    setDirectorEventBeingEdited({ ...ev });
    setIsDirectorModalOpen(true);
  };

  // Called from DirectorCustomModal => final "save"
  async function handleSaveEvent(directorEvent) {
    console.log('[handleSaveEvent] =>', directorEvent);
    // We'll build local date strings here too
    const isUpdate = (directorEvent.Id && directorEvent.Id>0);
    const startLocal = toLocalDateTimeString(new Date(directorEvent.startTime));
    const endLocal   = toLocalDateTimeString(new Date(directorEvent.endTime));

    const payload = {
      id: isUpdate ? directorEvent.Id : null,
      storeOrEvent: directorEvent.storeOrEvent || '',
      storeIds: directorEvent.storeIds || [],
      startTime: startLocal,
      endTime:   endLocal,
      notes: directorEvent.notes || []
    };
    console.log('[DirectorScheduleSection] handleSaveEvent => final payload to axios:', payload);

    try {
      const baseUrl = getBaseUrl();
      const saveUrl = `${baseUrl}/pfield_events/pfield_director/saveDirectorEvent`;
      const res = await axios.post(saveUrl, payload, { withCredentials:true });
      console.log('[saveDirectorEvent => response] =>', res.data);

      if (isUpdate) {
        // local update
        setEvents(prev => prev.map(e => 
          (e.Id===directorEvent.Id ? { ...directorEvent, SupervisorId: DIRECTOR_RESOURCE_ID } : e)
        ));
      } else {
        if (res.data.insertedId) {
          directorEvent.Id = res.data.insertedId;
        }
        directorEvent.SupervisorId = DIRECTOR_RESOURCE_ID;
        setEvents(prev => [...prev, directorEvent]);
      }
    } catch (err) {
      console.error('Error saving director event =>', err);
    }

    setIsModalOpen(false);
    setIsDirectorModalOpen(false);
    scheduleRef.current?.refreshEvents();
    scheduleRef.current?.dataBind();
    onEventsChanged?.();
  }

  // DRAG/RESIZE => store original times in TimelineWeek
  function handleDragStart(args) {
    if (scheduleRef.current?.currentView === 'TimelineWeek') {
      const ev = Array.isArray(args.data)? args.data[0] : args.data;
      if (ev?.Id && ev.SupervisorId===DIRECTOR_RESOURCE_ID) {
        originalTimesRef.current[ev.Id] = {
          start: new Date(ev.StartTime),
          end:   new Date(ev.EndTime)
        };
      }
    }
  }
  function handleResizeStart(args) {
    handleDragStart(args);
  }

  // eventCreate, eventChange, eventRemove => check read-only, etc.
  const onActionBegin = async (args) => {
    const rt = args.requestType;

    if (['eventCreate','eventChange','eventRemove'].includes(rt)) {
      const changed = Array.isArray(args.data)? args.data : [args.data];
      for (const ev of changed) {
        // sup => block
        if (ev.SupervisorId && ev.SupervisorId!==DIRECTOR_RESOURCE_ID) {
          console.log('Blocked editing supervisor shift => read-only');
          args.cancel = true;
          return;
        }
      }
    }

    if (rt==='eventRemove') {
      const changed = Array.isArray(args.data)? args.data : [args.data];
      for (const ev of changed) {
        if (ev.SupervisorId===DIRECTOR_RESOURCE_ID) {
          args.cancel = true;
          await deleteDirectorEvent(ev.Id);
          setEvents(prev => prev.filter(x => x.Id!==ev.Id));
          scheduleRef.current?.refreshEvents();
          scheduleRef.current?.dataBind();
          onEventsChanged?.();
        }
      }
    }

    if (rt==='eventChange') {
      const changed = Array.isArray(args.data)? args.data : [args.data];
      for (const ev of changed) {
        if (ev.SupervisorId===DIRECTOR_RESOURCE_ID) {
          if (scheduleRef.current?.currentView==='TimelineWeek') {
            const orig = originalTimesRef.current[ev.Id];
            if (orig) {
              const newStart = new Date(ev.StartTime);
              const newEnd   = new Date(ev.EndTime);

              const oldStartH = orig.start.getHours();
              const oldStartM = orig.start.getMinutes();
              const oldEndH   = orig.end.getHours();
              const oldEndM   = orig.end.getMinutes();

              const newStartDay = new Date(
                newStart.getFullYear(), newStart.getMonth(), newStart.getDate(),
                oldStartH, oldStartM, 0
              );
              const newEndDay   = new Date(
                newEnd.getFullYear(), newEnd.getMonth(), newEnd.getDate(),
                oldEndH, oldEndM, 0
              );

              // clamp
              const dayDiff = (newEndDay - newStartDay)/(1000*60*60*24);
              if (dayDiff>1) {
                newEndDay.setHours(23,59,59,999);
              }
              ev.StartTime = newStartDay;
              ev.EndTime   = newEndDay;
            }
          }
          args.cancel = true;
          await updateDirectorEvent(ev);
          setEvents(prev => prev.map(x => x.Id===ev.Id ? ev : x));
          scheduleRef.current?.refreshEvents();
          scheduleRef.current?.dataBind();
          onEventsChanged?.();
        } else {
          console.log('Blocking row change => sup');
          args.cancel = true;
        }
      }
    }

    // Overlap checks
    if (rt==='eventCreate' || rt==='eventChange') {
      const { cancel, message } = validateShift(args, events, scheduleRef);
      if (cancel) {
        args.cancel = true;
        if (message) alert(message);
      }
    }
  };

  const onActionComplete = (args) => {
    if (args.requestType==='viewNavigate' || args.requestType==='dateNavigate') {
      setTimeout(() => {
        if (scheduleRef.current) {
          const vDates = scheduleRef.current.getCurrentViewDates();
          if (vDates?.length>0) {
            const earliest = new Date(vDates[0]);
            earliest.setHours(0,0,0,0);
            const latest   = new Date(vDates[vDates.length-1]);
            latest.setHours(23,59,59,999);
            onDateRangeChange?.(earliest.toISOString(), latest.toISOString());
          }
        }
      },0);
    }
  };

  const onPopupOpen = (args) => {
    if (args.type==='Editor' || args.type==='QuickInfo') {
      args.cancel = true;
    }
  };

  const onEventRendered = (args) => {
    if (args.data.SupervisorId && args.data.SupervisorId!==DIRECTOR_RESOURCE_ID) {
      const found = supervisorResource.find(r => r.id===args.data.SupervisorId);
      if (found) {
        args.element.style.backgroundColor = found.color;
      }
    } else if (args.data.SupervisorId===DIRECTOR_RESOURCE_ID) {
      args.element.style.backgroundColor = '#FFD700';
    }
  };

  return (
    <div style={styles.container}>
      {/* old optional CustomModal */}
      <CustomModal
        isOpen={isModalOpen}
        eventData={currentEvent}
        onClose={()=>setIsModalOpen(false)}
        onSave={handleSaveEvent}
        onDelete={()=>{}}
        allEvents={events}
      />

      <DirectorCustomModal
        isOpen={isDirectorModalOpen}
        onClose={()=>setIsDirectorModalOpen(false)}
        onSave={handleSaveEvent}
        availableStores={directorStores}
        staticItems={["Office","Meetings","Vacation"]}
        initialEvent={directorEventBeingEdited}
        blockScrollOnMount={false}
        trapFocus={false}
        closeOnOverlayClick={false}
      />

      <ScheduleComponent
        ref={scheduleRef}
        showQuickInfo={true}
        dragStart={handleDragStart}
        resizeStart={handleResizeStart}
        cellDoubleClick={handleCellDoubleClick}
        eventDoubleClick={handleEventDoubleClick}
        actionBegin={onActionBegin}
        actionComplete={onActionComplete}
        popupOpen={onPopupOpen}
        eventRendered={onEventRendered}
        selectedDate={new Date()}
        firstDayOfWeek={1}
        currentView="TimelineWeek"
        height="100%"
        width="100%"
        eventSettings={{
          dataSource: events,
          template: shiftDurationTemplate,
          fields: {
            id: 'Id',
            subject: { name:'Subject' },
            groupID: { name:'SupervisorId' },
            startTime:{ name:'StartTime' },
            endTime:  { name:'EndTime' },
            description:{ name:'Description' }
          }
        }}
      >
        <ResourcesDirective>
          <ResourceDirective
            field="SupervisorId"
            title="Supervisor / Director"
            name="Supervisor"
            dataSource={supervisorResource}
            textField="text"
            idField="id"
            colorField="color"
            allowMultiple={false}
            expanded={true}
          />
        </ResourcesDirective>

        <ViewsDirective>
          <ViewDirective
            option="TimelineWeek"
            startHour="00:00"
            endHour="24:00"
            timeScale={{ enable:false }}
            group={{ resources:['Supervisor'] }}
            eventSettings={{
              enableTooltip:false,
              tooltipTemplate:ShiftTooltip
            }}
          />
          <ViewDirective
            option="Month"
            group={{ resources:[] }}
            eventSettings={{
              enableTooltip:true,
              tooltipTemplate:ShiftTooltip
            }}
          />
        </ViewsDirective>

        <Inject services={[TimelineViews,Month,DragAndDrop,Resize]} />
      </ScheduleComponent>
    </div>
  );
});

export default DirectorScheduleSection;
