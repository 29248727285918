// File: SupervisorHoursOverlay.jsx

import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle
} from 'react';

const DIRECTOR_RESOURCE_ID = -999;

function SupervisorHoursOverlay({ supervisorHoursMap = {}, nameToIdMap = {} }, ref) {
  const [rowOverlays, setRowOverlays] = useState([]);
  const overlayRef = useRef(null);

  function recalcPositions() {
    // 1) Find container
    const resourceContainer =
      document.querySelector('.e-resource-column-wrap')
      || document.querySelector('.e-timeline-resource');

    if (!resourceContainer) {
      setRowOverlays([]);
      return;
    }

    // 2) Grab all .e-resource-cells
    const rowElems = resourceContainer.querySelectorAll('.e-resource-cells');
    if (!rowElems.length) {
      setRowOverlays([]);
      return;
    }

    const containerRect = resourceContainer.getBoundingClientRect();
    const newOverlays = [];

    rowElems.forEach((rowElem) => {
      // a) Try .e-resource-text, fallback to rowElem
      let textDiv = rowElem.querySelector('.e-resource-text');
      if (!textDiv) {
        textDiv = rowElem;
      }

      // b) Original text
      const originalRawText = textDiv.textContent || '';
      let resourceText = originalRawText.trim();
      if (!resourceText) return;

      // **Check for (Director) in the *original* text** => skip
      if (originalRawText.includes('(Director)')) {
        // This row is the Director row => skip overlay
        return;
      }

      // c) Remove any "✔" or "(Director)" that might remain
      // (some edge case if the check got appended without parentheses)
      resourceText = resourceText.replace(/✔/g, '');
      resourceText = resourceText.replace('(Director)', '');
      resourceText = resourceText.trim();

      // d) Lookup supId
      const supId = nameToIdMap[resourceText];
      if (supId == null) {
        return; // no match in map => skip
      }

      // e) Also skip if supId is -999 just in case
      if (supId === DIRECTOR_RESOURCE_ID) {
        return;
      }

      // f) Grab hours
      const hoursValue = supervisorHoursMap[supId] ?? 0;

      // g) Position the overlay
      const rowRect = rowElem.getBoundingClientRect();
      const offsetTop = rowRect.top - containerRect.top + 90;
      const offsetLeft = rowRect.left - containerRect.left + 6;

      newOverlays.push({
        supId,
        resourceText,
        hours: hoursValue,
        top: offsetTop,
        left: offsetLeft
      });
    });

    setRowOverlays(newOverlays);
  }

  useImperativeHandle(ref, () => ({
    recalcPositions
  }));

  useEffect(() => {
    recalcPositions();
    window.addEventListener('resize', recalcPositions);

    const resourceContainer =
      document.querySelector('.e-resource-column-wrap')
      || document.querySelector('.e-timeline-resource');

    if (resourceContainer) {
      resourceContainer.addEventListener('scroll', recalcPositions, { passive:true });
    }

    return () => {
      window.removeEventListener('resize', recalcPositions);
      if (resourceContainer) {
        resourceContainer.removeEventListener('scroll', recalcPositions);
      }
    };
  }, [supervisorHoursMap, nameToIdMap]);

  return (
    <div
      ref={overlayRef}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
        zIndex: 9999999
      }}
    >
      {rowOverlays.map((o) => {
        let textColor = '#333';
        let borderColor = '#ccc';
        let boxShadow = '0 1px 2px rgba(0,0,0,0.1)';

        if (o.hours < 45) {
          textColor = 'red';
          borderColor = 'red';
          boxShadow = '0 0 5px 2px rgba(255,0,0,0.3)';
        }

        return (
          <div
            key={o.supId}
            style={{
              position: 'absolute',
              top: o.top,
              left: o.left,
              backgroundColor: 'rgba(248,248,248,0.9)',
              color: textColor,
              fontSize: '0.6em',
              padding: '1.5px 4px',
              border: `1px solid ${borderColor}`,
              borderRadius: 2,
              boxShadow
            }}
          >
            {o.hours} hrs
          </div>
        );
      })}
    </div>
  );
}

export default forwardRef(SupervisorHoursOverlay);
