// src/components/WeeklySalesReport.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import {
  Box,
  Button,
  Select,
  Text,
  Flex,
  Collapse,
  useColorModeValue,
  Table,
  Tbody,
  Thead,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";

import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import GetAppIcon from "@mui/icons-material/GetApp";

import { WeeklySalesTable } from "./WeeklySalesTable";
import { WeeklyProfitabilityTable } from "./WeeklyProfitabilityTable";
import { WeeklyServiceTable } from "./WeeklyServiceTable";
import { aggregateDataForWeekGroups } from "./aggregateWeekGroupData";
import exportToExcel from "./exportToExcel";

/**
 * getMondayOfISOWeek: Return date for Monday of the given ISO week & year
 */
function getMondayOfISOWeek(week, year) {
  const simple = new Date(Date.UTC(year, 0, 1 + (week - 1) * 7));
  const dayOfWeek = simple.getUTCDay();
  const ISOweekStart = simple;
  if (dayOfWeek <= 4) {
    ISOweekStart.setUTCDate(simple.getUTCDate() - simple.getUTCDay() + 1);
  } else {
    ISOweekStart.setUTCDate(simple.getUTCDate() + 8 - simple.getUTCDay());
  }
  return ISOweekStart;
}

/**
 * getBaseUrl: Environment-based base URL
 */
function getBaseUrl() {
  if (process.env.NODE_ENV === "development") {
    return "http://72.167.34.236:5000";
  }
  return "https://prolifi.app";
}

/**
 * isLeapYear: Check if year is leap year
 */
function isLeapYear(year) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

/**
 * getDefaultWeekYear: Return {week, year} for last week
 */
function getDefaultWeekYear() {
  const today = new Date();
  const startOfYear = new Date(Date.UTC(today.getFullYear(), 0, 1));
  const daysSinceStartOfYear = (today - startOfYear) / (24 * 60 * 60 * 1000);
  const weekNo = Math.ceil((daysSinceStartOfYear + startOfYear.getUTCDay() + 1) / 7);

  let year = today.getFullYear();
  let week = weekNo - 1;
  if (week < 1) {
    year -= 1;
    week = 52;
    // If that previous year had 53 weeks
    if (
      new Date(year, 11, 31).getUTCDay() === 4 ||
      (new Date(year, 11, 31).getUTCDay() === 3 && isLeapYear(year))
    ) {
      week = 53;
    }
  }
  return { week, year };
}

const WeeklySalesReport = () => {
  const [salesData, setSalesData] = useState(null);
  const [missingSalesData, setMissingSalesData] = useState({});
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);

  // Toggle states
  const [isMissingSalesDataExpanded, setIsMissingSalesDataExpanded] = useState(false);
  const [isWeeklySalesExpanded, setIsWeeklySalesExpanded] = useState(false);
  const [isWeeklyProfitabilityExpanded, setIsWeeklyProfitabilityExpanded] = useState(false);
  const [isWeeklyServiceExpanded, setIsWeeklyServiceExpanded] = useState(false);

  // On mount => set default week/year
  useEffect(() => {
    const { week, year } = getDefaultWeekYear();
    setSelectedWeek(week);
    setSelectedYear(year);
  }, []);

  // Fetch data if selected week & year are valid
  useEffect(() => {
    if (selectedWeek != null && selectedYear != null) {
      const monday = getMondayOfISOWeek(selectedWeek, selectedYear);
      const sunday = new Date(monday);
      sunday.setDate(monday.getDate() + 6);

      const startDate = monday.toISOString().split("T")[0];
      const endDate = sunday.toISOString().split("T")[0];
      const baseUrl = getBaseUrl();

      axios
        .get(`${baseUrl}/api/weekly-sales/${startDate}/${endDate}`, { withCredentials: true })
        .then((response) => {
          setSalesData(response.data);
          setMissingSalesData(response.data.missingStoresWithSupervisors);
          console.log("Sales Data:", response.data);
        })
        .catch((error) => {
          console.error("Error fetching sales data:", error);
          Swal.fire("Error", "Failed to fetch sales data", "error");
        });
    }
  }, [selectedWeek, selectedYear]);

  // Week/year dropdown
  const handleWeekYearChange = (event) => {
    const [week, year] = event.target.value.split("-");
    setSelectedWeek(parseInt(week, 10));
    setSelectedYear(parseInt(year, 10));
  };

  // Build next 52 weeks
  const generateWeekOptions = () => {
    const today = new Date();
    const currentYear = today.getUTCFullYear();
    const options = [];

    const { week: nextWeek, year: nextYear } = getDefaultWeekYear();
    // push next week
    options.push({
      weekNumber: nextWeek,
      year: nextYear,
      dateRange: formatDateRange(getMondayOfISOWeek(nextWeek, nextYear)),
    });

    // push last 52 weeks
    for (let i = 0; i < 52; i++) {
      const weekNumber = nextWeek - i - 1 <= 0 ? 52 + (nextWeek - i - 1) : nextWeek - i - 1;
      const year = nextWeek - i - 1 <= 0 ? nextYear - 1 : currentYear;
      options.push({
        weekNumber,
        year,
        dateRange: formatDateRange(getMondayOfISOWeek(weekNumber, year)),
      });
    }
    return options;
  };

  // Format "Mon dd yyyy to Sun dd yyyy"
  const formatDateRange = (monday) => {
    const sunday = new Date(monday);
    sunday.setDate(monday.getDate() + 6);
    return (
      `${(monday.getUTCMonth() + 1).toString().padStart(2, "0")}-${monday
        .getUTCDate()
        .toString()
        .padStart(2, "0")}-${monday.getUTCFullYear()} to ` +
      `${(sunday.getUTCMonth() + 1).toString().padStart(2, "0")}-${sunday
        .getUTCDate()
        .toString()
        .padStart(2, "0")}-${sunday.getUTCFullYear()}`
    );
  };

  // Toggle bars
  const handleMissingSalesDataToggle = () => {
    setIsMissingSalesDataExpanded(!isMissingSalesDataExpanded);
    setIsWeeklySalesExpanded(false);
    setIsWeeklyProfitabilityExpanded(false);
    setIsWeeklyServiceExpanded(false);
  };
  const handleWeeklySalesToggle = () => {
    setIsMissingSalesDataExpanded(false);
    setIsWeeklySalesExpanded(!isWeeklySalesExpanded);
    setIsWeeklyProfitabilityExpanded(false);
    setIsWeeklyServiceExpanded(false);
  };
  const handleWeeklyProfitabilityToggle = () => {
    setIsMissingSalesDataExpanded(false);
    setIsWeeklySalesExpanded(false);
    setIsWeeklyProfitabilityExpanded(!isWeeklyProfitabilityExpanded);
    setIsWeeklyServiceExpanded(false);
  };
  const handleWeeklyServiceToggle = () => {
    setIsMissingSalesDataExpanded(false);
    setIsWeeklySalesExpanded(false);
    setIsWeeklyProfitabilityExpanded(false);
    setIsWeeklyServiceExpanded(!isWeeklyServiceExpanded);
  };

  // Gradients for toggle bars
  const toggleBg = useColorModeValue(
    "linear-gradient(to-r, teal.300, blue.300)",
    "linear-gradient(to-r, teal.700, blue.600)"
  );
  const toggleHoverBg = useColorModeValue(
    "linear-gradient(to-r, teal.400, blue.400)",
    "linear-gradient(to-r, teal.800, blue.700)"
  );
  const toggleTextColor = useColorModeValue("gray.800", "white");

  // Render toggle bar
  const renderToggleBar = (handleToggle, isExpanded, text, bg, hoverBg, textColor) => (
    <Box
      as="button"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      w="100%"
      maxW="100%"
      px={4}
      py={2}
      borderRadius="md"
      color={textColor}
      bg={bg}
      mb={2}
      onClick={handleToggle}
      _hover={{ bg: hoverBg }}
      shadow="md"
    >
      <Text fontWeight="semibold" fontSize="md" noOfLines={1}>
        {text}
      </Text>
      {isExpanded ? <FaAngleUp /> : <FaAngleDown />}
    </Box>
  );

  // Export
  function formatDate(date) {
    return `${(date.getUTCMonth() + 1).toString().padStart(2, "0")}-${date
      .getUTCDate()
      .toString()
      .padStart(2, "0")}-${date.getUTCFullYear()}`;
  }

  const handleExport = () => {
    if (salesData && salesData.weeklySalesData) {
      const weeklySalesArray = Object.keys(salesData.weeklySalesData).map((storeId) => ({
        storeId,
        ...salesData.weeklySalesData[storeId],
      }));

      const monday = getMondayOfISOWeek(selectedWeek, selectedYear);
      const sunday = new Date(monday);
      sunday.setDate(monday.getDate() + 6);

      const startDate = formatDate(monday);
      const endDate = formatDate(sunday);

      const aggregatedWeekGroupData = aggregateDataForWeekGroups(weeklySalesArray);
      const aoaAggregatedWeekGroupData = convertAggregatedDataToAoa(aggregatedWeekGroupData);

      exportToExcel(
        weeklySalesArray,
        aoaAggregatedWeekGroupData,
        startDate,
        endDate,
        selectedWeek,
        selectedYear
      );
    } else {
      console.error("No weekly sales data available for export.");
      Swal.fire("Error", "No weekly sales data available for export.", "error");
    }
  };

  const sortOrder = ["NWA", "CENTRAL AR", "TENNESSEE", "ST LOUIS", "CHAMPAIGN", "NEBRASKA", "KANSAS"];
  const sortData = (data) => {
    return data.sort((a, b) => {
      const indexA = sortOrder.indexOf(a[2]);
      const indexB = sortOrder.indexOf(b[2]);
      return indexA - indexB;
    });
  };

  const convertAggregatedDataToAoa = (aggregatedData) => {
    let aoa = Object.entries(aggregatedData).map(([weekGroup, data]) => [
      "",
      "",
      weekGroup,
      data.groupNetSalesThisYear,
      data.groupNetSalesLastYear,
      data.percentDifferenceSales,
      data.mgrLaborP,
      data.crewLaborP,
      data.totalLaborP,
      data.groupTicketsThisYear,
      data.groupTicketsLastYear,
      data.percentDifferenceTickets,
      data.ticketAverage,
      data.onTimePercent,
    ]);
    aoa = sortData(aoa);
    return aoa;
  };

  const weekOptions = generateWeekOptions();

  return (
    <Box w="100%" maxW="100%" overflowX="auto" p={4}>
      {/* Controls: Week Selector + Export */}
      <Flex mb={4} align="center" wrap="wrap" gap={4}>
        <Flex align="center" gap={2}>
          <Text fontWeight="semibold" fontSize="md" color={useColorModeValue("gray.800", "white")}>
            Select Week:
          </Text>
          {/* Make the select wider here */}
          <Select w="300px" value={`${selectedWeek}-${selectedYear}`} onChange={handleWeekYearChange}>
            {weekOptions.map(({ weekNumber, year, dateRange }) => (
              <option key={`${weekNumber}-${year}`} value={`${weekNumber}-${year}`}>
                Week {weekNumber}: {dateRange}
              </option>
            ))}
          </Select>
        </Flex>
        <Button colorScheme="teal" leftIcon={<GetAppIcon />} onClick={handleExport} variant="solid">
          Download Weekly Report
        </Button>
      </Flex>

      {/* Missing Sales Data */}
      <Box mb={4} borderWidth="1px" borderRadius="md" p={4} shadow="md">
        {renderToggleBar(
          handleMissingSalesDataToggle,
          isMissingSalesDataExpanded,
          "Missing Sales Data",
          toggleBg,
          toggleHoverBg,
          toggleTextColor
        )}
        <Collapse in={isMissingSalesDataExpanded} animateOpacity>
          <Box mt={2} overflowX="auto" maxW="100%">
            <TableContainer maxW="100%" overflowX="auto">
              <Table variant="simple" size="sm" minW="600px">
                <Thead>
                  <Tr>
                    <Th>Supervisor Name</Th>
                    <Th>Stores Missing Data</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {Object.entries(missingSalesData).map(([supervisor, stores], idx) => (
                    <Tr key={idx}>
                      <Td>{supervisor}</Td>
                      <Td>{stores.join(", ")}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </Collapse>
      </Box>

      {/* Weekly Sales */}
      <Box mb={4} borderWidth="1px" borderRadius="md" p={4} shadow="md">
        {renderToggleBar(
          handleWeeklySalesToggle,
          isWeeklySalesExpanded,
          "Weekly Sales",
          toggleBg,
          toggleHoverBg,
          toggleTextColor
        )}
        <Collapse in={isWeeklySalesExpanded} animateOpacity>
          <Box mt={2} overflowX="auto" maxW="100%">
            {salesData && salesData.weeklySalesData ? (
              <WeeklySalesTable weeklySalesData={salesData.weeklySalesData} />
            ) : (
              <Text>No Weekly Sales data available.</Text>
            )}
          </Box>
        </Collapse>
      </Box>

      {/* Weekly Profitability */}
      <Box mb={4} borderWidth="1px" borderRadius="md" p={4} shadow="md">
        {renderToggleBar(
          handleWeeklyProfitabilityToggle,
          isWeeklyProfitabilityExpanded,
          "Weekly Profitability",
          toggleBg,
          toggleHoverBg,
          toggleTextColor
        )}
        <Collapse in={isWeeklyProfitabilityExpanded} animateOpacity>
          <Box mt={2} overflowX="auto" maxW="100%">
            {salesData && salesData.weeklySalesData ? (
              <WeeklyProfitabilityTable weeklyProfitabilityData={salesData.weeklySalesData} />
            ) : (
              <Text>No Weekly Profitability data available.</Text>
            )}
          </Box>
        </Collapse>
      </Box>

      {/* Weekly Service */}
      <Box mb={4} borderWidth="1px" borderRadius="md" p={4} shadow="md">
        {renderToggleBar(
          handleWeeklyServiceToggle,
          isWeeklyServiceExpanded,
          "Weekly Service",
          toggleBg,
          toggleHoverBg,
          toggleTextColor
        )}
        <Collapse in={isWeeklyServiceExpanded} animateOpacity>
          <Box mt={2} overflowX="auto" maxW="100%">
            {salesData && salesData.weeklySalesData ? (
              <WeeklyServiceTable weeklyServiceData={salesData.weeklySalesData} />
            ) : (
              <Text>No Weekly Service data available.</Text>
            )}
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
};

export default WeeklySalesReport;
