// src/utils/storeMerging.js

/**
 * Merge weekly data => ensure every store is included.
 */
export function mergeStoresIntoWeekly(weekly, storeData) {
  const weeklyMap = {};
  for (const w of weekly) {
    weeklyMap[String(w.combined_id)] = w;
  }

  const merged = [];

  // (A) For every store, ensure we have a weekly record
  for (const s of storeData) {
    const storeIdStr = String(s.store_id);
    if (weeklyMap[storeIdStr]) {
      merged.push(weeklyMap[storeIdStr]);
    } else {
      // If no record, create a default structure
      merged.push({
        combined_id: storeIdStr,
        storeName: s.store_name,
        required: 0,
        scheduled: 0,
        gap: 0,
      });
    }
  }

  // (B) Include leftover weekly rows not in storeData
  for (const w of weekly) {
    const isInStoreData = storeData.some(
      (s) => String(s.store_id) === String(w.combined_id)
    );
    if (!isInStoreData) {
      merged.push(w);
    }
  }

  return merged;
}

/**
 * Merge monthly data => ensure every store is included.
 */
export function mergeStoresIntoMonthly(monthly, storeData) {
  const monthlyMap = {};
  for (const m of monthly) {
    monthlyMap[String(m.combined_id)] = m;
  }

  const merged = [];

  // (A) For every store, ensure we have a monthly record
  for (const s of storeData) {
    const storeIdStr = String(s.store_id);
    if (monthlyMap[storeIdStr]) {
      merged.push(monthlyMap[storeIdStr]);
    } else {
      // If no record, create a default structure
      merged.push({
        combined_id: storeIdStr,
        storeName: s.store_name,
        nightVisits: 0,
        after8Visits: 0,
        weekendVisits: 0,
        workingWithMITCount: 0,
        gmNotPresentCount: 0,
      });
    }
  }

  // (B) Include leftover monthly rows not in storeData
  for (const m of monthly) {
    const isInStoreData = storeData.some(
      (s) => String(s.store_id) === String(m.combined_id)
    );
    if (!isInStoreData) {
      merged.push(m);
    }
  }

  return merged;
}
