// src/components/WeeklySalesTable.js
import React, { useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useColorModeValue,
  Text,
  Icon,
} from "@chakra-ui/react";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";

function daysPassedInWeek(date) {
  const currentDayOfWeek = date.getDay();
  return currentDayOfWeek === 0 ? 7 : currentDayOfWeek;
}

function calculatePercentDifference(current, previous) {
  if (previous === 0) return "N/A";
  return (((current - previous) / previous) * 100).toFixed(2) + "%";
}

function calculateTicketAverage(netSales, tickets) {
  if (tickets === 0) return "N/A";
  return (netSales / tickets).toFixed(2);
}

function formatCurrency(number) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(number);
}

function formatNumber(number) {
  return new Intl.NumberFormat().format(number);
}

function calculateLaborPercentage(labor, netSales) {
  if (netSales === 0) return "N/A";
  return ((labor / netSales) * 100).toFixed(2);
}

function getWeekNumber(date) {
  const startOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date - startOfYear) / 86400000;
  return Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
}

export function WeeklySalesTable({ weeklySalesData }) {
  // Hooks at the top
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const containerBg = useColorModeValue("gray.50", "gray.800");

  // Early return if no data
  if (!weeklySalesData) {
    return <Text>No sales data available.</Text>;
  }

  // Column headers
  const headers = [
    { key: "index", label: "#" },
    { key: "supervisorName", label: "Supervisor Name" },
    { key: "storeName", label: "Store Name" },
    { key: "netSalesThisYear", label: "Net Sales This Year" },
    { key: "netSalesLastYear", label: "Net Sales Last Year" },
    { key: "netSalesDiff", label: "Net Sales % +/-" },
    { key: "mgrLabor", label: "MGR Labor" },
    { key: "crewLabor", label: "Crew Labor" },
    { key: "totalLabor", label: "Total Labor" },
    { key: "ticketsThisYear", label: "Tickets This Year" },
    { key: "ticketsLastYear", label: "Tickets Last Year" },
    { key: "ticketsDiff", label: "Tickets % +/-" },
    { key: "ticketAverage", label: "Ticket Average" },
    { key: "onTimePct", label: "On time%" },
  ];

  // Sorting function
  const handleSort = (key) => {
    if (sortConfig.key === key) {
      setSortConfig({
        key,
        direction: sortConfig.direction === "asc" ? "desc" : "asc",
      });
    } else {
      setSortConfig({ key, direction: "asc" });
    }
  };

  // Transform weeklySalesData into an array of rows
  const allData = Object.values(weeklySalesData).map((data, index) => {
    const netSalesThisYear = parseFloat(data.currentWeek.netSales);
    const netSalesLastYear = parseFloat(data.lastYearSameWeek.netSales);
    const netSalesDiff = calculatePercentDifference(
      netSalesThisYear,
      netSalesLastYear
    );

    const ticketsThisYear = parseInt(data.currentWeek.tickets, 10);
    const ticketsLastYear = parseInt(data.lastYearSameWeek.tickets, 10);
    const ticketsDiff = calculatePercentDifference(
      ticketsThisYear,
      ticketsLastYear
    );
    const ticketAverage = calculateTicketAverage(
      netSalesThisYear,
      ticketsThisYear
    );

    const today = new Date();
    const currentWeekNumber = getWeekNumber(today);
    const dataWeekNumber = getWeekNumber(new Date(data.currentWeek.date));

    // Manager labor
    let proratedManagerLabor = 913; // Weekly manager labor
    if (currentWeekNumber === dataWeekNumber) {
      // If current week, prorate
      const daysPassed = daysPassedInWeek(today) - 1;
      proratedManagerLabor = (913 / 7) * daysPassed;
    }
    const mgrLabor = calculateLaborPercentage(
      proratedManagerLabor,
      netSalesThisYear
    );

    // Crew labor from data
    const crewLabor = calculateLaborPercentage(
      parseFloat(data.currentWeek.labor),
      netSalesThisYear
    );

    // Combined labor
    let totalLabor = "N/A";
    if (mgrLabor !== "N/A" && crewLabor !== "N/A") {
      totalLabor = (parseFloat(mgrLabor) + parseFloat(crewLabor)).toFixed(2);
    }

    // On-time ticket %
    const onTimePercentage =
      data.currentWeek.ontimeTickets && data.currentWeek.tickets > 0
        ? (
            (data.currentWeek.ontimeTickets / data.currentWeek.tickets) *
            100
          ).toFixed(2) + "%"
        : "N/A";

    return {
      index: index + 1,
      supervisorName: data.supervisorName,
      storeName: data.storeName,
      netSalesThisYear,
      netSalesLastYear,
      netSalesDiff,
      mgrLabor,
      crewLabor,
      totalLabor,
      ticketsThisYear,
      ticketsLastYear,
      ticketsDiff,
      ticketAverage,
      onTimePct: onTimePercentage,
    };
  });

  // Sort the data according to sortConfig
  const sortedData = [...allData].sort((a, b) => {
    const { key, direction } = sortConfig;
    if (!key) return 0;

    // Helper to parse "N/A" as NaN
    const parseIfNumeric = (val) => {
      if (val === "N/A") return Number.NaN;
      const parsed = parseFloat(val);
      return isNaN(parsed) ? val : parsed;
    };

    let valA = parseIfNumeric(a[key]);
    let valB = parseIfNumeric(b[key]);

    // Numeric sorting
    if (typeof valA === "number" && typeof valB === "number") {
      if (isNaN(valA)) return 1; // push "N/A" down
      if (isNaN(valB)) return -1; // push "N/A" down
      return direction === "asc" ? valA - valB : valB - valA;
    }

    // String sorting
    if (typeof valA === "string" && typeof valB === "string") {
      return direction === "asc"
        ? valA.localeCompare(valB)
        : valB.localeCompare(valA);
    }
    return 0;
  });

  // Renders the sort icon based on the current sort config
  const renderSortIcon = (colKey) => {
    if (sortConfig.key !== colKey) {
      return <FaSort />;
    }
    return sortConfig.direction === "asc" ? <FaSortUp /> : <FaSortDown />;
  };

  return (
    <Box w="100%" maxW="100%" overflowX="auto" bg={containerBg} p={2}>
      <TableContainer maxW="100%" overflowX="auto">
        <Table variant="striped" colorScheme="teal" size="md" minW="900px">
          <Thead>
            <Tr>
              {headers.map((h) => (
                <Th
                  key={h.key}
                  fontSize="sm"
                  fontWeight="bold"
                  onClick={() => handleSort(h.key)}
                  cursor="pointer"
                  whiteSpace="nowrap"
                >
                  {h.label} <Icon as={() => renderSortIcon(h.key)} ml={1} />
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {sortedData.map((row, idx) => (
              <Tr key={idx}>
                <Td>{row.index}</Td>
                <Td>{row.supervisorName}</Td>
                <Td>{row.storeName}</Td>
                <Td>{formatCurrency(row.netSalesThisYear)}</Td>
                <Td>{formatCurrency(row.netSalesLastYear)}</Td>
                <Td>{row.netSalesDiff}</Td>
                <Td>{row.mgrLabor}</Td>
                <Td>{row.crewLabor}</Td>
                <Td>{row.totalLabor}</Td>
                <Td>{formatNumber(row.ticketsThisYear)}</Td>
                <Td>{formatNumber(row.ticketsLastYear)}</Td>
                <Td>{row.ticketsDiff}</Td>
                <Td>{row.ticketAverage}</Td>
                <Td>{row.onTimePct}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}
