// SScheduleAnalyzer.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

// Chakra UI
import {
  Box,
  Button,
  Collapse,
  Flex,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

function SScheduleAnalyzer() {
  const [file, setFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [excelFileUrl, setExcelFileUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(true);

  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
    setIsLoading(false);
  };

  const getBaseUrl = () => {
    // Adjust logic if you have a different approach for dev vs production
    return process.env.NODE_ENV === "development"
      ? "http://72.167.34.236:5000"
      : "https://prolifi.app";
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
      setErrorMessage("");
      setIsLoading(false);
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    if (!file) {
      Swal.fire("Oops...", "Please select a file first", "error");
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const baseUrl = getBaseUrl();
      const response = await axios.post(`${baseUrl}/api/sScheduleUpload`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        withCredentials: true,
      });

      if (response.data.filePath) {
        setExcelFileUrl(response.data.filePath);
        setIsLoading(false); // success
        Swal.fire("Success", "File processed successfully!", "success");
      } else {
        Swal.fire("Error", "No file path returned from server", "error");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Upload error:", error);
      const errMessage =
        error.response && error.response.data
          ? error.response.data
          : "An unexpected error occurred";
      Swal.fire("Error", errMessage, "error");
      setErrorMessage(errMessage);
      setIsLoading(false);
    }
  };

  // Auto-download the Excel file once the upload is successful
  useEffect(() => {
    if (excelFileUrl) {
      const downloadExcelFile = async () => {
        try {
          const fullUrl = `${getBaseUrl()}/api/view-excel/${excelFileUrl}`;
          const response = await axios.get(fullUrl, { responseType: "blob" });
          const blob = new Blob([response.data], {
            type: "application/vnd.ms-excel",
          });
          const fileURL = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = fileURL;
          link.download = "downloaded_file.xlsx";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(fileURL);
        } catch (error) {
          console.error("Error downloading Excel file:", error);
          Swal.fire("Error", "Failed to download Excel file", "error");
        }
      };
      downloadExcelFile();
    }
  }, [excelFileUrl]);

  // Chakra color theming
  const containerBg = useColorModeValue("gray.50", "gray.800");
  const boxBg = useColorModeValue("white", "gray.700");
  const borderCol = useColorModeValue("gray.200", "gray.600");
  const dashedBorderCol = useColorModeValue("blue.300", "blue.500");
  const hoverBg = useColorModeValue("blue.50", "gray.600");

  return (
    <Box minH="100vh" bg={containerBg} p={2}>
      <Box
        maxW="600px"
        mx="auto"
        borderWidth="1px"
        borderColor={borderCol}
        borderRadius="md"
        bg={boxBg}
        shadow="md"
      >
        {/* Toggle bar at top */}
        <Box
          as="button"
          w="100%"
          textAlign="center"
          onClick={toggleFormVisibility}
          bgGradient="linear(to-r, blue.500, blue.300)"
          color="white"
          _hover={{ bgGradient: "linear(to-r, blue.600, blue.400)" }}
          borderBottomWidth="1px"
          borderColor={borderCol}
          py={1}
        >
          <Text fontSize="xs" fontWeight="bold">
            {isFormVisible ? "▲ Hide Form" : "▼ Show Form"}
          </Text>
        </Box>

        {/* Collapsible form */}
        <Collapse in={isFormVisible} animateOpacity>
          <Box as="form" onSubmit={handleUpload} p={4}>

            {/* Modern "drag or click" style file selection */}
            <Box
              as="label"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              p={6}
              mb={4}
              border="2px dashed"
              borderColor={dashedBorderCol}
              borderRadius="md"
              cursor="pointer"
              transition="background-color 0.2s"
              _hover={{ bg: hoverBg }}
            >
              {file ? (
                <Text fontSize="sm" color="blue.500" fontWeight="medium">
                  Selected file: {file.name}
                </Text>
              ) : (
                <Text fontSize="sm" color="gray.500">
                  Click or drag a .xlsx or .xls file here to upload
                </Text>
              )}
              <Input
                type="file"
                onChange={handleFileChange}
                accept=".xlsx,.xls"
                hidden
              />
            </Box>

            {/* Right-aligned button */}
            <Flex justify="flex-end">
              <Button
                type="submit"
                colorScheme="blue"
                size="sm"
                isLoading={isLoading}
                loadingText="Analyzing..."
                spinnerPlacement="end"
              >
                Analyze
              </Button>
            </Flex>

            {errorMessage && (
              <Text mt={3} color="red.500" fontSize="sm" textAlign="left">
                {errorMessage}
              </Text>
            )}
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
}

export default SScheduleAnalyzer;
